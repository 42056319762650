import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./auth";
import ModifierInvoicePage from "./pages/ModifierPage";
import FirstPage from "./pages/FirstPage";
import DeviceCheck from "./utils/device-check/DeviceCheck";
import QuotePage from "./pages/QuotePage";
import CustomerDeliveryBillPage from "./pages/CustomerDeliveryBillPage";
import CustomerBidPage from "./pages/CustomerBidPage";
import CustomerOrderPage from "./pages/CustomerOrderPage";
import CustomerProformaInvoicePage from "./pages/CustomerProformaInvoicePage";
import CustomerReceiptPage from "./pages/CustomerReceiptPage";

function App() {
  return (
    <Router>
      <DeviceCheck>
        <Routes>
          <Route path="/auth" element={<FirstPage />} />
          <Route
            path="/"
            element={
              <Auth>
                <ModifierInvoicePage />
              </Auth>
            }
          />
          <Route
            path="/quote"
            element={
              <Auth>
                <QuotePage />
              </Auth>
            }
          />
          <Route
            path="/customerdeliverybill"
            element={
              <Auth>
                <CustomerDeliveryBillPage />
              </Auth>
            }
          />
          <Route
            path="/customerbid"
            element={
              <Auth>
                <CustomerBidPage />
              </Auth>
            }
          />
          <Route
            path="/customerorder"
            element={
              <Auth>
                <CustomerOrderPage />
              </Auth>
            }
          />
          <Route
            path="/customerproformainvoice"
            element={
              <Auth>
                <CustomerProformaInvoicePage />
              </Auth>
            }
          />
          <Route
            path="/customerreceipt"
            element={
              <Auth>
                <CustomerReceiptPage />
              </Auth>
            }
          />
        </Routes>
      </DeviceCheck>
    </Router>
  );
}

export default App;
