import { Fragment, useContext, useState } from "react";
import { MasterDataContext, ReceiptContext } from "../../utils/GlobalContexts";
import { VoucherState } from "../../KulcsUzletApi";
import { TAB_TYPE } from "../../utils/Enums";
import { MobileBottomMenuPropsReceipt } from "../../interfaces/MobileBottomMenuPropsReceipt";

const MobileBottomMenuReceipt: React.FC<MobileBottomMenuPropsReceipt> = ({
  openResultModal,
  activeTab,
  handleSave,
  handleOpenPreview,
  handleSendEmailClick,
}) => {
  const { receipt, setReceipt } = useContext(ReceiptContext);
  const [open, setOpen] = useState(false);
  const { vats, paymentMethods, currencies } = useContext(MasterDataContext);
  const isDisabled =
    (receipt as any)?.Status === 5 ||
    (receipt as any)?.State === VoucherState.CANCELLED ||
    (receipt as any)?.State === VoucherState.CANCELLER ||
    (receipt as any)?.State === VoucherState.RESTORED;

  const handleCancel = () => {
    const voucherType = localStorage.getItem("voucherType");
    const kulcsUzletInvoicesUrl =
      voucherType &&
      voucherType !== "modifier" &&
      window.location.pathname.includes(voucherType)
        ? `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/${voucherType}s`
        : `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/invoices`;
    window.location.replace(kulcsUzletInvoicesUrl);
  };

  const handleSendVoucher = async () => {
    if (!receipt?.Detail) {
      openResultModal("1 termék kiválasztása kötelező!");
      return;
    }
    handleSendEmailClick && handleSendEmailClick();
  };

  return (
    <Fragment>
      {activeTab === TAB_TYPE.INVOICE &&
        localStorage.getItem("action") !== "edit" && (
          <div
            className="transition-height"
            style={{
              position: "fixed",
              bottom: "56px",
              width: "100%",
              backgroundColor: "white",
              borderTop: "1px solid #6d2243",
              left: 0,
              height: open ? "140px" : "60px",
            }}
          >
            <div
              onClick={() => setOpen(!open)}
              style={{
                top: "-45px",
                backgroundColor: "#6d2243",
                width: "45px",
                height: "45px",
                display: "block",
                position: "absolute",
                border: 0,
                borderRadius: "10px 10px 0 0",
                WebkitTransformOrigin: "center",
                msTransformOrigin: "center",
                transformOrigin: "center",
                left: 0,
              }}
            >
              <span
                style={{
                  display: "block",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "28px",
                  height: "28px",
                  border: "2px solid #ffffff",
                  color: "#ffffff",
                  WebkitBorderRadius: "50%",
                  borderRadius: "50%",
                }}
              >
                {open ? (
                  <svg
                    style={{ marginLeft: "7px", marginTop: "5px" }}
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    height="18px"
                    viewBox="0 0 384 512"
                  >
                    <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                ) : (
                  <svg
                    style={{ marginLeft: "7px", marginTop: "5px" }}
                    height="18px"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                  </svg>
                )}
              </span>
            </div>
          </div>
        )}

      <div
        style={{
          width: "100%",
          display: "-webkit-flex",
          position: "fixed",
          bottom: 0,
          left: 0,
        }}
      >
        <div
          onClick={handleOpenPreview}
          style={{
            padding: "5px",
            alignItems: "center",
            flex: "1 0 auto",
            justifyContent: "center",
            backgroundColor: "#3f76bb",
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            fontSize: "13px",
          }}
        >
          <div
            style={{
              height: "26px",
            }}
          >
            <div
              style={{
                borderRadius: "50%",
                border: "2px solid #ffffff",
                width: "24px",
                height: "24px",
                margin: "auto",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="14px"
                viewBox="0 0 576 512"
                fill="white"
                style={{ marginTop: "4.5px", marginLeft: "0.9px" }}
              >
                <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
              </svg>
            </div>
          </div>
          Előnézet
        </div>
        {localStorage.getItem("action") !== "edit" && (
          <div
            onClick={() => (handleSave ? handleSave() : {})}
            style={{
              padding: "5px",
              alignItems: "center",
              flex: "1 0 auto",
              justifyContent: "center",
              backgroundColor: "#3f76bb",
              cursor: "pointer",
              textAlign: "center",
              color: "white",
              fontSize: "13px",
            }}
          >
            <div
              style={{
                height: "26px",
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  border: "2px solid #ffffff",
                  width: "24px",
                  height: "24px",
                  margin: "auto",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="14px"
                  viewBox="0 0 576 512"
                  fill="white"
                  style={{ marginTop: "5px", marginLeft: "4px" }}
                >
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
            </div>
            Mentés
          </div>
        )}
        <div
          onClick={() => handleSendVoucher()}
          style={{
            padding: "5px",
            alignItems: "center",
            flex: "1 0 auto",
            justifyContent: "center",
            backgroundColor: "#3f76bb",
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            fontSize: "13px",
          }}
        >
          <div
            style={{
              height: "26px",
            }}
          >
            <div
              style={{
                borderRadius: "50%",
                border: "2px solid #ffffff",
                width: "24px",
                height: "24px",
                margin: "auto",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="14px"
                viewBox="0 0 512 512"
                fill="white"
                style={{ marginTop: "5px", marginLeft: "1px" }}
              >
                <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
              </svg>
            </div>
          </div>
          Küldés
        </div>
        <div
          onClick={handleCancel}
          style={{
            padding: "5px",
            alignItems: "center",
            flex: "1 0 auto",
            justifyContent: "center",
            backgroundColor: "#CECECE",
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            fontSize: "13px",
          }}
        >
          <div
            style={{
              height: "26px",
            }}
          >
            <div
              style={{
                borderRadius: "50%",
                border: "2px solid #ffffff",
                width: "24px",
                height: "24px",
                margin: "auto",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="14px"
                viewBox="0 0 576 512"
                fill="white"
                style={{ marginTop: "5px", marginLeft: "5px" }}
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </div>
          </div>
          Mégse
        </div>
      </div>
    </Fragment>
  );
};

export default MobileBottomMenuReceipt;
