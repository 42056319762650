import { ModifierInvoiceDetailDto, ModifierInvoiceDto } from "../KulcsUzletApi";
import {
  calculatePercentage,
  formatNumberWithQuantityUnit,
  needToRoundTo5,
} from "./HelperFunctions";
import { getCalculatedUnitPrice } from "./VoucherFunctions";

export const getNetValue = (
  item: ModifierInvoiceDetailDto,
  modifier: ModifierInvoiceDto | undefined
) => {
  let unitPriceMinusDiscount = getCalculatedUnitPrice(
    item?.DiscountPercent,
    item.UnitPrice
  );
  return unitPriceMinusDiscount * (item.Quantity ?? 0);
};

export const getModifierNetValueSum = (
  modifier: ModifierInvoiceDto | undefined
) => {
  if (!modifier?.DetailList || modifier?.DetailList?.length === 0) return 0;

  let sum = 0;
  modifier?.DetailList.map(
    (detail) => (
      (sum += getCalculatedUnitPrice(
        detail?.DiscountPercent,
        detail.OriginalUnitPrice ?? detail?.UnitPrice
      )),
      detail.Quantity ?? 0
    ),
    0
  );
  return sum;
};

export const getGrossValueSum = (modifier: ModifierInvoiceDto | undefined) => {
  if (!modifier?.DetailList || modifier?.DetailList?.length === 0) return 0;

  let sum = 0;
  modifier?.DetailList.map(
    (detail) => (sum += getGrossValue(detail, modifier))
  );
  return sum;
};

export const getGrossValue = (
  item: ModifierInvoiceDetailDto,
  modifier: ModifierInvoiceDto | undefined
) => {
  return getNetValue(item, modifier) * (1 + (item.VatPercent ?? 0) / 100);
};

export const getModifierVatValueSum = (
  modifier: ModifierInvoiceDto | undefined
) => {
  if (!modifier?.DetailList || modifier?.DetailList?.length === 0) return 0;

  let sum = 0;
  modifier?.DetailList.map(
    (detail) =>
      (sum += calculatePercentage(
        getNetValue(detail, modifier),
        detail.VatPercent
      )),
    0
  );

  return sum;
};

export const getModifierTotal = (
  modifier: ModifierInvoiceDto | null,
  acquitedValue?: boolean
) => {
  if (!modifier) return 0;
  const total =
    acquitedValue && !modifier.PaymentMethod?.ImmediatePay
      ? modifier.AcquitValue
      : getGrossValueSum(modifier);
  return formatNumberWithQuantityUnit(
    total,
    modifier.Currency,
    modifier.Currency?.GrandTotalDigits,
    needToRoundTo5(modifier?.PaymentMethod, modifier.Currency)
  );
};
