import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import { DialogConfirmProps } from "../../interfaces/DialogConfirmProps";

const DialogConfirm: React.FC<DialogConfirmProps> = ({
  message,
  open,
  setOpen,
  handleAction,
}) => {
  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title=""
      saveAction={handleAction}
      saveBtnText="Ok"
      closeBtnText="Bezár"
    >
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
    </DialogSkeleton>
  );
};

export default DialogConfirm;
