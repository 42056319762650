import CardFlip from "../card-flip/CardFlip";
import Comments from "../comments/Comments";
import InputComponent from "../input/InputComponent";
import { Button, Label } from "@fluentui/react-components";
import { COLOR } from "../../utils/Enums";
import { useStylesInvoiceForm } from "../../styles/InvoiceFormStyles";
import {
  formatNumberWithQuantityUnit,
  getCustomerAddress,
} from "../../utils/HelperFunctions";
import { VoucherContext, MasterDataContext } from "../../utils/GlobalContexts";
import {
  CurrencyDto,
  CustomerDeliveryBillDto,
  LanguageDto,
  ProjectDto,
  TransportModeDto,
  VoucherState,
} from "../../KulcsUzletApi";
import { Fragment, useContext, useState } from "react";
import SelectComponent from "../select/SelectComponent";
import { DatePicker, DayOfWeek } from "@fluentui/react-datepicker-compat";
import { DayPickerStrings } from "../../utils/GlobalConsts";
import { VoucherFormProps } from "../../interfaces/VoucherFormProps";
import {
  getVoucherNetValueSum,
  getVoucherTotal,
  getVoucherVatValueSum,
} from "../../utils/VoucherFunctions";
import ClipLoader from "react-spinners/ClipLoader";
import VoucherDetailTable from "../detail-table/VoucherDetailTable";

const CustomerDeliveryBillForm: React.FC<VoucherFormProps> = ({
  openCustomerModal,
  openResultModal,
  updateCurrencyRate,
  selectDetail,
  setShouldUpdateCurrency,
  currencyRate,
  copy,
  handleSendEmailClick,
  handleSave,
  isSaveLoading,
  isLoading,
  handleOpenPreview,
  isPreviewLoading,
}) => {
  const styles = useStylesInvoiceForm();
  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlip = () => setIsFlipped(!isFlipped);
  const { voucher, setVoucher } = useContext(VoucherContext);
  const isDisabled =
    voucher?.State === VoucherState.CANCELLED ||
    voucher?.State === VoucherState.CANCELLER ||
    voucher?.State === VoucherState.RESTORED;
  const { projects, currencies, vats, transportModes, languages } =
    useContext(MasterDataContext);
  const [voucherDiscount, setVoucherDiscount] = useState(0);
  const isSalesPlusPlus = localStorage.getItem("isSalesPlusPlus") === "1";
  const isEdit = localStorage.getItem("action") === "edit";

  const changeDateProp = (date: Date | null | undefined, property: string) =>
    setVoucher((prev: CustomerDeliveryBillDto) => ({
      ...prev,
      [property]: date,
    }));

  const editSelectProject = async (ev: any, data: any) => {
    setVoucher((prev: CustomerDeliveryBillDto) => ({
      ...prev,
      ProjectId: (data.optionValue as ProjectDto)?.Id ?? undefined,
    }));
  };

  const editSelectLanguage = async (ev: any, data: any) => {
    setVoucher((prev: CustomerDeliveryBillDto) => ({
      ...prev,
      LanguageInternalCode:
        (data.optionValue as LanguageDto)?.InternalCode ?? undefined,
    }));
  };

  const editSelectCurrency = async (ev: any, data: any) => {
    setVoucher((prev: CustomerDeliveryBillDto) => ({
      ...prev,
      CurrencyId: (data.optionValue as CurrencyDto)?.Id ?? undefined,
    }));
    setShouldUpdateCurrency(true);
  };

  const updateVoucherDiscount = (e: any) => {
    let value = e.target.value;
    setVoucherDiscount(value);
  };

  const updateVoucher = (e: any) => {
    let discount = voucherDiscount;

    if (discount < -100) discount = -100;
    else if (discount > 200) discount = 200;
    setVoucherDiscount(discount);

    setVoucher((prev: CustomerDeliveryBillDto) => ({
      ...prev,
      DiscountPercent: discount,
      Items: prev?.Items?.map((x) => ({
        ...x,
        DiscountPercent: discount * 1,
      })),
    }));
  };

  function changeCurrencyRate(event: any): void {
    updateCurrencyRate();
  }

  const handleSend = async () => {
    if (!voucher?.CustomerObj?.Id && voucher?.Items?.length === 0) {
      openResultModal("Legalább 1 termék és 1 ügyfél kiválasztása kötelező!");
      return;
    }
    handleSendEmailClick();
  };

  const editSelectTransport = async (ev: any, data: any) => {
    setVoucher((prev: CustomerDeliveryBillDto) => ({
      ...prev,
      TransportModeId: (data.optionValue as TransportModeDto)?.Id ?? undefined,
    }));
  };

  return (
    <CardFlip isFlipped={isFlipped}>
      <Fragment>
        <div className={styles.floatRight} style={{ display: "block" }}>
          <div className={styles.alignRight}>
            <Button
              disabled={isPreviewLoading || isSalesPlusPlus}
              className={styles.button}
              style={{ backgroundColor: COLOR.BIZ_PURPLE }}
              appearance="primary"
              onClick={handleOpenPreview}
            >
              {isPreviewLoading ? (
                <ClipLoader size={16} color="white" />
              ) : (
                "ELŐNÉZET"
              )}
            </Button>

            <Button
              disabled={isSaveLoading}
              className={styles.button}
              style={{ backgroundColor: COLOR.BIZ_DARK_ORANGE }}
              appearance="primary"
              onClick={() => (handleSave ? handleSave() : {})}
            >
              {isSaveLoading ? (
                <ClipLoader size={16} color="white" />
              ) : (
                "MENTÉS"
              )}
            </Button>

            <Button
              disabled={isSaveLoading}
              className={styles.button}
              style={{ backgroundColor: COLOR.BIZ_DARK_ORANGE }}
              appearance="primary"
              onClick={handleSend}
            >
              {"KÜLDÉS"}
            </Button>
          </div>

          <div className={styles.alignRight}></div>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              id="client-info-wrapper"
              className={styles.client}
              style={{ width: "48%", boxSizing: "border-box" }}
            >
              <div
                id={"client-info"}
                style={
                  !voucher?.CustomerObj?.Id || isDisabled || isSalesPlusPlus
                    ? { display: "none" }
                    : {}
                }
              >
                <div onClick={openCustomerModal}>
                  <div
                    className="btn-circle-icon"
                    style={{
                      display: "inline-flex",
                      marginLeft: "50px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="36px"
                      fill={COLOR.BIZ_BLACK}
                      viewBox="0 0 448 380"
                    >
                      <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                    </svg>
                  </div>
                  <div style={{ fontFamily: "Oxygen", fontSize: "17px" }}>
                    {"Partner szerkesztése"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-flex",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill={COLOR.BIZ_DARK_ORANGE}
                  height="42px"
                  viewBox="0 0 448 512"
                  style={!voucher?.CustomerObj?.Id ? { opacity: 0 } : {}}
                >
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                </svg>

                <div
                  className="client-info-wrapper"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <h3 className={styles.clientInfo}>
                    {voucher?.CustomerObj?.Name ?? ""}
                  </h3>
                  <div className={styles.clientInfo}>
                    {getCustomerAddress(voucher?.CustomerObj)}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.alignRight}>
                <h2 className={styles.vouchernumber}>
                  {voucher?.VoucherNumber}
                </h2>
              </div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Iktatószám</Label>
                </div>
                <InputComponent
                  disabled={isDisabled}
                  maxLength={80}
                  value={voucher?.FilingNumber ?? ""}
                  name="FilingNumber"
                  onChange={(e: any) =>
                    setVoucher((prev: CustomerDeliveryBillDto) => ({
                      ...prev,
                      FilingNumber: e.target.value,
                    }))
                  }
                />
                <div className={styles.label}>
                  <Label size="small">Teljesítés</Label>
                </div>
                <DatePicker
                  firstDayOfWeek={DayOfWeek.Monday}
                  disabled={isDisabled}
                  contentAfter={<></>}
                  style={{
                    width: "100px",
                  }}
                  onSelectDate={(data: any) =>
                    changeDateProp(data, "FulfillmentDate")
                  }
                  value={
                    (voucher as CustomerDeliveryBillDto)?.FulfillmentDate
                      ? new Date((voucher as any).FulfillmentDate)
                      : new Date()
                  }
                  allowTextInput
                  isMonthPickerVisible={true}
                  strings={DayPickerStrings}
                  formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
                />
              </div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Projekt</Label>
                </div>
                <SelectComponent
                  disabled={isDisabled}
                  options={projects && (Object.values(projects) as any)}
                  value={
                    (projects &&
                      voucher?.ProjectId &&
                      projects[voucher?.ProjectId]?.Name) ??
                    ""
                  }
                  action={editSelectProject}
                />

                <div className={styles.label}>
                  <Label size="small">Pénznem</Label>
                </div>
                <SelectComponent
                  disabled={isSalesPlusPlus || isDisabled || isEdit}
                  noEmptyOption
                  options={currencies && (Object.values(currencies) as any)}
                  value={
                    (currencies &&
                      voucher?.CurrencyId &&
                      currencies[voucher?.CurrencyId]?.DisplayName) ??
                    ""
                  }
                  action={editSelectCurrency}
                />
              </div>
              <div className={styles.alignRight}>
                <div className={styles.label}>
                  <Label size="small">Szállítási mód</Label>
                </div>
                <SelectComponent
                  disabled={isDisabled}
                  options={
                    transportModes && (Object.values(transportModes) as any)
                  }
                  value={
                    (transportModes &&
                      (voucher as CustomerDeliveryBillDto)?.TransportModeId &&
                      transportModes[(voucher as any)?.TransportModeId]
                        ?.Name) ??
                    ""
                  }
                  action={editSelectTransport}
                />
                <div className={styles.label}>
                  <Label size="small">Kelte</Label>
                </div>
                <DatePicker
                  firstDayOfWeek={DayOfWeek.Monday}
                  disabled={true}
                  isMonthPickerVisible={true}
                  strings={DayPickerStrings}
                  contentAfter={<></>}
                  style={{ width: "100px" }}
                  onSelectDate={(data: any) =>
                    changeDateProp(data, "VoucherDate")
                  }
                  value={
                    isSalesPlusPlus
                      ? new Date()
                      : voucher?.VoucherDate
                      ? new Date(voucher?.VoucherDate)
                      : new Date()
                  }
                  allowTextInput
                  formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
                />
              </div>
            </div>
          </div>
        </div>
        <VoucherDetailTable
          isLoading={isLoading}
          voucher={voucher}
          setVoucher={setVoucher}
          selectDetail={selectDetail}
        />

        <div className={styles.alignRight}>
          <div style={{ display: "inline-block", width: "100px" }}>
            Részösszeg
          </div>
          <div
            style={{
              display: "inline-block",
              width: "250px",
              margin: "5px",
            }}
          >
            {currencies &&
              voucher?.CurrencyId &&
              voucher &&
              formatNumberWithQuantityUnit(
                getVoucherNetValueSum(voucher),
                currencies[voucher?.CurrencyId],
                currencies[voucher?.CurrencyId]?.NetValueDigits
              )}
          </div>
        </div>
        <div className={styles.alignRight}>
          <div style={{ display: "inline-block", width: "100px" }}>
            Kedvezmény/Felár
          </div>
          <div
            style={{
              display: "inline-block",
              width: "250px",
              margin: "5px",
            }}
          >
            <InputComponent
              disabled={isDisabled}
              value={voucherDiscount ?? voucher?.DiscountPercent ?? 0}
              name={"DiscountPercent"}
              min={-100}
              max={200}
              numeric
              onChange={updateVoucherDiscount}
              onFocusLeave={updateVoucher}
            />
            {`%`}
          </div>
        </div>
        <div className={styles.alignRight}>
          <div style={{ display: "inline-block", width: "100px" }}>Áfa</div>
          <div
            style={{
              display: "inline-block",
              width: "250px",
              margin: "5px",
            }}
          >
            {currencies &&
              voucher?.CurrencyId &&
              vats &&
              voucher &&
              formatNumberWithQuantityUnit(
                getVoucherVatValueSum(voucher, vats),
                currencies[voucher?.CurrencyId],
                currencies[voucher?.CurrencyId]?.VatValueDigits
              )}
          </div>
        </div>
        <div className={styles.alignRight} style={{ margin: "10px" }}>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginRight: 0,
            }}
          >
            {currencyRate && currencyRate > 1 && (
              <Fragment>
                Beállított árfolyam:
                <Button
                  disabled={isDisabled}
                  appearance="primary"
                  style={{
                    backgroundColor: "#adadad",
                    marginLeft: "5px",
                  }}
                  onClick={changeCurrencyRate}
                >
                  Módosítás
                </Button>
              </Fragment>
            )}
          </div>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              fontWeight: 700,
            }}
          >
            {voucher?.CurrencyRate &&
              voucher?.CurrencyRate > 1 &&
              voucher?.CurrencyRate}
          </div>
          <div
            style={{
              fontSize: "22px",
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginLeft: "50px",
            }}
          >
            Összesen
          </div>
          <div
            style={{
              fontSize: "22px",
              fontWeight: 700,
              display: "inline-block",
              maxWidth: "20vw",
            }}
          >
            {voucher
              ? getVoucherTotal(
                  voucher,
                  undefined,
                  currencies && voucher?.CurrencyId
                    ? currencies[voucher?.CurrencyId]
                    : undefined,
                  vats
                )
              : 0}
          </div>
        </div>
        <div className={styles.alignRight} style={{ margin: "10px" }}>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
              marginRight: 0,
            }}
          >
            Nyelv:
          </div>
          <div
            style={{
              display: "inline-block",
              margin: 10,
              marginTop: 0,
            }}
          >
            <span style={{ marginRight: "5px" }}>{`Magyar /`}</span>
            <SelectComponent
              disabled
              options={languages as any}
              value={
                languages?.find(
                  (x) =>
                    x.InternalCode ===
                    (isSalesPlusPlus ? "HU" : voucher?.LanguageInternalCode)
                )?.Name ?? ""
              }
              action={editSelectLanguage}
              noEmptyOption
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",

            display: "flex",
          }}
          onClick={handleFlip}
        >
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "20px",
              display: "flex",
            }}
          >
            <span className={styles.comments}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </span>
            <span
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                cursor: "pointer",
              }}
            >
              Megjegyzések
            </span>
          </div>
        </div>
      </Fragment>

      <div>
        <Comments
          handleFlip={handleFlip}
          voucher={voucher}
          setVoucher={setVoucher}
        />
      </div>
    </CardFlip>
  );
};

export default CustomerDeliveryBillForm;
