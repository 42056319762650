import { Button, Label } from "@fluentui/react-components";
import { COLOR } from "../../utils/Enums";
import { useStylesInvoiceForm } from "../../styles/InvoiceFormStyles";
import { MasterDataContext, ReceiptContext } from "../../utils/GlobalContexts";
import {
  CurrencyDto,
  CustomerReceiptDto,
  PaymentMethodDto,
  VoucherState,
} from "../../KulcsUzletApi";
import { Fragment, useContext } from "react";
import SelectComponent from "../select/SelectComponent";
import { DatePicker, DayOfWeek } from "@fluentui/react-datepicker-compat";
import { DayPickerStrings } from "../../utils/GlobalConsts";
import ClipLoader from "react-spinners/ClipLoader";
import CustomerReceiptDetailTable from "../detail-table/CustomerReceiptDetailTable";
import { ReceiptFormProps } from "../../interfaces/ReceiptFormProps";
import InputComponent from "../input/InputComponent";

const CustomerReceiptForm: React.FC<ReceiptFormProps> = ({
  openResultModal,
  updateCurrencyRate,
  setShouldUpdateCurrency,
  currencyRate,
  copy,
  handleSendEmailClick,
  handleSave,
  isSaveLoading,
  isLoading,
  handleOpenPreview,
  isPreviewLoading,
  selectDetail,
}) => {
  const styles = useStylesInvoiceForm();
  const { receipt, setReceipt } = useContext(ReceiptContext);
  const isDisabled =
    receipt?.State === VoucherState.CANCELLED ||
    receipt?.State === VoucherState.CANCELLER ||
    receipt?.State === VoucherState.RESTORED;

  const { paymentMethods, currencies, vats } = useContext(MasterDataContext);
  const isSalesPlusPlus = localStorage.getItem("isSalesPlusPlus") === "1";
  const isEdit = localStorage.getItem("action") === "edit";

  const editSelectCurrency = async (ev: any, data: any) => {
    setReceipt((prev: CustomerReceiptDto) => ({
      ...prev,
      CurrencyId: (data.optionValue as CurrencyDto)?.Id ?? undefined,
    }));
    setShouldUpdateCurrency(true);
  };

  function changeCurrencyRate(event: any): void {
    updateCurrencyRate();
  }

  const handleSend = async () => {
    if (!receipt?.Detail) {
      openResultModal("1 termék kiválasztása kötelező!");
      return;
    }
    handleSendEmailClick();
  };

  const editSelectPaymentMethod = (ev: any, data: any) => {
    setReceipt((prev: CustomerReceiptDto) => ({
      ...prev,
      PaymentMethodId: (data.optionValue as PaymentMethodDto)?.Id ?? undefined,
    }));
  };

  return (
    <Fragment>
      <div className={styles.floatRight} style={{ display: "block" }}>
        <div className={styles.alignRight}>
          <Button
            disabled={isPreviewLoading}
            className={styles.button}
            style={{ backgroundColor: COLOR.BIZ_PURPLE }}
            appearance="primary"
            onClick={handleOpenPreview}
          >
            {isPreviewLoading ? (
              <ClipLoader size={16} color="white" />
            ) : (
              "ELŐNÉZET"
            )}
          </Button>

          {!isEdit && (
            <Button
              disabled={isSaveLoading}
              className={styles.button}
              style={{ backgroundColor: COLOR.BIZ_DARK_ORANGE }}
              appearance="primary"
              onClick={() => (handleSave ? handleSave() : {})}
            >
              {isSaveLoading ? (
                <ClipLoader size={16} color="white" />
              ) : (
                "MENTÉS"
              )}
            </Button>
          )}

          <Button
            disabled={isSaveLoading}
            className={styles.button}
            style={{ backgroundColor: COLOR.BIZ_DARK_ORANGE }}
            appearance="primary"
            onClick={handleSend}
          >
            {"KÜLDÉS"}
          </Button>
        </div>

        <div className={styles.alignRight}></div>
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            id="client-info-wrapper"
            className={styles.client}
            style={{ width: "48%", boxSizing: "border-box" }}
          >
            <div id={"client-info"} style={{ display: "none" }}>
              <div>
                <div
                  className="btn-circle-icon"
                  style={{
                    display: "inline-flex",
                    marginLeft: "50px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="36px"
                    fill={COLOR.BIZ_BLACK}
                    viewBox="0 0 448 380"
                  >
                    <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                  </svg>
                </div>
                <div style={{ fontFamily: "Oxygen", fontSize: "17px" }}>
                  {"Partner szerkesztése"}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "inline-flex",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={COLOR.BIZ_DARK_ORANGE}
                height="42px"
                viewBox="0 0 448 512"
                style={
                  !receipt?.CustomerName && !receipt?.CustomerEmail
                    ? { opacity: 0 }
                    : {}
                }
              >
                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
              </svg>

              <div
                className=""
                style={{
                  marginLeft: "10px",
                }}
              >
                <h3 className={styles.clientInfo}>
                  {receipt?.CustomerName ?? ""}
                </h3>
                <div className={styles.clientInfo}>
                  {receipt?.CustomerEmail}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.alignRight}>
              <h2 className={styles.vouchernumber}>{receipt?.VoucherNumber}</h2>
            </div>

            <div className={styles.alignRight}>
              <div className={styles.label}>
                <Label size="small">Ügyfél név</Label>
              </div>
              <InputComponent
                disabled={isSalesPlusPlus || isDisabled || isEdit}
                id="CustomerName"
                value={receipt?.CustomerName ?? ""}
                name={"CustomerName"}
                onChange={(e: any) => {
                  setReceipt((prev: CustomerReceiptDto) => ({
                    ...prev,
                    CustomerName: e.target.value,
                  }));
                }}
              />
              <div className={styles.label}>
                <Label size="small">Ügyfél email</Label>
              </div>
              <InputComponent
                disabled={isSalesPlusPlus || isDisabled || isEdit}
                id="CustomerEmail"
                value={receipt?.CustomerEmail ?? ""}
                name={"CustomerEmail"}
                onChange={(e: any) => {
                  setReceipt((prev: CustomerReceiptDto) => ({
                    ...prev,
                    CustomerEmail: e.target.value,
                  }));
                }}
              />
            </div>
            <div className={styles.alignRight}>
              <div className={styles.label}>
                <Label size="small">Pénznem</Label>
              </div>
              <SelectComponent
                disabled={isSalesPlusPlus || isDisabled || isEdit}
                noEmptyOption
                options={currencies && (Object.values(currencies) as any)}
                value={
                  (currencies &&
                    receipt?.CurrencyId &&
                    currencies[receipt?.CurrencyId]?.DisplayName) ??
                  ""
                }
                action={editSelectCurrency}
              />
              <div className={styles.label}>
                <Label size="small">Kelte</Label>
              </div>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                disabled={true}
                isMonthPickerVisible={true}
                strings={DayPickerStrings}
                contentAfter={<></>}
                style={{ width: "100px" }}
                value={
                  isSalesPlusPlus
                    ? new Date()
                    : receipt?.VoucherDate
                    ? new Date(receipt?.VoucherDate)
                    : new Date()
                }
                allowTextInput
                formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
              />
            </div>
            <div className={styles.alignRight}>
              <div className={styles.label}>
                <Label size="small">Fizetési mód</Label>
              </div>

              <SelectComponent
                disabled={isSalesPlusPlus || isDisabled || isEdit}
                noEmptyOption
                options={
                  paymentMethods
                    ? (Object.values(paymentMethods).filter(
                        (method) => method.ImmediatePay === 1
                      ) as [])
                    : []
                }
                value={
                  paymentMethods && receipt?.PaymentMethodId
                    ? paymentMethods[receipt?.PaymentMethodId]?.Name
                    : ""
                }
                action={editSelectPaymentMethod}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomerReceiptDetailTable
        isLoading={isLoading}
        voucher={receipt}
        setVoucher={setReceipt}
        selectDetail={selectDetail}
      />

      <div className={styles.alignRight} style={{ margin: "10px" }}>
        <div
          style={{
            display: "inline-block",
            margin: 10,
            marginTop: 0,
            marginRight: 0,
          }}
        >
          {currencyRate && currencyRate > 1 && (
            <Fragment>
              Beállított árfolyam:
              <Button
                disabled={isDisabled}
                appearance="primary"
                style={{
                  backgroundColor: "#adadad",
                  marginLeft: "5px",
                }}
                onClick={changeCurrencyRate}
              >
                Módosítás
              </Button>
            </Fragment>
          )}
        </div>
        <div
          style={{
            display: "inline-block",
            margin: 10,
            marginTop: 0,
            fontWeight: 700,
          }}
        >
          {currencyRate && currencyRate > 1 ? currencyRate : ""}
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerReceiptForm;
