import { Label, Textarea } from "@fluentui/react-components";
import { CommentsProps } from "../../interfaces/CommentsProps";
import { useStylesInvoiceForm } from "../../styles/InvoiceFormStyles";
import { Fragment, useContext } from "react";
import {
  DeviceContext,
  ModifierContext,
  QuoteContext,
} from "../../utils/GlobalContexts";
import { VoucherState } from "../../KulcsUzletApi";

const Comments: React.FC<CommentsProps> = ({
  handleFlip,
  voucher,
  setVoucher,
}) => {
  const styles = useStylesInvoiceForm();
  const { isMobile } = useContext(DeviceContext);
  const isDisabled =
    (voucher as any)?.Status === 5 ||
    (voucher as any)?.State === VoucherState.CANCELLED ||
    (voucher as any)?.State === VoucherState.CANCELLER ||
    (voucher as any)?.State === VoucherState.RESTORED;

  const updateComments = async (e: any) => {
    await setVoucher((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Fragment>
      {isMobile && (
        <div
          style={{
            position: "relative",
            display: "flex",
          }}
          onClick={handleFlip}
        >
          <div
            style={{
              position: "absolute",
              top: 15,
              right: 0,
              display: "flex",
            }}
          >
            <span className={styles.comments}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z" />
              </svg>
            </span>
            <span
              style={{ marginLeft: "5px", marginTop: "5px", cursor: "pointer" }}
            >
              Vissza
            </span>
          </div>
        </div>
      )}
      <div
        style={{
          padding: isMobile ? 0 : "15px",
          marginTop: isMobile ? "50px" : 0,
          height: "100%",
          paddingBottom: isMobile ? "100px" : "15px",
        }}
      >
        <div
          style={{ display: isMobile ? "block" : "flex", marginBottom: "15px" }}
        >
          <Label
            style={{
              whiteSpace: "nowrap",
              marginRight: "15px",
            }}
          >
            Felső megjegyzés
          </Label>
          <Textarea
            disabled={
              isDisabled ||
              (localStorage.getItem("voucherType") === "customerdeliverybill" &&
                localStorage.getItem("action") === "edit")
            }
            className={styles.textarea}
            name={
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerdeliverybill" ||
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerorder" ||
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerbid" ||
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerproformainvoice" ||
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerreceipt"
                ? "TopComment"
                : "TopNotes"
            }
            value={
              (voucher as any)?.TopNotes ?? (voucher as any)?.TopComment ?? ""
            }
            onChange={(event) => updateComments(event)}
            defaultValue={""}
            maxLength={500}
          />
        </div>
        <div style={{ display: isMobile ? "block" : "flex" }}>
          <Label style={{ whiteSpace: "nowrap", marginRight: "20px" }}>
            Alsó megjegyzés
          </Label>
          <Textarea
            disabled={
              isDisabled ||
              (localStorage.getItem("voucherType") === "customerdeliverybill" &&
                localStorage.getItem("action") === "edit")
            }
            className={styles.textarea}
            name={
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerdeliverybill" ||
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerorder" ||
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerbid" ||
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerproformainvoice" ||
              localStorage.getItem("voucherType")?.toLocaleLowerCase() ===
                "customerreceipt"
                ? "BottomComment"
                : "BottomNotes"
            }
            value={
              (voucher as any)?.BottomNotes ??
              (voucher as any)?.BottomComment ??
              ""
            }
            onChange={(event) => updateComments(event)}
            defaultValue={""}
            maxLength={500}
          />
        </div>
        {!isMobile && (
          <div
            style={{
              position: "relative",

              display: "flex",
            }}
            onClick={handleFlip}
          >
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                left: "20px",
                display: "flex",
              }}
            >
              <span className={styles.comments}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z" />
                </svg>
              </span>
              <span
                style={{
                  marginLeft: "5px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              >
                Vissza
              </span>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Comments;
