import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import InputComponent from "../input/InputComponent";
import { useEffect, useState } from "react";
import { DialogCurrencyProps } from "../../interfaces/DialogCurrencyProps";

const DialogSetCurrency: React.FC<DialogCurrencyProps> = ({
  open,
  setOpen,
  currencyRate,
  setCurrencyRate,
}) => {
  const [currencyValue, setCurrencyValue] = useState(1);

  const handleSaveCurrencyRate = () => {
    setCurrencyRate && setCurrencyRate(currencyValue);
    setOpen(false);
  };

  useEffect(() => {
    if (open) setCurrencyValue(currencyRate ?? 1);
  }, [open]);

  const onChangeCurrencyValue = (e: any) => {
    setCurrencyValue(e.target.value);
  };

  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title="Árfolyam beállítása"
      saveAction={handleSaveCurrencyRate}
      saveBtnText="Mentés"
    >
      <div>
        Ez az árfolyam lesz alkalmazva azoknál a termékeknél, amelyeknél a
        kiválaszott valutában nem lett megadva egyedi ár.
      </div>
      <InputComponent
        numeric
        fullwidth
        value={currencyValue}
        onChange={onChangeCurrencyValue}
        name={""}
      />
    </DialogSkeleton>
  );
};

export default DialogSetCurrency;
