import {
  VoucherService,
  VoucherType,
  CustomerBidDto,
  CustomerOrderDto,
  CustomerProformaInvoiceDto,
  CustomerDeliveryBillDto,
  CustomerReceiptDto,
} from "../KulcsUzletApi";
import { FilterOrder, FiltersObject } from "../models/FilterObj";

export const columns = [
  "TERMÉK",
  "",
  "EGYSÉGÁR",
  "ÁFA %",
  "KEDVEZMÉNY",
  "NETTÓ",
  "ÁFA",
  "BRUTTÓ",
];

export const columnsReceipt = ["", "DARABSZÁM", "TERMÉK", "ÁFA %", "BRUTTÓ"];

export const defaultNotDeletedFilter = {
  FieldName: "Deleted",
  FilterValue: 0,
  OperatorType: 0,
  FieldType: 0,
};

export const defaultFilterObj = {
  Skip: 0,
  Take: 10,
  Orders: [
    {
      FieldName: "Name",
      OrderWay: 0,
      OrderType: 0,
    } as FilterOrder,
  ],
  Filters: [[defaultNotDeletedFilter]],
} as FiltersObject;

export const DayPickerStrings = {
  months: [
    "Január",
    "Február",
    "Március",
    "Április",
    "Május",
    "Június",
    "Július",
    "Augusztus",
    "Szeptember",
    "Október",
    "November",
    "December",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Márc",
    "Ápr",
    "Máj",
    "Jún",
    "Júl",
    "Aug",
    "Szep",
    "Okt",
    "Nov",
    "Dec",
  ],

  days: [
    "Vasárnap",
    "Hétfő",
    "Kedd",
    "Szerda",
    "Csütörtök",
    "Péntek",
    "Szombat",
  ],

  shortDays: ["V", "H", "K", "SZ", "CS", "P", "SZ"],

  goToToday: "Ugrás a mai napra",
  prevMonthAriaLabel: "Előző hónap",
  nextMonthAriaLabel: "Következő hónap",
  prevYearAriaLabel: "Előző év",
  nextYearAriaLabel: "Következő év",
};

export const quoteNames = [
  { Id: 4, Name: "Bizonylat" },
  { Id: 5, Name: "Payment Request" },
  { Id: 6, Name: "Quotation" },
  { Id: 7, Name: "Purchase Order" },
];

export const defaultInvoicingTypes = [
  { Id: "PAPER", Name: "Papír alapú számla" },
  { Id: "ELECTRONIC", Name: "Elektronikus számla" },
  { Id: "BOTH", Name: "Mindkettő" },
];

export const getNewCustomerBidDto = async (isSalesPlusPlus: boolean) => {
  const comments = await VoucherService.getVoucherDefaultdocumentcomments();
  let currency: number | undefined = 245;
  if (isSalesPlusPlus) {
    const voucherSequence = await VoucherService.getVoucherVouchersequence(
      VoucherType.CUSTOMER_BID
    );
    currency = voucherSequence.Currency;
  }
  const voucherNumber = await VoucherService.getVoucherNextvouchernumber(
    VoucherType.CUSTOMER_BID,
    currency
  ).catch((e) => console.log(e));
  return {
    Id: undefined,
    VoucherNumber: voucherNumber ?? "",
    CurrencyId: currency,
    CurrencyRate: 1,
    DiscountPercent: undefined,
    Items: [],
    VoucherDate: new Date().toString(),
    TransportDate: null,
    ExpirationDate: new Date().toString(),
    LanguageInternalCode: "HU",
    TopComment: comments?.DefaultQuoteTopComment ?? "",
    BottomComment: comments?.DefaultQuoteComment ?? "",
    RemotePrint: false,
    VoucherName: "Vevői ajánlat",
    PaymentMethodId: 313,
  } as CustomerBidDto;
};

export const getNewCustomerReceiptDto = async () => {
  let currency: number | undefined = 245;
  const voucherNumber = await VoucherService.getVoucherNextvouchernumber(
    VoucherType.CUSTOMER_RECEIPT,
    currency
  ).catch((e) => console.log(e));
  return {
    Id: undefined,
    VoucherNumber: voucherNumber ?? "",
    CurrencyId: currency,
    CurrencyRate: 1,
    Detail: undefined,
    VoucherDate: new Date().toString(),
    RemotePrint: false,
    VoucherName: "Nyugta",
    PaymentMethodId: 313,
    CustomerName: "",
    CustomerEmail: "",
  } as CustomerReceiptDto;
};

export const getNewCustomerOrderDto = async (
  withoutVoucherNumber = false,
  isSalesPlusPlus: boolean
) => {
  const comments = await VoucherService.getVoucherDefaultdocumentcomments();
  let currency: number | undefined = 245;

  let voucherNumber;
  if (!withoutVoucherNumber) {
    voucherNumber = await VoucherService.getVoucherNextvouchernumber(
      VoucherType.CUSTOMER_ORDER,
      currency
    ).catch((e) => console.log(e));
  }

  if (isSalesPlusPlus) {
    const voucherSequence = await VoucherService.getVoucherVouchersequence(
      VoucherType.CUSTOMER_ORDER
    );
    currency = voucherSequence.Currency;
  }

  return {
    Id: undefined,
    VoucherNumber: voucherNumber ?? "",
    CurrencyId: currency,
    CurrencyRate: 1,
    DiscountPercent: undefined,
    Items: [],
    VoucherDate: new Date().toString(),
    TransportDate: new Date().toString(),
    OrderDate: new Date().toString(),
    ExpectedTransportDate: null,
    LanguageInternalCode: "HU",
    TopComment: comments?.DefaultQuoteTopComment ?? "",
    BottomComment: comments?.DefaultQuoteComment ?? "",
    RemotePrint: false,
    VoucherName: "Vevői rendelés",
    PaymentMethodId: 313,
  } as CustomerOrderDto;
};

export const getNewCustomerProformaInvoiceDto = async (
  withoutVoucherNumber = false,
  isSalesPlusPlus: boolean
) => {
  const comments = await VoucherService.getVoucherDefaultdocumentcomments();
  let currency: number | undefined = 245;

  let voucherNumber;
  if (!withoutVoucherNumber) {
    voucherNumber = await VoucherService.getVoucherNextvouchernumber(
      VoucherType.CUSTOMER_PROFORMA_INVOICE,
      currency
    ).catch((e) => console.log(e));
  }

  if (isSalesPlusPlus) {
    const voucherSequence = await VoucherService.getVoucherVouchersequence(
      VoucherType.CUSTOMER_PROFORMA_INVOICE
    );
    currency = voucherSequence.Currency;
  }

  return {
    Id: undefined,
    VoucherNumber: voucherNumber ?? "",
    CurrencyId: currency,
    CurrencyRate: 1,
    DiscountPercent: undefined,
    Items: [],
    VoucherDate: new Date().toString(),
    PaymentDate: new Date().toString(),
    FulfillmentDate: new Date().toString(),
    LanguageInternalCode: "HU",
    TopComment: comments?.DefaultQuoteTopComment ?? "",
    BottomComment: comments?.DefaultQuoteComment ?? "",
    RemotePrint: false,
    VoucherName: "Díjbekérő",
    PaymentMethodId: 313,
  } as CustomerProformaInvoiceDto;
};

export const getNewCustomerDeliveryBillDto = async (
  withoutVoucherNumber = false,
  isSalesPlusPlus: boolean
) => {
  const comments = await VoucherService.getVoucherDefaultdocumentcomments();
  let currency: number | undefined = 245;
  let voucherNumber;
  if (!withoutVoucherNumber) {
    voucherNumber = await VoucherService.getVoucherNextvouchernumber(
      VoucherType.CUSTOMER_DELIVERY_BILL,
      currency
    ).catch((e) => console.log(e));
  }

  if (isSalesPlusPlus) {
    const voucherSequence = await VoucherService.getVoucherVouchersequence(
      VoucherType.CUSTOMER_DELIVERY_BILL
    );
    currency = voucherSequence.Currency;
  }

  return {
    Id: undefined,
    VoucherNumber: voucherNumber ?? "",
    CurrencyId: currency,
    CurrencyRate: 1,
    DiscountPercent: undefined,
    Items: [],
    VoucherDate: new Date().toString(),
    FulfillmentDate: new Date().toString(),
    LanguageInternalCode: "HU",
    TopComment: comments?.DefaultQuoteTopComment ?? "",
    BottomComment: comments?.DefaultQuoteComment ?? "",
    RemotePrint: false,
    VoucherName: "Szállítólevél",
  } as CustomerDeliveryBillDto;
};
