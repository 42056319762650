import { useState, useContext, Fragment } from "react";
import { InvoiceFormProps } from "../../interfaces/InvoiceFormProps";
import { ModifierContext, MasterDataContext } from "../../utils/GlobalContexts";
import Comments from "../comments/Comments";
import CardFlip from "../card-flip/CardFlip";
import { Label } from "@fluentui/react-components";
import SelectComponent from "../select/SelectComponent";
import { ProjectDto, ModifierInvoiceDto } from "../../KulcsUzletApi";
import { DatePicker, DayOfWeek } from "@fluentui/react-datepicker-compat";
import { DayPickerStrings } from "../../utils/GlobalConsts";
import InputComponent from "../input/InputComponent";
import {
  formatNumberWithQuantityUnit,
  calculatePercentage,
  trimLongString,
} from "../../utils/HelperFunctions";
import { getNetValue } from "../../utils/ModifierFunctions";

const ModifierFormMobile: React.FC<InvoiceFormProps> = ({
  openModal,
  originalItemIds,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlip = () => setIsFlipped(!isFlipped);
  const { modifier, setModifier } = useContext(ModifierContext);
  const { projects, setProjects } = useContext(MasterDataContext);

  const changeProject = (ev: any, data: any) => {
    const project =
      data.optionValue === "Üres..." ? null : (data.optionValue as ProjectDto);
    setModifier((prev: ModifierInvoiceDto) => ({
      ...prev,
      Project: project,
    }));
  };

  const updateItemQuantity = (
    index: number,
    newValue: any,
    input?: boolean
  ) => {
    setModifier((prevState: ModifierInvoiceDto) => {
      if (!prevState) return null;
      const updatedItems = [...(prevState.DetailList ?? [])];
      const updatedQuantity = input
        ? newValue
        : (updatedItems[index]?.Quantity || 0) + newValue;

      updatedItems[index] = {
        ...updatedItems[index],
        Quantity: updatedQuantity === "" ? 0 : updatedQuantity,
      };

      return {
        ...prevState,
        DetailList: updatedItems,
      };
    });
  };

  const removeNullQuantity = (index: number) => {
    setModifier((prevState: ModifierInvoiceDto) => {
      if (!prevState) return null;
      const updatedItems = [...(prevState.DetailList ?? [])];
      let item = updatedItems[index];
      if (
        item.Quantity === undefined ||
        item.Quantity === null ||
        item?.Quantity <= 0
      )
        updatedItems.splice(index, 1);
      return {
        ...prevState,
        DetailList: updatedItems,
      };
    });
  };

  const changeDateProp = (date: Date | null | undefined, property: string) =>
    setModifier((prev: ModifierInvoiceDto) => ({
      ...prev,
      [property]: date,
    }));

  return (
    <CardFlip isFlipped={isFlipped}>
      <div style={{ paddingBottom: "100px" }}>
        <div style={{ textAlign: "right", fontWeight: 700, fontSize: "17px" }}>
          {modifier?.InvoiceNumber}
        </div>

        <div style={{ textAlign: "right", marginBottom: 10, marginTop: 10 }}>
          <span
            style={{
              marginRight: 10,
            }}
          >
            Nyelv:
          </span>
          <span
            style={{
              marginRight: 0,
            }}
          >{`Magyar / ${modifier?.Language}`}</span>
        </div>

        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "calc(100% - 80px)",
              display: "inline-block",
              padding: "5px",
              fontFamily: "'Oxygen', sans-serif",
              paddingBottom: "5px",
              borderRight: "1px solid rgba(149, 183, 211, 0.75)",
            }}
          >
            <div
              style={{
                fontSize: "22px",
                wordBreak: "break-all",
              }}
            >
              {modifier?.CustomerName}
            </div>
            <div
              style={{
                fontFamily: "'Oxygen', sans-serif",
                wordBreak: "break-all",
                marginTop: "10px",
                fontSize: "13px",
              }}
            >
              {modifier?.CustomerAddress}
            </div>
          </div>
          <div
            onClick={handleFlip}
            style={{
              width: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              WebkitJustifyContent: "center",
              WebkitAlignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                width: "25px",
                height: "25px",
                border: "2px solid #000000",
                backgroundColor: "transparent",
                position: "relative",
                WebkitBorderRadius: "50%",
                MozBorderRadius: "50%",
                borderRadius: "50%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <Label
            style={{
              lineHeight: "30px",
              color: "#666666",
              textAlign: "center",
              width: "100%",
            }}
          >
            Projekt
          </Label>
          <span style={{ textAlign: "right", width: "100%" }}>
            <SelectComponent
              transparent
              defaultValue={modifier?.Project?.Name}
              options={projects && (Object.values(projects) as any)}
              action={changeProject}
            />
          </span>
        </div>

        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#666666",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Kelte
            </div>
            <div style={{ textAlign: "center" }}>
              <InputComponent
                transparent
                value={new Date().toLocaleDateString()}
                name=""
                disabled
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ color: "#666666", textAlign: "center" }}>
              Fizetési határidő
            </div>
            <div style={{ textAlign: "center" }}>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                contentAfter={<></>}
                style={{
                  width: "100px",
                  background: "transparent",
                  border: "none",
                }}
                disabled={modifier?.PaymentMethod?.ImmediatePay === 1}
                onSelectDate={(data: any) =>
                  changeDateProp(data, "PaymentDate")
                }
                value={modifier?.PaymentDate ?? new Date()}
                allowTextInput
                isMonthPickerVisible={true}
                strings={DayPickerStrings}
                formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#666666",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Teljesítés
            </div>
            <div style={{ textAlign: "center" }}>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                disabled
                isMonthPickerVisible={true}
                strings={DayPickerStrings}
                contentAfter={<></>}
                style={{
                  width: "100px",
                  background: "transparent",
                  border: "none",
                }}
                onSelectDate={(data: any) =>
                  changeDateProp(data, "FulfillmentDate")
                }
                value={modifier?.FulfillmentDate ?? new Date()}
                allowTextInput
                formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ color: "#666666", textAlign: "center" }}>
              Fizetési mód
            </div>
            <div style={{ textAlign: "center" }}>
              <InputComponent
                transparent
                value={modifier?.PaymentMethod?.Name ?? ""}
                name=""
                disabled
              />
            </div>
          </div>
        </div>

        {modifier?.DetailList?.map((detail, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "rgba(221, 232, 241, 0.75)",
              borderBottom: "2px solid white",
              padding: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "60%", overflow: "hidden" }}>
                {trimLongString(detail?.ProductNameDisplay, 30)}
              </div>
              <div
                style={{ width: "40%" }}
              >{`${detail?.DiscountPercent} %`}</div>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "50%", marginTop: "5px" }}>
                {detail?.Id && !originalItemIds?.includes(detail?.Id) ? (
                  <Fragment>
                    <InputComponent
                      small
                      numeric
                      name="Quantity"
                      value={detail?.Quantity ?? 0}
                      onChange={(e: any, data: any) =>
                        updateItemQuantity(index, data.value, true)
                      }
                      onFocusLeave={() => removeNullQuantity(index)}
                    />
                    <span style={{ marginLeft: "3px" }}>
                      {detail.QuantityName}
                    </span>
                  </Fragment>
                ) : (
                  <Fragment>
                    <span style={{ fontWeight: 700, marginRight: "5px" }}>
                      {detail?.Quantity}
                    </span>
                    <span>{detail?.QuantityName}</span>
                  </Fragment>
                )}
              </div>
              <div style={{ width: "50%", marginTop: "5px" }}>
                {`${detail.VatPercent} % [ ${formatNumberWithQuantityUnit(
                  calculatePercentage(
                    getNetValue(detail, modifier),
                    detail.VatPercent
                  ),
                  modifier.Currency,
                  modifier.Currency?.VatValueDigits
                )} ]`}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "13px",
              }}
            >
              <div style={{ width: "30%", marginTop: "5px" }}></div>
              <div style={{ width: "70%", marginTop: "5px", fontWeight: 700 }}>
                {formatNumberWithQuantityUnit(
                  getNetValue(detail, modifier),
                  modifier.Currency,
                  modifier.Currency?.NetValueDigits
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div style={{}}>
        <Comments
          handleFlip={handleFlip}
          voucher={modifier}
          setVoucher={setModifier}
        />
      </div>
    </CardFlip>
  );
};

export default ModifierFormMobile;
