import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import InputComponent from "../input/InputComponent";
import { DeviceContext, MasterDataContext } from "../../utils/GlobalContexts";
import {
  CurrencyDto,
  CustomerBidDetailDto,
  CustomerDeliveryBillDetailDto,
  CustomerOrderDetailDto,
  CustomerProformaInvoiceDetailDto,
  VatDto,
} from "../../KulcsUzletApi";
import { useContext, useEffect, useState } from "react";
import { Text, Textarea } from "@fluentui/react-components";
import { useStylesDialogEditCustomerStyle } from "../../styles/DialogEditCustomerStyle";
import {
  formatNumberWithQuantityUnit,
  getRoundedNumber,
} from "../../utils/HelperFunctions";
import SelectComponent from "../select/SelectComponent";
import { DialogEditDetailProps } from "../../interfaces/DialogEditDetailProps";
import { getCalculatedUnitPrice } from "../../utils/VoucherFunctions";

const DialogEditDetail: React.FC<DialogEditDetailProps> = ({
  open,
  setOpen,
  detail,
  voucher,
  setVoucher,
}) => {
  const styles = useStylesDialogEditCustomerStyle();
  const [currentDetail, setCurrentDetail] = useState<
    | CustomerOrderDetailDto
    | CustomerDeliveryBillDetailDto
    | CustomerBidDetailDto
    | CustomerProformaInvoiceDetailDto
    | null
  >(null);
  const { vats, currencies } = useContext(MasterDataContext);
  const [currency, setCurrency] = useState<CurrencyDto | undefined | null>(
    undefined
  );
  const [vat, setVat] = useState<VatDto | undefined | null>(undefined);
  const [discountedUnitPrice, setDiscountedUnitPrice] = useState<
    number | undefined | null
  >(undefined);
  const [sum, setSum] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [grossSum, setGrossSum] = useState<number>(0);
  const [grossUnitPrice, setGrossUnitPrice] = useState<number>(0);
  const { isMobile } = useContext(DeviceContext);
  const voucherType = localStorage.getItem("voucherType")?.toLowerCase();

  useEffect(() => {
    if (open) {
      setInitialData();
    } else {
      setVat(undefined);
      setCurrency(undefined);
      setCurrentDetail(null);
      setSum(0);
      setQuantity(0);
      setDiscountedUnitPrice(0);
      setGrossUnitPrice(0);
      setGrossSum(0);
    }
  }, [open]);

  const setInitialData = () => {
    if (!detail) return;

    let initialVat = undefined;
    if (vats && detail?.Vat) initialVat = vats[detail.Vat];
    setVat(initialVat);

    if (!detail.DiscountPercent || detail.DiscountPercent === 0)
      detail.DiscountPercent = voucher?.DiscountPercent ?? 0;
    setCurrentDetail(detail);
    if (currencies && detail.Currency) setCurrency(currencies[detail.Currency]);
    else if (
      currencies &&
      ((voucher as any).Currency ?? (voucher as any).CurrencyId)
    )
      setCurrency(
        currencies[(voucher as any).Currency ?? (voucher as any).CurrencyId]
      );

    setGrossUnitPrice(calculateGross(detail?.UnitPrice, 0, 1, initialVat));
    let grossSumInitial = calculateGross(
      detail?.UnitPrice,
      detail?.DiscountPercent,
      detail?.Quantity,
      initialVat
    );
    setGrossSum(grossSumInitial);
    setQuantity(detail?.Quantity ?? 0);
    setSum((detail?.UnitPrice ?? 0) * (detail?.Quantity ?? 1));

    let discountedUnitPriceInitial = getCalculatedUnitPrice(
      detail?.DiscountPercent,
      detail.UnitPrice
    );
    setDiscountedUnitPrice(discountedUnitPriceInitial);
  };

  const saveDetail = async () => {
    if (!setVoucher) return;
    await setVoucher((prevState: any) => {
      if (!prevState) return null;
      const updatedItems = prevState.Items?.map(
        (
          item:
            | CustomerBidDetailDto
            | CustomerOrderDetailDto
            | CustomerProformaInvoiceDetailDto
            | CustomerDeliveryBillDetailDto
        ) => {
          if (currentDetail && item.Id === currentDetail.Id) {
            if (currentDetail.UnitPrice)
              currentDetail.UnitPrice = getRoundedNumber(
                currency?.UnitPriceDigits,
                currentDetail.UnitPrice
              );
            currentDetail.OriginalPrice = currentDetail.UnitPrice;
            return currentDetail;
          }
          return item;
        }
      );
      return {
        ...prevState,
        Items: updatedItems,
      };
    });
    setOpen(false);
  };

  const calculateUnitPrice = (grossUnitPrice: number | undefined | null) => {
    if (grossUnitPrice && vat?.Rate) {
      const netUnitPrice = grossUnitPrice / (1 + vat.Rate / 100);
      return getRoundedNumber(currency?.UnitPriceDigits, netUnitPrice);
    } else return grossUnitPrice;
  };

  const calculateGross = (
    unitPrice: number | undefined | null,
    discount: number | undefined | null,
    quantity: number | undefined | null,
    vat: VatDto | undefined | null
  ) => {
    if (unitPrice) {
      let curr = currency;
      if (!curr) {
        if (currencies && detail?.Currency) curr = currencies[detail.Currency];
        else if (
          currencies &&
          ((voucher as any).Currency ?? (voucher as any).CurrencyId)
        )
          curr =
            currencies[
              (voucher as any).Currency ?? (voucher as any).CurrencyId
            ];
      }

      const netValue =
        getCalculatedUnitPrice(discount, unitPrice) * (quantity ?? 1);
      const vatValue = 1 + (vat?.Rate ?? 0) / 100;
      return getRoundedNumber(curr?.UnitPriceDigits, netValue * vatValue);
    }
    return 0;
  };

  const grossUnitPriceChanged = (grossUnitPrice: number) => {
    setGrossUnitPrice(grossUnitPrice);
    let unitPrice = calculateUnitPrice(grossUnitPrice);
    setCurrentDetail((prev) => ({
      ...prev,
      UnitPrice: unitPrice,
    }));

    let grossSumUpdated = calculateGross(
      unitPrice,
      currentDetail?.DiscountPercent,
      currentDetail?.Quantity,
      vat
    );
    setGrossSum(grossSumUpdated);
    let sumUpdated = (unitPrice ?? 0) * (currentDetail?.Quantity ?? 1);
    setSum(sumUpdated);

    let discountedUnitPriceUpdated = getCalculatedUnitPrice(
      currentDetail?.DiscountPercent,
      unitPrice
    );

    setDiscountedUnitPrice(discountedUnitPriceUpdated);
  };
  const quantityChanged = (quantity: number) => {
    let grossSumUpdated = calculateGross(
      currentDetail?.UnitPrice ?? 0,
      currentDetail?.DiscountPercent,
      quantity,
      vat
    );
    setGrossSum(grossSumUpdated);

    const netValue =
      getCalculatedUnitPrice(
        currentDetail?.DiscountPercent,
        currentDetail?.UnitPrice
      ) * quantity;
    setSum(netValue);
  };
  const discountPercentChanged = (discount: number) => {
    let grossSumUpdated = calculateGross(
      currentDetail?.UnitPrice ?? 0,
      discount,
      currentDetail?.Quantity,
      vat
    );
    setGrossSum(grossSumUpdated);
    const netValue =
      getCalculatedUnitPrice(discount, currentDetail?.UnitPrice) *
      (currentDetail?.Quantity ?? 1);
    setSum(netValue);
    let discountedUnitPriceUpdated = getCalculatedUnitPrice(
      discount,
      currentDetail?.UnitPrice
    );

    setDiscountedUnitPrice(discountedUnitPriceUpdated);
  };

  const unitPriceChanged = (unitPrice: number) => {
    setGrossUnitPrice(calculateGross(unitPrice, 0, 1, vat));

    let grossSumUpdated = calculateGross(
      unitPrice,
      currentDetail?.DiscountPercent,
      currentDetail?.Quantity,
      vat
    );
    setGrossSum(grossSumUpdated);
    const netValue =
      getCalculatedUnitPrice(currentDetail?.DiscountPercent, unitPrice) *
      (currentDetail?.Quantity ?? 1);
    setSum(netValue);
    let discountedUnitPriceUpdated = currentDetail?.DiscountPercent
      ? getCalculatedUnitPrice(currentDetail?.DiscountPercent, unitPrice)
      : undefined;

    setDiscountedUnitPrice(discountedUnitPriceUpdated);
  };

  const updateCurrentDetail = (
    e: any,
    checkForMin?: number,
    checkforMax?: number
  ) => {
    if (e.target.name !== "GrossUnitPrice") {
      setCurrentDetail((prev) => ({
        ...prev,
        [e.target.name]:
          checkForMin !== undefined && e.target.value < checkForMin
            ? checkForMin
            : checkforMax !== undefined && e.target.value > checkforMax
            ? checkforMax
            : e.target.value,
      }));
    }
    const value =
      checkForMin && e.target.value < checkForMin
        ? checkForMin
        : e.target.value ?? 0;
    switch (e.target.name) {
      case "Quantity":
        if (quantity !== value) setQuantity(value);
        quantityChanged(value);
        break;
      case "UnitPrice":
        unitPriceChanged(value);
        break;
      case "GrossUnitPrice":
        grossUnitPriceChanged(value);
        break;
      case "DiscountPercent":
        discountPercentChanged(value);
        break;
      default:
        break;
    }
  };

  const editDetailVat = async (ev: any, data: any) => {
    const updatedVat = data.optionValue as VatDto;

    const vatValue = 1 + (updatedVat?.Rate ?? 0) / 100;
    const grossUnitPriceUpdated = getRoundedNumber(
      currency?.UnitPriceDigits,
      (currentDetail?.UnitPrice ?? 0) * vatValue
    );
    let grossSumUpdated = calculateGross(
      currentDetail?.UnitPrice,
      currentDetail?.DiscountPercent,
      currentDetail?.Quantity,
      updatedVat
    );
    setGrossSum(grossSumUpdated);

    setCurrentDetail((prev) => ({
      ...prev,
      Vat: updatedVat?.Id ?? undefined,
    }));
    setGrossUnitPrice(grossUnitPriceUpdated);
    setVat(updatedVat);
  };

  const updateComments = async (e: any) => {
    await setCurrentDetail((prevState: any) => ({
      ...prevState,
      DetailComment: e.target.value,
    }));
  };

  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title="Tétel"
      saveAction={saveDetail}
      saveBtnText="Mentés"
      closeBtnText="Mégse"
    >
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Név
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={currentDetail?.ProductNameDisplay ?? ""}
            name={"ProductNameDisplay"}
            onChange={(e: any) => updateCurrentDetail(e, undefined)}
          />
        </div>
      </div>
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Mennyiség
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={quantity}
            name={"Quantity"}
            min={1}
            numeric
            onChange={(e: any) => setQuantity(e.target.value ?? 1)}
            onFocusLeave={(e: any) => updateCurrentDetail(e, 1)}
          />
        </div>
      </div>
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Egységár
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            contentBefore={
              currency?.IsPost === 0 ? (
                <Text size={400} id={"currency-input"}>
                  {currency?.Sign}
                </Text>
              ) : (
                ""
              )
            }
            contentAfter={
              currency?.IsPost === 1 || !currency ? (
                <Text size={400} id={"currency-input"}>
                  {currency?.Sign ?? "Ft"}
                </Text>
              ) : (
                ""
              )
            }
            fullwidth
            value={
              currentDetail?.UnitPrice ?? calculateUnitPrice(grossUnitPrice)
            }
            name={"UnitPrice"}
            numeric
            onChange={(e: any) => updateCurrentDetail(e, undefined)}
          />
        </div>
      </div>
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Bruttó egységár
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={grossUnitPrice}
            contentBefore={
              currency?.IsPost === 0 ? (
                <Text size={400} id={"currency-input"}>
                  {currency?.Sign}
                </Text>
              ) : (
                ""
              )
            }
            contentAfter={
              currency?.IsPost === 1 || !currency ? (
                <Text size={400} id={"currency-input"}>
                  {currency?.Sign ?? "Ft"}
                </Text>
              ) : (
                ""
              )
            }
            numeric
            name={"GrossUnitPrice"}
            onChange={(e: any) => updateCurrentDetail(e, undefined)}
          />
        </div>
      </div>
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            {voucherType === "customerbid" ||
            voucherType === "customerorder" ||
            voucherType === "customerdeliverybill" ||
            voucherType === "customerproformainvoice"
              ? "Kedvezmény/Felár (%)"
              : "Kedvezmény (%)"}
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={currentDetail?.DiscountPercent ?? 0}
            name={"DiscountPercent"}
            numeric
            min={
              voucherType === "customerbid" ||
              voucherType === "customerorder" ||
              voucherType === "customerdeliverybill" ||
              voucherType === "customerproformainvoice"
                ? -100
                : 0
            }
            max={200}
            onChange={(e: any) => updateCurrentDetail(e, -100, 200)}
          />
        </div>
      </div>
      {discountedUnitPrice !== undefined &&
      discountedUnitPrice !== null &&
      discountedUnitPrice !== 0 ? (
        <div
          className={isMobile ? styles.mobileline : styles.line}
          style={{ marginBottom: isMobile ? 5 : 0 }}
        >
          <div className={isMobile ? styles.mobilefpart : styles.fpart}>
            <div
              className={isMobile ? styles.mobilefpart : styles.fpartText}
              style={{ marginTop: isMobile ? 0 : -15 }}
            >
              Kedvezményes egységár
            </div>
          </div>
          <div className={isMobile ? styles.mobilespart : styles.spart}>
            <span>
              {currency &&
                formatNumberWithQuantityUnit(
                  discountedUnitPrice,
                  currency,
                  currency?.UnitPriceDigits
                )}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        className={isMobile ? styles.mobileline : styles.line}
        style={{ marginBottom: isMobile ? 5 : 0 }}
      >
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Összeg
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <span>
            {currency &&
              formatNumberWithQuantityUnit(
                sum,
                currency,
                currency?.NetValueDigits
              )}
          </span>
        </div>
      </div>
      <div
        className={isMobile ? styles.mobileline : styles.line}
        style={{ marginBottom: isMobile ? 5 : 0 }}
      >
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Bruttó összeg
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <span>
            {currency &&
              formatNumberWithQuantityUnit(
                grossSum,
                currency,
                currency?.GrossValueDigits
              )}
          </span>
        </div>
      </div>
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            ÁFA
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <SelectComponent
            noEmptyOption
            fullwidth
            options={
              vats &&
              (Object.values(vats).sort((a, b) =>
                (a.Name ?? "").localeCompare(b.Name ?? "")
              ) as any)
            }
            value={
              (vats &&
                currentDetail?.Vat &&
                vats[currentDetail?.Vat]?.VatCode) ??
              ""
            }
            action={editDetailVat}
          />
        </div>
      </div>
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Megjegyzések
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <Textarea
            style={{
              width: "100%",
              height: isMobile ? "150px" : "200px",
              overflow: "hidden",
            }}
            name="DetailComment"
            value={currentDetail?.DetailComment ?? ""}
            onChange={(event) => updateComments(event)}
            defaultValue={""}
            maxLength={500}
          />
        </div>
      </div>
    </DialogSkeleton>
  );
};

export default DialogEditDetail;
