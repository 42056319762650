/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VoucherState {
    INVALID = 'Invalid',
    OPEN = 'Open',
    NORMAL = 'Normal',
    FULFILLED_ANY = 'FulfilledAny',
    FULFILLED = 'Fulfilled',
    RESTORED = 'Restored',
    CANCELLED = 'Cancelled',
    CANCELLER = 'Canceller',
    MODIFIED = 'Modified',
    MODIFIER = 'Modifier',
    PROFORMA_RELATION = 'ProformaRelation',
    APPLIED = 'Applied',
    AVAILABLE_ANY = 'AvailableAny',
    ACQUITED = 'Acquited',
    DELIVERED = 'Delivered',
    DELIVERED_ANY = 'DeliveredAny',
    ACCEPTED = 'Accepted',
    ACCEPTED_ANY = 'AcceptedAny',
    DELETED = 'Deleted',
}
