import { TableBody, TableCell, TableRow } from "@fluentui/react-components";
import { useStylesVoucherDetail } from "../../styles/VoucherDetailStyles";
import { columns } from "../../utils/GlobalConsts";
import { Fragment, useContext, useEffect, useState } from "react";
import { COLOR } from "../../utils/Enums";
import {
  calculatePercentage,
  formatNumberWithQuantityUnit,
  trimLongString,
} from "../../utils/HelperFunctions";
import {
  CurrencyDto,
  CustomerBidDto,
  CustomerDeliveryBillDetailDto,
  CustomerDeliveryBillDto,
  QuantityUnitDto,
  QuoteDto,
  VatDto,
  VoucherState,
} from "../../KulcsUzletApi";
import { MasterDataContext } from "../../utils/GlobalContexts";
import { Delete16Filled, Edit16Filled } from "@fluentui/react-icons";
import {
  getCalculatedUnitPrice,
  getNetValue,
} from "../../utils/VoucherFunctions";
import { ClipLoader } from "react-spinners";
import { VoucherDetailTableProps } from "../../interfaces/VoucherDetailTableProps";

const VoucherDetailTable: React.FC<VoucherDetailTableProps> = ({
  voucher,
  setVoucher,
  selectDetail,
  isLoading,
}) => {
  const styles = useStylesVoucherDetail();
  const [highlightedRows, setHighlightedRows] = useState<number[]>([]);
  const [prevDetails, setPrevDetails] = useState<
    CustomerDeliveryBillDetailDto[] | undefined
  >([]);
  const isDisabled =
    voucher?.State === VoucherState.CANCELLED ||
    voucher?.State === VoucherState.CANCELLER ||
    voucher?.State === VoucherState.RESTORED;
  const { currencies, vats, quantityUnits, products } =
    useContext(MasterDataContext);

  useEffect(() => {
    setVoucher((prevState: any) => {
      if (!prevState) return null;
      const updatedItems = [...(prevState.DetailList ?? [])];
      return {
        ...prevState,
        DetailList: updatedItems,
      };
    });
  }, []);

  const highlight = (id: number | null | undefined) => {
    if (!id) return;
    setHighlightedRows((prev) => [...prev, id]);
    setTimeout(() => {
      setHighlightedRows((prev) => prev.filter((item) => item !== id));
    }, 1000);
  };

  useEffect(() => {
    setPrevDetails(voucher?.Items ?? []);
  }, [voucher?.Items]);

  const detailChangedLogic = (
    newDetail: CustomerDeliveryBillDetailDto,
    oldDetail: CustomerDeliveryBillDetailDto | undefined
  ) => {
    return oldDetail !== undefined && newDetail.Quantity !== oldDetail.Quantity;
  };

  useEffect(() => {
    voucher?.Items?.forEach((newdetail) => {
      if (!prevDetails) return;
      const olddetail = prevDetails.find((x) => x.Id === newdetail.Id);

      if (detailChangedLogic(newdetail, olddetail)) {
        highlight(newdetail.Id);
      }
    });
    const tableBody = document.querySelector("#table-body");
    if (tableBody) {
      const lastRow = tableBody.querySelector("tr:last-child");
      if (lastRow) {
        lastRow.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, [voucher?.Items, prevDetails]);

  const editDetailClick = (detail: CustomerDeliveryBillDetailDto) => {
    selectDetail(detail);
  };

  const deleteDetailClick = (index: number) => {
    setVoucher((prevState: CustomerDeliveryBillDto | CustomerBidDto | null) => {
      if (!prevState) return null;
      const updatedItems = [...(prevState.Items ?? [])];
      updatedItems.splice(index, 1);
      return {
        ...prevState,
        Items: updatedItems,
      };
    });
  };

  return (
    <Fragment>
      <div
        style={{
          overflow: "auto",
          height: "380px",
          overflowX: "auto",
          borderBottom: `1px solid ${COLOR.BIZ_PURPLE}`,
        }}
      >
        {isLoading ? (
          <div
            style={{
              textAlign: "center",
              margin: "20px",
            }}
          >
            <ClipLoader />
          </div>
        ) : (
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{
              tableLayout: "auto",
              width: "100%",
              padding: 0,
              borderCollapse: "collapse",
            }}
          >
            <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <tr
                style={{
                  backgroundColor: COLOR.BIZ_PURPLE,
                  color: "white",
                  fontWeight: 400,
                }}
              >
                {columns.map((column, index) => (
                  <th
                    style={{
                      fontWeight: 400,
                      padding: "5px",
                      fontSize: "12.5px",
                    }}
                    key={index}
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>

            <TableBody id="table-body">
              {voucher?.Items?.map((detail, index) => {
                if (!detail) return;
                let currency: CurrencyDto | null | undefined = undefined;
                if (currencies && detail?.Currency)
                  currency = currencies[detail.Currency];

                let quantityUnit: QuantityUnitDto | null | undefined =
                  undefined;
                if (quantityUnits && detail?.QuantityUnit)
                  quantityUnit = quantityUnits[detail.QuantityUnit];

                let vat: VatDto | null | undefined = undefined;
                if (vats && detail?.Vat) vat = vats[detail.Vat];

                let discount = detail?.DiscountPercent ?? 0;

                let unitPriceMinusDiscount = getCalculatedUnitPrice(
                  detail?.DiscountPercent,
                  detail.UnitPrice
                );

                let netValue = getNetValue(
                  unitPriceMinusDiscount,
                  detail.Quantity
                );

                let grossValue = netValue * (1 + (vat?.Rate ?? 0) / 100);
                let vatValue = calculatePercentage(netValue, vat?.Rate);

                return (
                  <TableRow
                    id={`index-${index}`}
                    className={
                      detail.Id && highlightedRows.includes(detail.Id)
                        ? styles.highlightedRow
                        : styles.normalRow
                    }
                    key={index}
                  >
                    {/* MENNYISÉG */}
                    <TableCell
                      style={{ width: "50px" }}
                      className={styles.cell}
                    >
                      <div style={{ float: "right" }}>
                        {!isDisabled &&
                          !(
                            localStorage.getItem("action") === "edit" &&
                            localStorage.getItem("voucherType") ===
                              "customerdeliverybill"
                          ) && (
                            <span className="icon-container">
                              <Edit16Filled
                                style={{
                                  cursor: "pointer",
                                  color: COLOR.BIZ_DARK_ORANGE,
                                }}
                                onClick={() => editDetailClick(detail)}
                              />{" "}
                              <Delete16Filled
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                  color: COLOR.BIZ_DARK_ORANGE,
                                }}
                                onClick={() => deleteDetailClick(index)}
                              />
                            </span>
                          )}
                        <span>{`${detail?.Quantity} ${quantityUnit?.Name}`}</span>{" "}
                      </div>
                    </TableCell>

                    {/* TERMÉK */}
                    <TableCell
                      className={styles.cell}
                      style={{
                        fontWeight: 700,
                        width: "15%",
                        overflow: "hidden",
                      }}
                    >
                      {trimLongString(detail.ProductNameDisplay, 30)}
                    </TableCell>

                    {/* EGYSÉGÁR */}
                    <TableCell className={styles.cell}>
                      {formatNumberWithQuantityUnit(
                        unitPriceMinusDiscount,
                        currency,
                        currency?.UnitPriceDigits
                      )}
                    </TableCell>

                    {/* ÁFA % */}
                    <TableCell
                      style={{ width: "50px" }}
                      className={styles.cell}
                    >
                      {`${vat?.Rate} %`}
                    </TableCell>

                    {/* KEDVEZMÉNY */}
                    <TableCell
                      style={{ width: "50px" }}
                      className={styles.cell}
                    >
                      {`${discount} %`}
                    </TableCell>

                    {/* NETTÓ */}
                    <TableCell className={styles.cell}>
                      {formatNumberWithQuantityUnit(
                        netValue,
                        currency,
                        currency?.NetValueDigits
                      )}
                    </TableCell>

                    {/* ÁFA */}
                    <TableCell className={styles.cell}>
                      {formatNumberWithQuantityUnit(
                        vatValue,
                        currency,
                        currency?.VatValueDigits
                      )}
                    </TableCell>

                    {/* BRUTTÓ */}
                    <TableCell className={styles.cell}>
                      {vats && detail.Vat
                        ? formatNumberWithQuantityUnit(
                            grossValue,
                            currency,
                            currency?.GrossValueDigits
                          )
                        : ""}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </table>
        )}
      </div>
    </Fragment>
  );
};

export default VoucherDetailTable;
