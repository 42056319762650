import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import InputComponent from "../input/InputComponent";
import { useState } from "react";
import { Checkbox, Textarea } from "@fluentui/react-components";
import { useStylesDialogEditCustomerStyle } from "../../styles/DialogEditCustomerStyle";
import { DeviceContext, MasterDataContext } from "../../utils/GlobalContexts";
import {
  CustomerReceiptDto,
  DocumentPrintService,
  MasterDataService,
  SendDocumentRequestDto,
} from "../../KulcsUzletApi";
import InfoIcon from "../icons/InfoIcon";
import _ from "lodash";
import { DialogSendEmailProps } from "../../interfaces/DialogSendEmailProps";

const DialogSendEmail: React.FC<DialogSendEmailProps> = ({
  open,
  setOpen,
  handleSaveVoucher,
  openResultModal,
  voucher,
  voucherType,
}) => {
  const [email, setEmail] = useState<SendDocumentRequestDto | undefined>(
    undefined
  );
  const styles = useStylesDialogEditCustomerStyle();
  const { isMobile } = React.useContext(DeviceContext);
  const [showTooltips, setShowToolTips] = useState([] as string[]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const { userSettings } = React.useContext(MasterDataContext);

  React.useEffect(() => {
    if (open) getDefaultEmail();
    else {
      setEmail(undefined);
      setIsSaveLoading(false);
    }
  }, [open]);

  const getDefaultEmail = async () => {
    let companyName: string = "";
    await MasterDataService.getMasterdataCompanyprofile()
      .then((res) => {
        companyName = res.CompanyName ?? "";
      })
      .catch(() => {});

    let emailBody = userSettings?.InvoiceEmailBodyText?.replace(
      /\[Partner neve\]/g,
      (voucher as any)?.CustomerObj?.Name ??
        (voucher as CustomerReceiptDto)?.CustomerName ??
        ""
    ).replace(/\[Cegnev\]/g, companyName);

    let emailSubject = "{{title}} érkezett {{company}} partneredtől!"
      .replace(/\{\{company\}\}/g, companyName)
      .replace(
        /\{\{title\}\}/g,
        (voucher as any)?.QuoteName ?? (voucher as any)?.VoucherName ?? ""
      );

    setEmail({
      VoucherNumber: voucher?.VoucherNumber,
      VoucherId: 0,
      VoucherType: voucherType,
      VoucherTitle:
        (voucher as any)?.QuoteName ?? (voucher as any)?.VoucherName ?? "",
      ClientEmail:
        (voucher as any)?.Email ??
        (voucher as CustomerReceiptDto)?.CustomerEmail ??
        (voucher as any)?.CustomerObj?.Email ??
        "",
      ClientEmails: [] as string[],
      EmailSubject: emailSubject,
      EmailBody: emailBody,
      toIssuer: false,
      toAccountant: false,
      IsEinvoice: false,
    } as SendDocumentRequestDto);
  };

  const handleSendEmail = async () => {
    if (!email?.ClientEmail) {
      openResultModal &&
        openResultModal("Az email cím mező kitöltése kötelező.");
      return;
    }
    if (handleSaveVoucher !== undefined) {
      setIsSaveLoading(true);

      let voucherid = await handleSaveVoucher(true);
      if (voucherid) {
        let updatedDto = _.cloneDeep(email);
        if (updatedDto) updatedDto.VoucherId = voucherid;
        await DocumentPrintService.postDocumentprintSendvoucher(updatedDto)
          .then((res) => {
            if (res.IsSuccess) {
              setOpen(false);
              openResultModal &&
                openResultModal("Az email kiküldése sikeres volt.");
              const voucherType = localStorage.getItem("voucherType");
              window.location.replace(
                `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/${voucherType}s`
              );
            } else {
              openResultModal &&
                openResultModal(
                  `A mentés nem sikerült: ${res?.ErrorMsg ?? ""}`
                );
              setIsSaveLoading(false);
            }
          })
          .catch((e) => {
            openResultModal &&
              openResultModal(`A mentés nem sikerült: ${e?.message}`);
            setIsSaveLoading(false);
          });
      }
    }
  };

  const onChangeInput = (e: any, data: any) => {
    setEmail((prev: any) => ({ ...prev, [e.target.name]: data.value }));
  };

  const addItemToRequiredFields = (item: string) => {
    let fields = [] as string[];
    fields.push(item);
    setShowToolTips(fields);
  };

  React.useEffect(() => {
    if (showTooltips?.length > 0) {
      setTimeout(() => {
        setShowToolTips([]);
      }, 3000);
    }
  }, [showTooltips]);

  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title="Bizonylat küldése e-mailben"
      saveAction={handleSendEmail}
      saveBtnText="Küldés"
      closeBtnText="Mégse"
      isSaveLoading={isSaveLoading}
    >
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Email cím
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={email?.ClientEmail ?? ""}
            onChange={onChangeInput}
            name={"ClientEmail"}
            showTooltip={showTooltips?.includes("Email")}
            tooltip="Több email cím is megadható pontosvesszővel (;) elválasztva."
            tooltipColor="#36bd3f"
            iconCount={1}
            icon={<InfoIcon onClick={() => addItemToRequiredFields("Email")} />}
          />
        </div>
      </div>
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Tárgy
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={email?.EmailSubject ?? ""}
            onChange={onChangeInput}
            name={"EmailSubject"}
          />
        </div>
      </div>

      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Email szövege
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <Textarea
            style={{
              width: "100%",
              height: isMobile ? "150px" : "200px",
              overflow: "hidden",
            }}
            value={email?.EmailBody ?? ""}
            onChange={onChangeInput}
            name={"EmailBody"}
          />
        </div>
      </div>
      <div
        className={isMobile ? styles.mobileline : styles.line}
        style={{ marginTop: isMobile ? "150px" : "160px" }}
      >
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Másolatot kér
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <Checkbox
            checked={email?.toIssuer ?? false}
            onChange={(ev, data) =>
              setEmail((prev: any) => ({ ...prev, toIssuer: data.checked }))
            }
          />
        </div>{" "}
      </div>
    </DialogSkeleton>
  );
};

export default DialogSendEmail;
