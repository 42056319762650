import { PageHeaderProps } from "../../interfaces/PageHeaderProps";
import { usePageHeaderStyle } from "../../styles/PageHeaderStyle";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";

const PageHeader: React.FC<PageHeaderProps> = ({ title, content }) => {
  const styles = usePageHeaderStyle();
  const voucherType = localStorage.getItem("voucherType");
  const kulcsUzletInvoicesUrl = () =>
    voucherType === "modifier"
      ? `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/invoices`
      : `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/${voucherType}s`;

  return (
    <div className={styles.container}>
      <BackIcon
        height="28px"
        width="28px"
        style={{ cursor: "pointer" }}
        onClick={() => window.location.replace(kulcsUzletInvoicesUrl())}
      />
      <div className={styles.title}>{title}</div>
      {content}
    </div>
  );
};

export default PageHeader;
