import {
  CurrencyDto,
  CustomerBidDetailDto,
  CustomerBidDto,
  CustomerDeliveryBillDetailDto,
  CustomerDeliveryBillDto,
  CustomerReceiptDetailDto,
  MasterDataService,
  PaymentMethodDto,
  QuoteDetailDto,
  QuoteDto,
} from "../KulcsUzletApi";
import { VatDictionary } from "../models/Dictionaries";
import {
  calculatePercentage,
  formatNumberWithQuantityUnit,
  getvat,
  needToRoundTo5,
} from "./HelperFunctions";
import _ from "lodash";

export const getVoucherTotal = (
  voucher: QuoteDto | CustomerDeliveryBillDto | CustomerBidDto | undefined,
  paymentMethod: undefined | PaymentMethodDto,
  currency: CurrencyDto | undefined,
  vats: null | VatDictionary
) => {
  const total = getGrossValueSum(voucher, vats);
  return formatNumberWithQuantityUnit(
    total,
    currency,
    currency?.GrandTotalDigits,
    needToRoundTo5(paymentMethod, currency)
  );
};

const getGrossValue = (
  detail: QuoteDetailDto | CustomerDeliveryBillDetailDto | CustomerBidDetailDto,
  vatpercent: number | undefined
) => {
  return (
    getNetValue(
      getCalculatedUnitPrice(detail?.DiscountPercent, detail?.UnitPrice),
      detail.Quantity
    ) *
    (1 + (vatpercent ?? 0) / 100)
  );
};

const getGrossValueSum = (
  voucher: QuoteDto | CustomerDeliveryBillDto | CustomerBidDto | undefined,
  vats: null | VatDictionary
) => {
  if (!voucher?.Items || voucher?.Items?.length === 0) return 0;

  let sum = 0;
  voucher?.Items.map(
    (
      detail:
        | QuoteDetailDto
        | CustomerDeliveryBillDetailDto
        | CustomerBidDetailDto
    ) =>
      (sum += getGrossValue(
        detail,
        vats && detail.Vat ? vats[detail.Vat]?.Rate : undefined
      ))
  );
  return sum;
};

export const getNetValue = (
  unitprice: number,
  quantity: number | undefined | null
) => unitprice * (quantity ?? 0);

export const getVoucherNetValueSum = (
  voucher: QuoteDto | CustomerDeliveryBillDto | CustomerBidDto | undefined
) => {
  if (!voucher?.Items || voucher?.Items?.length === 0) return 0;

  let sum = 0;
  voucher?.Items.map(
    (detail) =>
      (sum += getNetValue(
        getCalculatedUnitPrice(detail?.DiscountPercent, detail?.UnitPrice),
        detail.Quantity ?? 0
      )),
    0
  );
  return sum;
};

export const getVoucherVatValueSum = (
  voucher: QuoteDto | CustomerDeliveryBillDto | CustomerBidDto | undefined,
  vats: null | VatDictionary
) => {
  if (!voucher?.Items || voucher?.Items?.length === 0) return 0;

  let sum = 0;
  voucher?.Items.map(
    (detail) =>
      (sum += calculatePercentage(
        getNetValue(
          getCalculatedUnitPrice(detail?.DiscountPercent, detail?.UnitPrice),
          detail.Quantity
        ),
        getvat(vats, detail.Vat)
      )),
    0
  );

  return sum;
};

export const updateItemUnitPrices = async (
  voucher: CustomerDeliveryBillDto | CustomerBidDto,
  setVoucher: React.Dispatch<
    React.SetStateAction<CustomerDeliveryBillDto | CustomerBidDto | undefined>
  >,
  currencyRate: number
) => {
  let items = _.cloneDeep(voucher?.Items);
  let updateditems: CustomerDeliveryBillDetailDto[] | CustomerBidDetailDto[] =
    [];
  if (items) {
    for (let i of items) {
      const unitprice = await getUnitPrice(
        i,
        voucher?.CurrencyId,
        currencyRate
      );
      i.UnitPrice = Number(unitprice);
      i.Currency = voucher?.CurrencyId;
      updateditems.push(i);
    }
  }
  const updatedDto = {
    ...voucher,
    Items: items,
    CurrencyRate: currencyRate,
  };
  setVoucher(updatedDto);
};

export const getCalculatedUnitPrice = (
  discount: number | null | undefined,
  unitPrice: number | null | undefined
) => {
  return (unitPrice ?? 0) * (1 + (discount ?? 0) / 100);
};

export const getUnitPrice = async (
  item: CustomerDeliveryBillDetailDto | CustomerReceiptDetailDto,
  currencyId: number | null | undefined,
  currencyRate: number
) => {
  let currencyUnitPrice = null;
  let hufNetPrice = null;
  if (currencyId !== 245) {
    const currencyUnitPriceDto = await getCurrencyUnitPriceDto(
      (item as any).Product ?? (item as any).ProductId,
      currencyId
    );

    if (currencyUnitPriceDto) {
      currencyUnitPrice = currencyUnitPriceDto.NetPrice;
    } else {
      const hufUnitPriceDto = await getHufUnitPrice(
        (item as any).Product ?? (item as any).ProductId
      );

      if (hufUnitPriceDto) {
        hufNetPrice = hufUnitPriceDto.NetPrice;
      }
    }
  } else {
    const hufUnitPriceDto = await getHufUnitPrice(
      (item as any).Product ?? (item as any).ProductId
    );

    if (hufUnitPriceDto) {
      hufNetPrice = hufUnitPriceDto.NetPrice ?? 0;
    }
  }
  if (currencyUnitPrice) {
    return currencyUnitPrice;
  } else {
    return (hufNetPrice ?? 0) / (currencyRate ?? 1);
  }
};

export const getCurrencyUnitPriceDto = async (
  productid: any,
  currencyid: any
) => {
  return await MasterDataService.getMasterdataCurrencyprice(
    productid,
    currencyid
  );
};

export const getHufUnitPrice = async (productid: any) => {
  return await MasterDataService.getMasterdataHufprice(productid);
};
