import { useState, useContext, Fragment, useEffect } from "react";
import { VoucherContext, MasterDataContext } from "../../utils/GlobalContexts";
import Comments from "../comments/Comments";
import CardFlip from "../card-flip/CardFlip";
import { Label } from "@fluentui/react-components";
import SelectComponent from "../select/SelectComponent";
import {
  ProjectDto,
  CurrencyDto,
  QuantityUnitDto,
  VatDto,
  TransportModeDto,
  LanguageDto,
  CustomerBidDto,
  VoucherState,
  CustomerBidDetailDto,
  PaymentMethodDto,
} from "../../KulcsUzletApi";
import { DatePicker, DayOfWeek } from "@fluentui/react-datepicker-compat";
import { DayPickerStrings } from "../../utils/GlobalConsts";
import InputComponent from "../input/InputComponent";
import {
  formatNumberWithQuantityUnit,
  calculatePercentage,
  trimLongString,
  getCustomerAddress,
} from "../../utils/HelperFunctions";
import { COLOR, TAB_TYPE } from "../../utils/Enums";
import { Delete16Filled, Edit16Filled } from "@fluentui/react-icons";
import { VoucherFormMobileProps } from "../../interfaces/VoucherFormMobileProps";
import {
  getCalculatedUnitPrice,
  getNetValue,
} from "../../utils/VoucherFunctions";

const CustomerBidFormMobile: React.FC<VoucherFormMobileProps> = ({
  openCustomerModal,
  selectDetail,
  setActiveTab,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlip = () => setIsFlipped(!isFlipped);
  const { voucher, setVoucher } = useContext(VoucherContext);
  const {
    projects,
    paymentMethods,
    currencies,
    quantityUnits,
    vats,
    transportModes,
    languages,
  } = useContext(MasterDataContext);
  const [isCustomerClicked, setIsCustomerClicked] = useState(false);
  const [clickedDetail, setClickedDetail] = useState<number | undefined | null>(
    undefined
  );

  const isSalesPlusPlus = localStorage.getItem("isSalesPlusPlus") === "1";
  const isDisabled =
    voucher?.State === VoucherState.CANCELLED ||
    voucher?.State === VoucherState.CANCELLER ||
    voucher?.State === VoucherState.RESTORED;

  const changeProject = (ev: any, data: any) => {
    setVoucher((prev: CustomerBidDto) => ({
      ...prev,
      Project: (data.optionValue as ProjectDto)?.Id ?? undefined,
    }));
  };

  const changeTransportMode = (ev: any, data: any) => {
    setVoucher((prev: CustomerBidDto) => ({
      ...prev,
      TransportModeId: (data.optionValue as TransportModeDto)?.Id ?? undefined,
    }));
  };

  const changeDateProp = (date: Date | null | undefined, property: string) =>
    setVoucher((prev: CustomerBidDto) => ({
      ...prev,
      [property]: date,
    }));

  const editDetailClick = (detail: CustomerBidDetailDto) => {
    selectDetail(detail);
  };

  const deleteDetailClick = async (index: number) => {
    await setVoucher((prevState: CustomerBidDto | null) => {
      if (!prevState) return null;
      const updatedItems = [...(prevState.Items ?? [])];
      updatedItems.splice(index, 1);
      return {
        ...prevState,
        Items: updatedItems,
      };
    });
  };

  function editSelectLanguage(ev: any, data: any): void {
    setVoucher((prev: CustomerBidDto) => ({
      ...prev,
      LanguageInternalCode:
        (data.optionValue as LanguageDto)?.InternalCode ?? undefined,
    }));
  }

  const editSelectPaymentMethod = (ev: any, data: any) => {
    setVoucher((prev: CustomerBidDto) => ({
      ...prev,
      PaymentMethodId: (data.optionValue as PaymentMethodDto)?.Id ?? undefined,
    }));
  };

  return (
    <CardFlip isFlipped={isFlipped}>
      <div style={{ paddingBottom: "100px" }}>
        <h3 style={{ margin: 0, marginBottom: 5, textAlign: "right" }}>
          {voucher?.VoucherNumber}
        </h3>
        <div style={{ textAlign: "right" }}>
          <span style={{ marginRight: "5px" }}>Nyelv:</span>
          <span style={{}}>
            <span style={{ marginRight: "5px" }}>{`Magyar /`}</span>
            <SelectComponent
              disabled
              options={languages as any}
              value={
                languages?.find(
                  (x) =>
                    x.InternalCode ===
                    (isSalesPlusPlus ? "HU" : voucher?.LanguageInternalCode)
                )?.Name ?? ""
              }
              action={editSelectLanguage}
              noEmptyOption
            />
          </span>
        </div>
        <Fragment>
          <div
            id={
              isCustomerClicked
                ? "mobileclient-info"
                : "mobileclient-info-hidden"
            }
            style={
              !isCustomerClicked && voucher?.CustomerObj
                ? { display: "none" }
                : {
                    position: "absolute",
                    textAlign: "center",
                    width: "calc(100% - 70px)",
                    marginTop: "10px",
                  }
            }
          >
            <div
              onClick={() => {
                if (isDisabled) {
                } else if (isCustomerClicked && voucher?.CustomerObj) {
                  openCustomerModal();
                  setIsCustomerClicked(false);
                } else if (setActiveTab) {
                  setActiveTab(TAB_TYPE.CUSTOMERS);
                }
              }}
            >
              <div
                className="btn-circle-icon-mobile"
                style={{
                  display: "inline-flex",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="14px"
                  fill={COLOR.BIZ_BLACK}
                  viewBox="0 0 448 380"
                >
                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                </svg>
              </div>
              <div style={{ fontFamily: "Oxygen", fontSize: "14px" }}>
                {!isCustomerClicked && !voucher?.CustomerObj
                  ? "Válassz partnert"
                  : "Partner szerkesztése"}
              </div>
            </div>
          </div>

          <div
            onClick={(e: any) => {
              e.stopPropagation();
              if (!isDisabled && !isCustomerClicked) setIsCustomerClicked(true);
            }}
            style={{
              backgroundColor: "rgba(221, 232, 241, 0.75)",
              borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
              display: "flex",
              opacity: isCustomerClicked ? 0.2 : 1,
              transition: "opacity 0.2s ease-in-out",
            }}
          >
            <div
              style={{
                width: "calc(100% - 80px)",
                minHeight: "60px",
                display: "inline-block",
                padding: "5px",
                fontFamily: "'Oxygen', sans-serif",
                paddingBottom: "5px",
                borderRight: "1px solid rgba(149, 183, 211, 0.75)",
              }}
            >
              <div
                style={{
                  fontSize: "22px",
                  wordBreak: "break-all",
                }}
              >
                {voucher?.CustomerObj?.Name}
              </div>
              <div
                style={{
                  fontFamily: "'Oxygen', sans-serif",
                  wordBreak: "break-all",
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                {getCustomerAddress(voucher?.CustomerObj)}
              </div>
            </div>
            <div
              onClick={(e: any) => {
                e.stopPropagation();
                handleFlip();
              }}
              style={{
                width: "70px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                WebkitJustifyContent: "center",
                WebkitAlignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  width: "25px",
                  height: "25px",
                  border: "2px solid #000000",
                  backgroundColor: "transparent",
                  position: "relative",
                  WebkitBorderRadius: "50%",
                  MozBorderRadius: "50%",
                  borderRadius: "50%",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                </svg>
              </div>
            </div>
          </div>
        </Fragment>
        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <Label
            style={{
              lineHeight: "30px",
              color: "#666666",
              textAlign: "center",
              width: "100%",
            }}
          >
            Projekt
          </Label>
          <span style={{ textAlign: "right", width: "100%" }}>
            <SelectComponent
              disabled={isDisabled}
              transparent
              value={
                (projects &&
                  voucher?.ProjectId &&
                  projects[voucher?.ProjectId]?.Name) ??
                ""
              }
              options={projects && (Object.values(projects) as any)}
              action={changeProject}
            />
          </span>
        </div>

        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <div style={{ width: "50%", textAlign: "center" }}>
            <Label
              style={{
                color: "#666666",
                textAlign: "center",
              }}
            >
              Iktatószám
            </Label>
            <div style={{ textAlign: "center" }}>
              <InputComponent
                disabled={isDisabled}
                transparent
                maxLength={80}
                value={voucher?.FilingNumber ?? ""}
                name="FilingNumber"
                onChange={(e: any) =>
                  setVoucher((prev: CustomerBidDto) => ({
                    ...prev,
                    FilingNumber: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#666666",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Szállítási mód
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SelectComponent
                disabled={isDisabled}
                options={
                  transportModes && (Object.values(transportModes) as any)
                }
                value={
                  (transportModes &&
                    (voucher as CustomerBidDto)?.TransportModeId &&
                    transportModes[(voucher as any)?.TransportModeId]?.Name) ??
                  ""
                }
                action={changeTransportMode}
                transparent
                small
              />
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#666666",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Kelte
            </div>
            <div style={{ textAlign: "center" }}>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                disabled={true}
                isMonthPickerVisible={true}
                strings={DayPickerStrings}
                contentAfter={<></>}
                style={{
                  width: "100px",
                  background: "transparent",
                  border: "none",
                }}
                onSelectDate={(data: any) =>
                  changeDateProp(data, "VoucherDate")
                }
                value={
                  isSalesPlusPlus
                    ? new Date()
                    : voucher?.VoucherDate
                    ? new Date(voucher?.VoucherDate)
                    : new Date()
                }
                allowTextInput
                formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#666666",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Szállítási határidő
            </div>
            <div style={{ textAlign: "center" }}>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                disabled={isSalesPlusPlus || isDisabled}
                isMonthPickerVisible={true}
                strings={DayPickerStrings}
                contentAfter={<></>}
                style={{
                  width: "100px",
                  background: "transparent",
                  border: "none",
                }}
                onSelectDate={(data: any) =>
                  changeDateProp(data, "TransportDate")
                }
                value={
                  (voucher as CustomerBidDto)?.TransportDate
                    ? new Date((voucher as any)?.TransportDate)
                    : null
                }
                allowTextInput
                formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#666666",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Érvényesség
            </div>
            <div style={{ textAlign: "center" }}>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                disabled={isDisabled}
                isMonthPickerVisible={true}
                strings={DayPickerStrings}
                contentAfter={<></>}
                style={{
                  width: "100px",
                  background: "transparent",
                  border: "none",
                }}
                onSelectDate={(data: any) =>
                  changeDateProp(data, "ExpirationDate")
                }
                value={
                  (voucher as CustomerBidDto)?.ExpirationDate
                    ? new Date((voucher as any)?.ExpirationDate)
                    : new Date()
                }
                allowTextInput
                formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ color: "#666666", textAlign: "center" }}>
              Fizetési mód
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SelectComponent
                disabled={isDisabled}
                small
                transparent
                noEmptyOption
                options={
                  paymentMethods && (Object.values(paymentMethods) as any)
                }
                value={
                  paymentMethods && (voucher as CustomerBidDto)?.PaymentMethodId
                    ? paymentMethods[(voucher as any)?.PaymentMethodId]?.Name
                    : ""
                }
                action={editSelectPaymentMethod}
              />
            </div>
          </div>
        </div>

        {voucher?.Items?.map((detail, index) => {
          let currency: CurrencyDto | null | undefined = undefined;
          if (currencies && detail?.Currency)
            currency = currencies[detail.Currency];

          let quantityUnit: QuantityUnitDto | null | undefined = undefined;
          if (quantityUnits && detail?.QuantityUnit)
            quantityUnit = quantityUnits[detail.QuantityUnit];

          let vat: VatDto | null | undefined = undefined;
          if (vats && detail?.Vat) vat = vats[detail.Vat];

          let discount = detail?.DiscountPercent ?? 0;
          let unitPriceMinusDiscount = getCalculatedUnitPrice(
            detail?.DiscountPercent,
            detail.UnitPrice
          );
          let netValue = getNetValue(unitPriceMinusDiscount, detail.Quantity);
          let vatValue = calculatePercentage(netValue, vat?.Rate);

          return (
            <div
              onClick={() => {
                if (!isDisabled) {
                  if (clickedDetail === detail?.Id) {
                    setClickedDetail(undefined);
                  } else {
                    setClickedDetail(detail?.Id);
                  }
                }
              }}
              key={index}
              style={
                clickedDetail === detail?.Id
                  ? {
                      backgroundColor: COLOR.BIZ_DARK_ORANGE,
                      borderBottom: "2px solid white",
                    }
                  : { borderBottom: "2px solid white" }
              }
            >
              <div
                style={
                  !isDisabled && clickedDetail === detail?.Id
                    ? { width: "100%", height: "100%", zIndex: 1001 }
                    : { display: "none" }
                }
              >
                <div
                  style={{
                    width: "100%",
                    height: "70px",
                    position: "absolute",
                    color: "white",
                    margin: "auto",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    WebkitBoxPack: "center",
                    WebkitBoxAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      marginRight: "15px",
                    }}
                    onClick={() => editDetailClick(detail)}
                  >
                    <Edit16Filled
                      style={{
                        cursor: "pointer",
                        display: "block",
                        margin: "auto",
                        backgroundColor: "white",
                        padding: "5px",
                        borderRadius: "50%",
                        color: COLOR.BIZ_DARK_ORANGE,
                      }}
                    />{" "}
                    Tétel szerkesztése
                  </span>
                  <span
                    style={{ fontSize: "12px" }}
                    onClick={() => deleteDetailClick(index)}
                  >
                    <Delete16Filled
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginRight: "10px",
                        display: "block",
                        margin: "auto",
                        backgroundColor: "white",
                        padding: "5px",
                        borderRadius: "50%",
                        color: COLOR.BIZ_DARK_ORANGE,
                      }}
                    />
                    Tétel törlése
                  </span>
                </div>
              </div>
              <div
                style={{
                  opacity: clickedDetail === detail?.Id ? 0.2 : 1,
                  backgroundColor: "rgba(221, 232, 241, 0.75)",
                  border:
                    clickedDetail === detail?.Id
                      ? "4px solid #3f76bb"
                      : "4px solid transparent",
                  pointerEvents:
                    clickedDetail === detail?.Id ? "none" : "-moz-initial",
                  zIndex: clickedDetail === detail?.Id ? 0 : 1,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                  }}
                >
                  <div style={{ width: "60%", overflow: "hidden" }}>
                    {trimLongString(detail?.ProductNameDisplay, 30)}
                  </div>
                  <div style={{ width: "40%" }}>{`${discount} %`}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                  }}
                >
                  <div style={{ width: "50%", marginTop: "5px" }}>
                    {detail?.Id ? (
                      <Fragment>
                        <span style={{ fontWeight: 700 }}>
                          {detail?.Quantity ?? 0}
                        </span>
                        <span style={{ marginLeft: "10px" }}>
                          {quantityUnit?.Name}
                        </span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <span style={{ fontWeight: 700, marginRight: "5px" }}>
                          {detail?.Quantity}
                        </span>
                      </Fragment>
                    )}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      marginTop: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {`${vat?.Rate}% [ ${formatNumberWithQuantityUnit(
                      vatValue,
                      currency,
                      currency?.VatValueDigits
                    )} ]`}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                  }}
                >
                  <div style={{ width: "30%", marginTop: "5px" }}></div>
                  <div
                    style={{
                      width: "70%",
                      marginTop: "5px",
                      fontWeight: 700,
                    }}
                  >
                    {formatNumberWithQuantityUnit(
                      unitPriceMinusDiscount,
                      currency,
                      currency?.UnitPriceDigits
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div style={{}}>
        <Comments
          handleFlip={handleFlip}
          voucher={voucher}
          setVoucher={setVoucher}
        />
      </div>
    </CardFlip>
  );
};

export default CustomerBidFormMobile;
