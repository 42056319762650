import { useState, useContext, Fragment } from "react";
import { MasterDataContext, ReceiptContext } from "../../utils/GlobalContexts";
import SelectComponent from "../select/SelectComponent";
import {
  VoucherState,
  PaymentMethodDto,
  CustomerReceiptDto,
} from "../../KulcsUzletApi";
import { DatePicker, DayOfWeek } from "@fluentui/react-datepicker-compat";
import { DayPickerStrings } from "../../utils/GlobalConsts";
import InputComponent from "../input/InputComponent";
import {
  formatNumberWithQuantityUnit,
  trimLongString,
} from "../../utils/HelperFunctions";
import { COLOR, TAB_TYPE } from "../../utils/Enums";
import { Delete16Filled, Edit16Filled } from "@fluentui/react-icons";
type Props = {
  setActiveTab?: React.Dispatch<React.SetStateAction<TAB_TYPE>>;
  selectDetail?: () => void;
};

const CustomerReceiptFormMobile = ({ setActiveTab, selectDetail }: Props) => {
  const { receipt, setReceipt } = useContext(ReceiptContext);
  const { paymentMethods, currencies, quantityUnits, vats } =
    useContext(MasterDataContext);
  const [clickedDetail, setClickedDetail] = useState(false);
  const isEdit = localStorage.getItem("action") === "edit";
  const isSalesPlusPlus = localStorage.getItem("isSalesPlusPlus") === "1";
  const isDisabled =
    receipt?.State === VoucherState.CANCELLED ||
    receipt?.State === VoucherState.CANCELLER ||
    receipt?.State === VoucherState.RESTORED;

  const deleteDetailClick = async () => {
    await setReceipt((prevState: CustomerReceiptDto | null) => {
      return {
        ...prevState,
        Detail: undefined,
      };
    });
  };

  const editSelectPaymentMethod = (ev: any, data: any) => {
    setReceipt((prev: CustomerReceiptDto) => ({
      ...prev,
      PaymentMethodId: (data.optionValue as PaymentMethodDto)?.Id ?? undefined,
    }));
  };

  return (
    <Fragment>
      <div style={{ paddingBottom: "100px" }}>
        <h3 style={{ margin: 0, marginBottom: 5, textAlign: "right" }}>
          {receipt?.VoucherNumber}
        </h3>{" "}
        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#666666",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Ügyfél név
            </div>
            <div style={{ textAlign: "center" }}>
              <InputComponent
                disabled={isSalesPlusPlus || isDisabled || isEdit}
                transparent
                id="CustomerName"
                value={receipt?.CustomerName ?? ""}
                name={"CustomerName"}
                onChange={(e: any) => {
                  setReceipt((prev: CustomerReceiptDto) => ({
                    ...prev,
                    CustomerName: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#666666",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Ügyfél email
            </div>
            <div style={{ textAlign: "center" }}>
              <InputComponent
                disabled={isSalesPlusPlus || isDisabled || isEdit}
                transparent
                id="CustomerEmail"
                value={receipt?.CustomerEmail ?? ""}
                name={"CustomerEmail"}
                onChange={(e: any) => {
                  setReceipt((prev: CustomerReceiptDto) => ({
                    ...prev,
                    CustomerEmail: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "rgba(221, 232, 241, 0.75)",
            borderBottom: "1px solid rgba(149, 183, 211, 0.75)",
            display: "flex",
            padding: "5px",
          }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                color: "#666666",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Kelte
            </div>
            <div style={{ textAlign: "center" }}>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                disabled={true}
                isMonthPickerVisible={true}
                strings={DayPickerStrings}
                contentAfter={<></>}
                style={{
                  width: "100px",
                  background: "transparent",
                  border: "none",
                }}
                value={
                  isSalesPlusPlus
                    ? new Date()
                    : receipt?.VoucherDate
                    ? new Date(receipt?.VoucherDate)
                    : new Date()
                }
                allowTextInput
                formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
              />
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ color: "#666666", textAlign: "center" }}>
              Fizetési mód
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SelectComponent
                disabled={isSalesPlusPlus || isDisabled || isEdit}
                small
                transparent
                noEmptyOption
                options={
                  paymentMethods
                    ? (Object.values(paymentMethods).filter(
                        (method) => method.ImmediatePay === 1
                      ) as [])
                    : []
                }
                value={
                  paymentMethods && receipt?.PaymentMethodId
                    ? paymentMethods[receipt?.PaymentMethodId]?.Name
                    : ""
                }
                action={editSelectPaymentMethod}
              />
            </div>
          </div>
        </div>
        {receipt?.Detail && (
          <div
            onClick={() => {
              if (!isDisabled && !isEdit) {
                setClickedDetail(!clickedDetail);
              }
            }}
            style={
              clickedDetail
                ? {
                    backgroundColor: COLOR.BIZ_DARK_ORANGE,
                    borderBottom: "2px solid white",
                  }
                : { borderBottom: "2px solid white" }
            }
          >
            <div
              style={
                !isDisabled && clickedDetail
                  ? { width: "100%", height: "100%", zIndex: 1001 }
                  : { display: "none" }
              }
            >
              <div
                style={{
                  width: "100%",
                  height: "70px",
                  position: "absolute",
                  color: "white",
                  margin: "auto",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  WebkitBoxPack: "center",
                  WebkitBoxAlign: "center",
                  marginTop: "5px",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    marginRight: "15px",
                  }}
                  onClick={selectDetail}
                >
                  <Edit16Filled
                    style={{
                      cursor: "pointer",
                      display: "block",
                      margin: "auto",
                      backgroundColor: "white",
                      padding: "5px",
                      borderRadius: "50%",
                      color: COLOR.BIZ_DARK_ORANGE,
                    }}
                  />{" "}
                  Tétel szerkesztése
                </span>
                <span style={{ fontSize: "12px" }} onClick={deleteDetailClick}>
                  <Delete16Filled
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      marginRight: "10px",
                      display: "block",
                      margin: "auto",
                      backgroundColor: "white",
                      padding: "5px",
                      borderRadius: "50%",
                      color: COLOR.BIZ_DARK_ORANGE,
                    }}
                  />
                  Tétel törlése
                </span>
              </div>
            </div>
            <div
              style={{
                opacity: clickedDetail ? 0.2 : 1,
                backgroundColor: "rgba(221, 232, 241, 0.75)",
                border: clickedDetail
                  ? "4px solid #3f76bb"
                  : "4px solid transparent",
                pointerEvents: clickedDetail ? "none" : "-moz-initial",
                zIndex: clickedDetail ? 0 : 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "13px",
                }}
              >
                <div style={{ width: "60%", overflow: "hidden" }}>
                  {trimLongString(receipt?.Detail?.ProductNameDisplay, 30)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "13px",
                }}
              >
                <div style={{ width: "50%", marginTop: "5px" }}>
                  <span style={{ fontWeight: 700 }}>
                    {receipt?.Detail?.Quantity ?? 0}
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    {(quantityUnits &&
                      receipt?.Detail?.QuantityUnitId &&
                      quantityUnits[receipt?.Detail?.QuantityUnitId]?.Name) ??
                      ""}
                  </span>
                </div>
                <div
                  style={{
                    width: "50%",
                    marginTop: "5px",
                    fontSize: "12px",
                  }}
                >
                  {`${
                    vats &&
                    receipt?.Detail?.VatId &&
                    vats[receipt?.Detail?.VatId]?.Rate
                  }%`}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "13px",
                }}
              >
                <div style={{ width: "30%", marginTop: "5px" }}></div>
                <div
                  style={{
                    width: "70%",
                    marginTop: "5px",
                    fontWeight: 700,
                  }}
                >
                  {formatNumberWithQuantityUnit(
                    receipt?.Detail?.GrossValue,
                    currencies && receipt?.CurrencyId
                      ? currencies[receipt?.CurrencyId]
                      : undefined,
                    currencies &&
                      receipt?.CurrencyId &&
                      currencies[receipt?.CurrencyId]?.GrossValueDigits
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CustomerReceiptFormMobile;
