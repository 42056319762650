import { Fragment, useContext, useEffect, useState } from "react";
import {
  VoucherContext,
  DeviceContext,
  MasterDataContext,
} from "../utils/GlobalContexts";
import { useStylesMainPage } from "../styles/MainPageStyles";
import { useParams } from "react-router-dom";
import {
  CustomerBidDto,
  CustomerBidHeadDto,
  CustomerDeliveryBillDetailDto,
  CustomerDeliveryBillDto,
  CustomerDeliveryBillHeadDto,
  CustomerDto,
  CustomerOrderHeadDto,
  CustomerProformaInvoiceDetailDto,
  DocumentPrintService,
  MasterDataService,
  VoucherService,
  VoucherType,
} from "../KulcsUzletApi";
import { TAB_TYPE, LIST_TYPE } from "../utils/Enums";
import { getCompanyName, getMasterData } from "../utils/HelperFunctions";
import axios from "axios";
import _ from "lodash";
import MobileTabs from "../components/mobile-tabs/MobileTabs";
import ListContainer from "../components/lists/ListContainer";
import MobileBottomMenu from "../components/bottom-menu/MobileBottomMenu";
import Header from "../components/header/Header";
import PageHeader from "../components/page-header/PageHeader";
import DialogConfirm from "../components/dialog/DialogConfirm";
import DialogEditCustomer from "../components/dialog/DialogEditCustomer";
import DialogEditDetail from "../components/dialog/DialogEditDetail";
import DialogSetCurrency from "../components/dialog/DialogSetCurrency";
import DialogSendEmail from "../components/dialog/DialogSendEmail";
import CustomerDeliveryBillFormMobile from "../components/forms/CustomerDeliveryBillFormMobile";
import CustomerDeliveryBillForm from "../components/forms/CustomerDeliveryBillForm";
import {
  updateItemUnitPrices,
  getCalculatedUnitPrice,
} from "../utils/VoucherFunctions";
import { getNewCustomerDeliveryBillDto } from "../utils/GlobalConsts";

export default function CustomerDeliveryBillPage() {
  const { isMobile } = useContext(DeviceContext);
  const { voucher, setVoucher } = useContext(VoucherContext);
  const [companyName, setCompanyName] = useState("");
  const {
    setVats,
    setQuantityUnits,
    setProductCategories,
    setProjects,
    setCustomerCategories,
    setCurrencies,
    setPaymentMethods,
    setCountries,
    currencies,
    setTransportModes,
    setLanguages,
    setUserSettings,
  } = useContext(MasterDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [openResultDialog, setOpenResultDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [dialogText, setDialogText] = useState<string | null>(null);
  const [currencyRate, setCurrencyRate] = useState<number | undefined | null>(
    1
  );
  const [unsavedCurrencyRate, setUnsavedCurrencyRate] = useState<
    number | undefined
  >(1);
  let { id } = useParams();
  const [activeTab, setActiveTab] = useState(
    id ? TAB_TYPE.INVOICE : TAB_TYPE.CUSTOMERS
  );
  const [currentListType, setCurrentListType] = useState(
    id ? LIST_TYPE.PRODUCT : LIST_TYPE.CUSTOMER
  );
  const styles = useStylesMainPage();
  const [newCustomerModal, setNewCustomerModal] = useState(false);
  const [openCurrencyRateModal, setOpenCurrencyRaterModal] = useState(false);
  const [selectedDetail, setSelectedDetail] =
    useState<CustomerDeliveryBillDetailDto | null>(null);
  const [shouldUpdateCurrency, setShouldUpdateCurrency] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const isSalesPlusPlus = localStorage.getItem("isSalesPlusPlus") === "1";

  useEffect(() => {
    if (companyName === "") {
      getCompanyName(setCompanyName);
    }
  }, [companyName]);

  useEffect(() => {
    setIsLoading(true);
    getData().then(() => setIsLoading(false));
  }, []);

  const getHeadData = async (id: string, copy: boolean) => {
    try {
      const resHead: CustomerDeliveryBillHeadDto = copy
        ? await VoucherService.getVoucherCustomerdeliverybillCopy(Number(id))
        : await VoucherService.getVoucherCustomerdeliverybill1(Number(id));
      return resHead;
    } catch (error) {
      console.log(error);
    }
  };

  const getDetailData = async (detailId: string) => {
    try {
      let details: CustomerDeliveryBillDetailDto[] =
        await VoucherService.getVoucherCustomerdeliverybilldetails(
          Number(detailId)
        );
      details = details.map((d) => ({
        ...d,
        UnitPrice: d.OriginalPrice,
        DiscountPercent: d.DiscountPercent ?? 0,
      }));
      return details;
    } catch (error) {
      console.log(error);
    }
  };

  const mapPropsFromSourceVoucher = (
    dto: CustomerDeliveryBillDto,
    sourceVoucher: CustomerBidHeadDto | CustomerOrderHeadDto
  ) => {
    return {
      ...dto,
      BottomComment: sourceVoucher.BottomComment,
      TopComment: sourceVoucher.TopComment,
      ProjectId: sourceVoucher.ProjectId,
      CurrencyId: sourceVoucher.CurrencyId,
      CustomerId: sourceVoucher.CustomerId as any,
      CustomerNameDisplay: sourceVoucher.CustomerNameDisplay,
      DiscountPercent: sourceVoucher.DiscountPercent,
      GrandTotalDigits: sourceVoucher.GrandTotalDigits,
      NetValue: sourceVoucher.NetValue,
      NetValueDigits: sourceVoucher.NetValueDigits,
      TotalCount: sourceVoucher.TotalCount,
      GrossValue: sourceVoucher.GrossValue,
      GrossValueDigits: sourceVoucher.GrossValueDigits,
      LanguageInternalCode: sourceVoucher.LanguageInternalCode,
      PayableValue: sourceVoucher.PayableValue,
      PaymentMethodId: sourceVoucher.PaymentMethodId,
      TransportModeId: sourceVoucher.TransportModeId,
      VatValue: sourceVoucher.VatValue,
      VatValueDigits: sourceVoucher.VatValueDigits,
      UnitPriceDigits: sourceVoucher.UnitPriceDigits,
      CurrencyRate: sourceVoucher.CurrencyRate,
    };
  };

  const getSourceData = async (
    relationid: string,
    vouchertype: string,
    newDto: CustomerDeliveryBillDto
  ) => {
    try {
      switch (vouchertype) {
        case "customerbid":
          const sourceVoucherCustomerBid =
            await VoucherService.getVoucherCustomerbidSource(
              Number(relationid),
              VoucherType.CUSTOMER_DELIVERY_BILL
            );
          newDto = mapPropsFromSourceVoucher(newDto, sourceVoucherCustomerBid);
          newDto.RelationId = Number(relationid);
          newDto.RelationVoucherType = VoucherType.CUSTOMER_BID;
          const voucherNumberBid =
            await VoucherService.getVoucherNextvouchernumber(
              VoucherType.CUSTOMER_DELIVERY_BILL,
              sourceVoucherCustomerBid.CurrencyId ?? 245
            ).catch((e) => console.log(e));

          if (voucherNumberBid) newDto.VoucherNumber = voucherNumberBid;

          return newDto;

        case "customerorder":
          const sourceVoucherCustomerOrder =
            await VoucherService.getVoucherCustomerorderSource(
              Number(relationid),
              VoucherType.CUSTOMER_DELIVERY_BILL
            );
          newDto = mapPropsFromSourceVoucher(
            newDto,
            sourceVoucherCustomerOrder
          );
          newDto.RelationId = Number(relationid);
          newDto.RelationVoucherType = VoucherType.CUSTOMER_ORDER;
          const voucherNumberOrder =
            await VoucherService.getVoucherNextvouchernumber(
              VoucherType.CUSTOMER_DELIVERY_BILL,
              sourceVoucherCustomerOrder.CurrencyId ?? 245
            ).catch((e) => console.log(e));

          if (voucherNumberOrder) newDto.VoucherNumber = voucherNumberOrder;

          return newDto;

        case "customerproformainvoice":
          const sourceVoucherCustomerProformaInvoice =
            await VoucherService.getVoucherCustomerproformainvoiceSource(
              Number(relationid),
              VoucherType.CUSTOMER_DELIVERY_BILL
            );
          newDto = mapPropsFromSourceVoucher(
            newDto,
            sourceVoucherCustomerProformaInvoice
          );
          newDto.RelationId = Number(relationid);
          newDto.RelationVoucherType = VoucherType.CUSTOMER_PROFORMA_INVOICE;
          const voucherNumberProformaInvoice =
            await VoucherService.getVoucherNextvouchernumber(
              VoucherType.CUSTOMER_DELIVERY_BILL,
              sourceVoucherCustomerProformaInvoice.CurrencyId ?? 245
            ).catch((e) => console.log(e));

          if (voucherNumberProformaInvoice)
            newDto.VoucherNumber = voucherNumberProformaInvoice;

          return newDto;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mapSourceVoucherDetails = (
    voucherType: VoucherType,
    details: CustomerDeliveryBillDetailDto[]
  ) => {
    return details?.map((d) => ({
      ...d,
      UnitPrice: d.OriginalPrice,
      DiscountPercent: d.DiscountPercent ?? 0,
      Id: Math.floor(Math.random() * 10000000),
      CustomerDeliveryBillId: undefined,
      RelationId: d.Id,
      RelationVoucherType: voucherType,
    }));
  };

  const getSourceDetailData = async (detailId: string, vouchertype: string) => {
    let details: CustomerDeliveryBillDetailDto[] = [];
    try {
      switch (vouchertype) {
        case "customerbid":
          details = (await VoucherService.getVoucherCustomerbiddetailsSource(
            Number(detailId)
          )) as CustomerDeliveryBillDetailDto[];
          details = mapSourceVoucherDetails(VoucherType.CUSTOMER_BID, details);
          break;
        case "customerorder":
          details = (await VoucherService.getVoucherCustomerorderdetailsSource(
            Number(detailId)
          )) as CustomerDeliveryBillDetailDto[];
          details = mapSourceVoucherDetails(
            VoucherType.CUSTOMER_ORDER,
            details
          );
          break;
        case "customerproformainvoice":
          details =
            (await VoucherService.getVoucherCustomerproformainvoicedetailsSource(
              Number(detailId)
            )) as CustomerProformaInvoiceDetailDto[];
          details = mapSourceVoucherDetails(
            VoucherType.CUSTOMER_PROFORMA_INVOICE,
            details
          );
          break;
        default:
          break;
      }

      return details;
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerObj = async (customerid: number) => {
    try {
      const customer: CustomerDto =
        await MasterDataService.getMasterdataGetcustomerbyid(customerid);
      return customer;
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    const action = localStorage.getItem("action");
    const relationId = localStorage.getItem("relationId");
    const relationVoucherType = localStorage.getItem("relationVoucherType");

    let voucherId = undefined;
    if (action === "edit" || action === "copy") {
      setCurrentListType(LIST_TYPE.PRODUCT);
      setActiveTab(TAB_TYPE.INVOICE);
      voucherId = voucherId ?? localStorage.getItem("id");
    } else localStorage.removeItem("id");

    await getMasterData(
      setQuantityUnits,
      setVats,
      setProductCategories,
      setProjects,
      setTransportModes,
      setCustomerCategories,
      setCurrencies,
      setPaymentMethods,
      setCountries,
      setLanguages,
      setUserSettings
    );

    if (voucherId || relationId) {
      let head:
        | CustomerDeliveryBillDto
        | CustomerDeliveryBillHeadDto
        | undefined = undefined;
      let details: CustomerDeliveryBillDetailDto[] | undefined = undefined;
      if (relationId && relationVoucherType) {
        const newDto = await getNewCustomerDeliveryBillDto(
          true,
          isSalesPlusPlus
        );
        head = await getSourceData(relationId, relationVoucherType, newDto);
        details =
          (await getSourceDetailData(relationId, relationVoucherType)) ?? [];
      } else if (voucherId) {
        head = await getHeadData(voucherId, action === "copy");
        details = (await getDetailData(voucherId)) ?? [];
      }

      let customerobj: CustomerDto | undefined = undefined;
      if (head?.CustomerId)
        customerobj = await getCustomerObj(head?.CustomerId);
      let dto = head as CustomerDeliveryBillDto;
      dto.Items = details as CustomerDeliveryBillDetailDto[];
      dto.CustomerObj = customerobj;

      if (action === "copy") {
        dto.Id = undefined;
        dto.RelationId = null;
        dto.RelationVoucherType = undefined;
        const voucherNumber = await VoucherService.getVoucherNextvouchernumber(
          VoucherType.CUSTOMER_DELIVERY_BILL,
          dto.CurrencyId ?? 245
        ).catch((e) => console.log(e));
        if (voucherNumber) dto.VoucherNumber = voucherNumber;

        dto.Items = dto?.Items?.map((x) => ({
          ...x,
          Id: Math.floor(Math.random() * 10000000),
          CustomerDeliveryBillId: undefined,
        }));
      }
      setVoucher(dto);
      setCurrencyRate(dto.CurrencyRate);
    } else {
      setCurrencyRate(1);

      const newDto = await getNewCustomerDeliveryBillDto(
        false,
        isSalesPlusPlus
      );
      setVoucher(newDto);
    }
  };

  useEffect(() => {
    if (voucher?.CurrencyId && shouldUpdateCurrency) {
      updateCurrency().then(async () => {
        if (voucher?.CurrencyId) {
          const vouchernumber =
            await VoucherService.getVoucherNextvouchernumber(
              VoucherType.CUSTOMER_DELIVERY_BILL,
              voucher.CurrencyId
            );
          if (vouchernumber !== voucher.VoucherNumber) {
            setVoucher((prev: CustomerDeliveryBillDto) => ({
              ...prev,
              VoucherNumber: vouchernumber,
            }));
          }
        }
      });
      setShouldUpdateCurrency(false);
    }
  }, [shouldUpdateCurrency]);

  useEffect(() => {
    if (currencyRate && voucher?.CurrencyId) {
      if (voucher?.Id && firstRender) {
        setFirstRender(false);
        return;
      } else if (voucher?.Items?.length && voucher?.Items?.length > 0) {
        updateItemUnitPrices(voucher, setVoucher, currencyRate);
      } else {
        setVoucher((prev: CustomerDeliveryBillDto) => ({
          ...prev,
          CurrencyRate: currencyRate,
        }));
      }
    }
  }, [currencyRate]);

  const updateCurrency = async () => {
    const currencyname =
      currencies &&
      voucher?.CurrencyId &&
      currencies[voucher?.CurrencyId]?.Name;

    if (currencyname && currencyname !== "HUF") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/currencyRates/${currencyname}`
        );
        if (response.data) openSetCurrencyRateModal(response.data);
        else openSetCurrencyRateModal(1);
      } catch (e) {
        openSetCurrencyRateModal(1);
      }
    } else {
      setCurrencyRate(1);
    }
  };

  const openCustomerModal = () => {
    setNewCustomerModal(false);
    setOpenCustomerDialog(true);
  };

  const openResultModal = (text?: string) => {
    if (text) setDialogText(text);
    setOpenResultDialog(true);
  };

  const openNewCustomerModal = (text?: string) => {
    if (text) setDialogText(text);
    setNewCustomerModal(true);
    setOpenCustomerDialog(true);
  };

  const openSetCurrencyRateModal = (currencyValue?: number) => {
    if (currencyValue) setUnsavedCurrencyRate(currencyValue);
    setOpenCurrencyRaterModal(true);
  };

  const selectDetail = (detail: CustomerDeliveryBillDetailDto) => {
    setSelectedDetail(detail);
    setOpenDetailDialog(true);
  };

  const handleSendEmailClick = () => {
    if (isSalesPlusPlus) {
      handleSave(true);
    } else setOpenSendEmailDialog(true);
  };

  const handleSave = async (isSendEmail?: boolean) => {
    setIsSaveLoading(true);
    let originalDto = _.cloneDeep(voucher) as CustomerDeliveryBillDto;

    originalDto.DiscountPercent = originalDto.DiscountPercent ?? 0;
    if (isSendEmail && isSalesPlusPlus) originalDto.RemotePrint = true;

    if (originalDto.Items && originalDto.Items?.length > 0) {
      originalDto.Items = originalDto.Items?.map(
        (detail: CustomerDeliveryBillDetailDto) => ({
          ...detail,
          UnitPrice: getCalculatedUnitPrice(
            detail?.DiscountPercent,
            detail.UnitPrice
          ),
          OriginalPrice: detail?.UnitPrice,
          DiscountPercent: detail?.DiscountPercent,
          Id: detail.CustomerDeliveryBillId ? detail.Id : null,
          CustomerDeliveryBillId: originalDto.Id
            ? detail.CustomerDeliveryBillId
            : undefined,
        })
      );
    }

    if (originalDto.VoucherDate) {
      var voucherdate = new Date(originalDto.VoucherDate);
      originalDto.VoucherDate = new Date(
        voucherdate.getTime() - voucherdate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }
    if (originalDto.FulfillmentDate) {
      var fulfillmentDate = new Date(originalDto.FulfillmentDate);
      originalDto.FulfillmentDate = new Date(
        fulfillmentDate.getTime() - fulfillmentDate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }

    let result: number | undefined | null = undefined;
    await VoucherService.postVoucherCustomerdeliverybill(originalDto)
      .then((res) => {
        if (res.IsSuccess === false) {
          openResultModal(`A mentés nem sikerült: ${res.ErrorMsg}`);
          setIsSaveLoading(false);
          result = undefined;
        } else {
          setIsSaveLoading(false);
          result = res.ResultValue?.Id;
          openResultModal("A mentés sikeres volt.");
          const voucherType = localStorage.getItem("voucherType");
          window.location.replace(
            `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/${voucherType}s`
          );
        }
      })
      .catch((e) => {
        openResultModal(`A mentés nem sikerült: ${e?.message}`);
        setIsSaveLoading(false);
        result = undefined;
      });
    return result;
  };

  const handleOpenPreview = async () => {
    setIsPreviewLoading(true);
    let originalDto = _.cloneDeep(voucher) as CustomerDeliveryBillDto;
    if (originalDto.VoucherDate) {
      const voucherdate = new Date(originalDto.VoucherDate);
      originalDto.VoucherDate = new Date(
        voucherdate.getTime() - voucherdate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }
    if (originalDto.FulfillmentDate) {
      const fulfillmentDate = new Date(originalDto.FulfillmentDate);
      originalDto.FulfillmentDate = new Date(
        fulfillmentDate.getTime() - fulfillmentDate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }

    originalDto.Items = originalDto.Items?.map(
      (detail: CustomerDeliveryBillDetailDto) => ({
        ...detail,
        UnitPrice: getCalculatedUnitPrice(
          detail?.DiscountPercent,
          detail.UnitPrice
        ),
      })
    );

    await DocumentPrintService.postDocumentprintPreviewCustomerdeliverybill(
      originalDto
    )
      .then((res) => {
        if (res.IsSuccess === false) {
          openResultModal(
            `Az előnézet betöltése nem sikerült: ${res.ErrorMsg}`
          );
          setIsPreviewLoading(false);
        } else if (res.PreviewPages && res.PreviewPages.length > 0) {
          let contents: string[] = [];
          res.PreviewPages.forEach((p, index) =>
            contents.push(
              `<img src="data:image/png;base64,${p}" alt="${index}"/>`
            )
          );
          var tab = window.open(
            `${originalDto.VoucherNumber}_customerDeliveryBill`,
            "_blank"
          );
          var html = `<html>
          <title>Előnézet</title>
          <body>
            ${contents.join()}
          </body>
          </html>`;
          tab?.document.write(html);
          tab?.document.close();
          setIsPreviewLoading(false);
        }
      })
      .catch((e) => {
        openResultModal(`Az előnézet betöltése nem sikerült: ${e?.message}`);
        console.log(e);
        setIsPreviewLoading(false);
      });
  };

  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          <div className={styles.containerMobile}>
            <div className={styles.invoiceContainerMobile}>
              <MobileTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                needPartnerTab
              />
              {activeTab === TAB_TYPE.CUSTOMERS && (
                <ListContainer
                  setShouldUpdateCurrency={setShouldUpdateCurrency}
                  type={LIST_TYPE.CUSTOMER}
                  setActiveTab={setActiveTab}
                  openNewCustomerModal={openNewCustomerModal}
                />
              )}
              {activeTab === TAB_TYPE.PRODUCTS && (
                <ListContainer type={LIST_TYPE.PRODUCT} />
              )}
              {activeTab === TAB_TYPE.INVOICE && (
                <CustomerDeliveryBillFormMobile
                  setActiveTab={setActiveTab}
                  openCustomerModal={openCustomerModal}
                  selectDetail={selectDetail}
                />
              )}
              <MobileBottomMenu
                handleOpenPreview={handleOpenPreview}
                handleSave={handleSave}
                handleSendEmailClick={handleSendEmailClick}
                openResultModal={openResultModal}
                activeTab={activeTab}
                voucher={voucher}
                setVoucher={setVoucher}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Header companyName={companyName} />
          <div
            className={styles.container}
            style={{ justifyContent: "center" }}
          >
            <PageHeader
              title=""
              content={
                <div style={{ fontSize: 25 }}>
                  {voucher?.VoucherName ?? "Szállítólevél"}
                </div>
              }
            />
            <div className={styles.innerContainer} style={{ marginBottom: 0 }}>
              <div className={styles.containerLeft}>
                <ListContainer
                  setShouldUpdateCurrency={setShouldUpdateCurrency}
                  type={currentListType}
                  setType={setCurrentListType}
                  openNewCustomerModal={openNewCustomerModal}
                />
              </div>
              <div className={styles.containerRight}>
                <CustomerDeliveryBillForm
                  isPreviewLoading={isPreviewLoading}
                  handleOpenPreview={handleOpenPreview}
                  isLoading={isLoading}
                  isSaveLoading={isSaveLoading}
                  handleSave={handleSave}
                  handleSendEmailClick={handleSendEmailClick}
                  copy={localStorage.getItem("action") === "copy"}
                  currencyRate={currencyRate}
                  openCustomerModal={openCustomerModal}
                  openResultModal={openResultModal}
                  updateCurrencyRate={updateCurrency}
                  selectDetail={selectDetail}
                  setShouldUpdateCurrency={setShouldUpdateCurrency}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <DialogConfirm
        message={dialogText ?? ""}
        open={openResultDialog}
        setOpen={setOpenResultDialog}
      />
      <DialogEditCustomer
        setShouldUpdateCurrency={setShouldUpdateCurrency}
        title={newCustomerModal ? "Új partner" : "Partner szerkesztése"}
        open={openCustomerDialog}
        setOpen={setOpenCustomerDialog}
        customerObj={
          newCustomerModal ? ({} as CustomerDto) : voucher?.CustomerObj
        }
      />
      <DialogEditDetail
        open={openDetailDialog}
        setOpen={setOpenDetailDialog}
        detail={selectedDetail}
        voucher={voucher}
        setVoucher={setVoucher}
      />
      <DialogSetCurrency
        currencyRate={unsavedCurrencyRate}
        setCurrencyRate={setCurrencyRate}
        open={openCurrencyRateModal}
        setOpen={setOpenCurrencyRaterModal}
      />
      <DialogSendEmail
        openResultModal={openResultModal}
        handleSaveVoucher={handleSave}
        open={openSendEmailDialog}
        setOpen={setOpenSendEmailDialog}
        voucher={voucher}
        voucherType={2}
      />
    </Fragment>
  );
}
