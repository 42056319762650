import { TableBody, TableCell, TableRow } from "@fluentui/react-components";
import { ModifierDetailTableProps } from "../../interfaces/ModifierDetailTableProps";
import { useStylesVoucherDetail } from "../../styles/VoucherDetailStyles";
import { columns } from "../../utils/GlobalConsts";
import { Fragment, useContext, useEffect, useState } from "react";
import { COLOR } from "../../utils/Enums";
import {
  calculatePercentage,
  formatNumberWithQuantityUnit,
  trimLongString,
} from "../../utils/HelperFunctions";
import { ModifierInvoiceDetailDto } from "../../KulcsUzletApi";
import InputComponent from "../input/InputComponent";
import { ModifierContext } from "../../utils/GlobalContexts";
import { getNetValue } from "../../utils/ModifierFunctions";
import { getCalculatedUnitPrice } from "../../utils/VoucherFunctions";

const ModifierDetailTable: React.FC<ModifierDetailTableProps> = ({
  originalItemIds,
}) => {
  const { modifier, setModifier } = useContext(ModifierContext);
  const styles = useStylesVoucherDetail();
  const [highlightedRows, setHighlightedRows] = useState<number[]>([]);
  const [prevDetails, setPrevDetails] = useState<
    ModifierInvoiceDetailDto[] | undefined
  >([]);

  useEffect(() => {
    setModifier((prevState: any) => {
      if (!prevState) return null;
      const updatedItems = [...(prevState.DetailList ?? [])];
      return {
        ...prevState,
        DetailList: updatedItems,
      };
    });
  }, []);

  const updateItemQuantity = (
    index: number,
    newValue: any,
    input?: boolean
  ) => {
    setModifier((prevState: any) => {
      if (!prevState) return null;
      const updatedItems = [...(prevState.DetailList ?? [])];
      const updatedQuantity = input
        ? newValue
        : (updatedItems[index]?.Quantity || 0) + newValue;

      updatedItems[index] = {
        ...updatedItems[index],
        Quantity: updatedQuantity === "" ? 0 : updatedQuantity,
      };

      return {
        ...prevState,
        DetailList: updatedItems,
      };
    });
  };

  const removeNullQuantity = (index: number) => {
    setModifier((prevState: any) => {
      if (!prevState) return null;
      const updatedItems = [...(prevState.DetailList ?? [])];
      let item = updatedItems[index];

      if (
        item.Quantity === undefined ||
        item.Quantity === null ||
        item?.Quantity <= 0
      )
        updatedItems.splice(index, 1);

      return {
        ...prevState,
        DetailList: updatedItems,
      };
    });
  };

  const highlight = (id: number | null | undefined) => {
    if (!id) return;
    setHighlightedRows((prev) => [...prev, id]);
    setTimeout(() => {
      setHighlightedRows((prev) => prev.filter((item) => item !== id));
    }, 1000);
  };

  useEffect(() => {
    setPrevDetails(modifier?.DetailList ?? []);
  }, [modifier?.DetailList]);

  const detailChangedLogic = (
    newDetail: ModifierInvoiceDetailDto,
    oldDetail: ModifierInvoiceDetailDto | undefined
  ) => {
    return oldDetail !== undefined && newDetail.Quantity !== oldDetail.Quantity;
  };

  useEffect(() => {
    modifier?.DetailList?.forEach((newdetail) => {
      if (!prevDetails) return;
      const olddetail = prevDetails.find((x) => x.Id === newdetail.Id);

      if (detailChangedLogic(newdetail, olddetail)) {
        highlight(newdetail.Id);
      }
    });
    const tableBody = document.querySelector("#table-body");
    if (tableBody) {
      const lastRow = tableBody.querySelector("tr:last-child");
      if (lastRow) {
        lastRow.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, [modifier?.DetailList, prevDetails]);

  return (
    <Fragment>
      <div
        style={{
          overflow: "auto",
          height: "380px",
          overflowX: "auto",
          borderBottom: `1px solid ${COLOR.BIZ_PURPLE}`,
        }}
      >
        <table
          cellSpacing="0"
          cellPadding="0"
          style={{
            tableLayout: "auto",
            width: "100%",
            padding: 0,
            borderCollapse: "collapse",
          }}
        >
          <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <tr
              style={{
                backgroundColor: COLOR.BIZ_PURPLE,
                color: "white",
                fontWeight: 400,
              }}
            >
              {columns.map((column, index) => (
                <th
                  style={{
                    fontWeight: 400,
                    padding: "5px",
                    fontSize: "12.5px",
                  }}
                  key={index}
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>

          <TableBody id="table-body">
            {modifier?.DetailList?.map((detail, index) => {
              let unitPriceMinusDiscount = getCalculatedUnitPrice(
                detail?.DiscountPercent,
                detail?.OriginalUnitPrice ?? detail.UnitPrice
              );

              let netValue = getNetValue(detail, modifier);

              let grossValue = netValue * (1 + (detail?.VatPercent ?? 0) / 100);
              let vatValue = calculatePercentage(netValue, detail?.VatPercent);
              return (
                <TableRow
                  id={`index-${index}`}
                  className={
                    detail.Id && highlightedRows.includes(detail.Id)
                      ? styles.highlightedRow
                      : "normalRow"
                  }
                  key={index}
                >
                  {/* MENNYISÉG */}
                  <TableCell style={{ width: "50px" }} className={styles.cell}>
                    {detail?.Id && !originalItemIds?.includes(detail?.Id) ? (
                      <Fragment>
                        <InputComponent
                          small
                          numeric
                          name="Quantity"
                          value={detail?.Quantity ?? 0}
                          onChange={(e: any, data: any) =>
                            updateItemQuantity(index, data.value, true)
                          }
                          onFocusLeave={() => removeNullQuantity(index)}
                        />
                        <span style={{ marginLeft: "3px" }}>
                          {detail.QuantityName}
                        </span>
                      </Fragment>
                    ) : (
                      <span
                        style={{ float: "right" }}
                      >{`${detail?.Quantity} ${detail.QuantityName}`}</span>
                    )}
                  </TableCell>

                  {/* TERMÉK */}
                  <TableCell
                    className={styles.cell}
                    style={{
                      fontWeight: 700,
                      width: "15%",
                      overflow: "hidden",
                    }}
                  >
                    {trimLongString(detail.ProductNameDisplay, 30)}
                  </TableCell>

                  {/* EGYSÉGÁR */}
                  <TableCell className={styles.cell}>
                    {formatNumberWithQuantityUnit(
                      unitPriceMinusDiscount ?? 0,
                      modifier.Currency,
                      modifier.Currency?.UnitPriceDigits
                    )}
                  </TableCell>

                  {/* ÁFA % */}
                  <TableCell style={{ width: "50px" }} className={styles.cell}>
                    {`${detail?.VatPercent} %`}
                  </TableCell>

                  {/* KEDVEZMÉNY */}
                  <TableCell style={{ width: "50px" }} className={styles.cell}>
                    {`${detail?.DiscountPercent ?? 0} %`}
                  </TableCell>

                  {/* NETTÓ */}
                  <TableCell className={styles.cell}>
                    {formatNumberWithQuantityUnit(
                      netValue,
                      modifier.Currency,
                      modifier.Currency?.NetValueDigits
                    )}
                  </TableCell>

                  {/* ÁFA */}
                  <TableCell className={styles.cell}>
                    {formatNumberWithQuantityUnit(
                      vatValue,
                      modifier.Currency,
                      modifier.Currency?.VatValueDigits
                    )}
                  </TableCell>

                  {/* BRUTTÓ */}
                  <TableCell className={styles.cell}>
                    {formatNumberWithQuantityUnit(
                      grossValue,
                      modifier.Currency,
                      modifier.Currency?.GrossValueDigits
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </table>
      </div>
    </Fragment>
  );
};

export default ModifierDetailTable;
