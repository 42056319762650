import { TableBody, TableCell, TableRow } from "@fluentui/react-components";
import { useStylesVoucherDetail } from "../../styles/VoucherDetailStyles";
import { columnsReceipt } from "../../utils/GlobalConsts";
import { Fragment, useContext } from "react";
import { COLOR } from "../../utils/Enums";
import {
  formatNumberWithQuantityUnit,
  trimLongString,
} from "../../utils/HelperFunctions";
import { CustomerReceiptDto, VoucherState } from "../../KulcsUzletApi";
import { MasterDataContext, ReceiptContext } from "../../utils/GlobalContexts";
import { Delete16Filled, Edit16Filled } from "@fluentui/react-icons";
import { ClipLoader } from "react-spinners";
import { CustomerReceiptDetailTableProps } from "../../interfaces/CustomerReceiptDetailTableProps";

const CustomerReceiptDetailTable: React.FC<CustomerReceiptDetailTableProps> = ({
  voucher,
  setVoucher,
  selectDetail,
  isLoading,
}) => {
  const styles = useStylesVoucherDetail();
  const { receipt } = useContext(ReceiptContext);
  const isDisabled =
    voucher?.State === VoucherState.CANCELLED ||
    voucher?.State === VoucherState.CANCELLER ||
    voucher?.State === VoucherState.RESTORED;
  const { currencies, vats, quantityUnits } = useContext(MasterDataContext);

  const deleteDetailClick = () => {
    setVoucher((prevState: CustomerReceiptDto | null) => {
      if (!prevState) return null;
      return {
        ...prevState,
        Detail: undefined,
      };
    });
  };

  return (
    <Fragment>
      <div
        style={{
          overflow: "auto",
          height: "380px",
          overflowX: "auto",
          borderBottom: `1px solid ${COLOR.BIZ_PURPLE}`,
        }}
      >
        {isLoading ? (
          <div
            style={{
              textAlign: "center",
              margin: "20px",
            }}
          >
            <ClipLoader />
          </div>
        ) : (
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{
              tableLayout: "auto",
              width: "100%",
              padding: 0,
              borderCollapse: "collapse",
            }}
          >
            <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <tr
                style={{
                  backgroundColor: COLOR.BIZ_PURPLE,
                  color: "white",
                  fontWeight: 400,
                }}
              >
                {columnsReceipt.map((column, index) => (
                  <th
                    style={{
                      fontWeight: 400,
                      padding: "5px",
                      fontSize: "12.5px",
                      textAlign:
                        index === columnsReceipt.length - 1 ? "right" : "left",
                      paddingRight:
                        index === columnsReceipt.length - 1 ? "50px" : 0,
                    }}
                    key={index}
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>

            <TableBody id="table-body">
              {receipt?.Detail && (
                <TableRow className={styles.normalRow}>
                  {/* MENNYISÉG */}
                  <TableCell style={{ width: "50px" }} className={styles.cell}>
                    <div style={{ float: "right" }}>
                      {!isDisabled &&
                        localStorage.getItem("action") !== "edit" && (
                          <span className="icon-container">
                            <Edit16Filled
                              style={{
                                cursor: "pointer",
                                color: COLOR.BIZ_DARK_ORANGE,
                              }}
                              onClick={selectDetail}
                            />{" "}
                            <Delete16Filled
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                                marginRight: "10px",
                                color: COLOR.BIZ_DARK_ORANGE,
                              }}
                              onClick={deleteDetailClick}
                            />
                          </span>
                        )}
                    </div>
                  </TableCell>
                  <TableCell style={{ width: "150px" }} className={styles.cell}>
                    <span>{`${receipt?.Detail?.Quantity} ${
                      quantityUnits &&
                      receipt?.Detail?.QuantityUnitId &&
                      quantityUnits[receipt?.Detail?.QuantityUnitId]?.Name
                    }`}</span>
                  </TableCell>
                  {/* TERMÉK */}
                  <TableCell
                    className={styles.cell}
                    style={{
                      fontWeight: 700,
                      width: "15%",
                      overflow: "hidden",
                    }}
                  >
                    {trimLongString(receipt?.Detail?.ProductNameDisplay, 30)}
                  </TableCell>

                  {/* ÁFA % */}
                  <TableCell style={{ width: "50px" }} className={styles.cell}>
                    {`${
                      vats &&
                      receipt?.Detail?.VatId &&
                      vats[receipt?.Detail?.VatId]?.Rate
                    } %`}
                  </TableCell>

                  {/* BRUTTÓ */}
                  <TableCell
                    className={styles.cell}
                    style={{ textAlign: "right", paddingRight: "50px" }}
                  >
                    {formatNumberWithQuantityUnit(
                      receipt?.Detail?.GrossValue,
                      currencies && receipt?.CurrencyId
                        ? currencies[receipt?.CurrencyId]
                        : undefined,
                      currencies &&
                        receipt?.CurrencyId &&
                        currencies[receipt?.CurrencyId]?.GrossValueDigits
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </table>
        )}
      </div>
    </Fragment>
  );
};

export default CustomerReceiptDetailTable;
