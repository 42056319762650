import { Fragment, useContext, useState } from "react";
import { MobileBottomMenuProps } from "../../interfaces/MobileBottomMenuProps";
import { MasterDataContext, ModifierContext } from "../../utils/GlobalContexts";
import _ from "lodash";
import {
  CustomerBidDto,
  CustomerDeliveryBillDto,
  CustomerOrderDto,
  CustomerProformaInvoiceDto,
  ModifierInvoiceDetailDto,
  ModifierInvoiceService,
  QuoteDto,
  VoucherState,
} from "../../KulcsUzletApi";
import { TAB_TYPE } from "../../utils/Enums";
import {
  getModifierTotal,
  getModifierVatValueSum,
  getModifierNetValueSum,
} from "../../utils/ModifierFunctions";
import {
  getCalculatedUnitPrice,
  getVoucherNetValueSum,
  getVoucherTotal,
  getVoucherVatValueSum,
} from "../../utils/VoucherFunctions";
import { formatNumberWithQuantityUnit } from "../../utils/HelperFunctions";
import InputComponent from "../input/InputComponent";

const MobileBottomMenu: React.FC<MobileBottomMenuProps> = ({
  openResultModal,
  activeTab,
  handleSave,
  handleOpenPreview,
  handleSendEmailClick,
  voucher,
  setVoucher,
}) => {
  const { modifier } = useContext(ModifierContext);
  const [open, setOpen] = useState(false);
  const { vats, paymentMethods, currencies } = useContext(MasterDataContext);
  const [voucherDiscount, setVoucherDiscount] = useState(0);
  const isDisabled =
    (voucher as any)?.Status === 5 ||
    (voucher as any)?.State === VoucherState.CANCELLED ||
    (voucher as any)?.State === VoucherState.CANCELLER ||
    (voucher as any)?.State === VoucherState.RESTORED;

  const isSalesPlusPlus = localStorage.getItem("isSalesPlusPlus") === "1";
  const voucherType = localStorage.getItem("voucherType")?.toLowerCase();
  const handleCancel = () => {
    const kulcsUzletInvoicesUrl =
      voucherType &&
      voucherType !== "modifier" &&
      window.location.pathname.includes(voucherType)
        ? `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/${voucherType}s`
        : `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/invoices`;
    window.location.replace(kulcsUzletInvoicesUrl);
  };

  const handleSaveModifier = async (send: boolean) => {
    let originalModifierInvoiceDto = _.cloneDeep(modifier);
    originalModifierInvoiceDto.DetailList =
      originalModifierInvoiceDto.DetailList?.map(
        (detail: ModifierInvoiceDetailDto) => {
          let unitPriceMinusDiscount = getCalculatedUnitPrice(
            detail?.DiscountPercent,
            detail?.OriginalUnitPrice ?? detail?.UnitPrice
          );

          return {
            ...detail,
            Id: detail.IsNewItem ? null : detail.Id,
            UnitPrice: unitPriceMinusDiscount,
          };
        }
      );
    if (originalModifierInvoiceDto.PaymentDate) {
      var payment = originalModifierInvoiceDto.PaymentDate;
      originalModifierInvoiceDto.PaymentDate = new Date(
        payment.getTime() - payment.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }
    originalModifierInvoiceDto.SendModifierAfterCreate = send;

    await ModifierInvoiceService.postModifierModify(originalModifierInvoiceDto)
      .then((res) => {
        if (res.IsSuccess === false) {
          openResultModal(`A mentés nem sikerült: ${res.ErrorMsg}`);
        } else {
          openResultModal("A mentés sikeres volt.");
          window.location.replace(
            `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/invoices`
          );
        }
      })
      .catch((e) => {
        openResultModal(`A mentés nem sikerült: ${e?.message}`);
        console.log(e);
      });
  };

  const handleSendVoucher = async () => {
    if (!(voucher as any)?.CustomerObj?.Id && voucher?.Items?.length === 0) {
      openResultModal("Legalább 1 termék és 1 ügyfél kiválasztása kötelező!");
      return;
    }
    handleSendEmailClick && handleSendEmailClick();
  };

  const handleOpenPreviewModifier = async () => {
    let originalModifierInvoiceDto = _.cloneDeep(modifier);
    originalModifierInvoiceDto.DetailList =
      originalModifierInvoiceDto.DetailList?.map(
        (detail: ModifierInvoiceDetailDto) => ({
          ...detail,
          Id: detail.IsNewItem ? null : detail.Id,
        })
      );

    await ModifierInvoiceService.postModifierPreview(originalModifierInvoiceDto)
      .then((res) => {
        if (res.IsSuccess === false) {
          openResultModal(
            `Az előnézet betöltése nem sikerült: ${res.ErrorMsg}`
          );
        } else if (res.PreviewPages && res.PreviewPages.length > 0) {
          let contents: string[] = [];
          res.PreviewPages.forEach((p, index) =>
            contents.push(
              `<img src="data:image/png;base64,${p}" alt="${index}"/>`
            )
          );
          var tab = window.open(
            `${originalModifierInvoiceDto.InvoiceNumber}_modifier`,
            "_blank"
          );
          var html = `<html>
          <body>
            ${contents.join()}
          </body>
          </html>`;
          tab?.document.write(html);
          tab?.document.close();
        }
      })
      .catch((e) => {
        openResultModal(`Az előnézet betöltése nem sikerült: ${e?.message}`);
        console.log(e);
      });
  };

  const updateVoucherDiscount = (e: any) => {
    setVoucherDiscount(e.target.value);
  };

  const updateVoucher = (e: any) => {
    if (!setVoucher) return;

    let discount = voucherDiscount;
    if (discount < -100) discount = -100;
    else if (discount > 200) discount = 200;
    setVoucherDiscount(discount);

    setVoucher(
      (
        prev:
          | QuoteDto
          | CustomerDeliveryBillDto
          | CustomerProformaInvoiceDto
          | CustomerBidDto
          | CustomerOrderDto
          | null
      ) => ({
        ...prev,
        DiscountPercent: discount,
        Items: prev?.Items?.map((x) => ({
          ...x,
          DiscountPercent: discount * 1,
        })),
      })
    );
  };

  return (
    <Fragment>
      {activeTab === TAB_TYPE.INVOICE && (
        <div
          className="transition-height"
          style={{
            position: "fixed",
            bottom: "56px",
            width: "100%",
            backgroundColor: "white",
            borderTop: "1px solid #6d2243",
            left: 0,
            height: open ? "140px" : "60px",
          }}
        >
          <div
            onClick={() => setOpen(!open)}
            style={{
              top: "-45px",
              backgroundColor: "#6d2243",
              width: "45px",
              height: "45px",
              display: "block",
              position: "absolute",
              border: 0,
              borderRadius: "10px 10px 0 0",
              WebkitTransformOrigin: "center",
              msTransformOrigin: "center",
              transformOrigin: "center",
              left: 0,
            }}
          >
            <span
              style={{
                display: "block",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "28px",
                height: "28px",
                border: "2px solid #ffffff",
                color: "#ffffff",
                WebkitBorderRadius: "50%",
                borderRadius: "50%",
              }}
            >
              {open ? (
                <svg
                  style={{ marginLeft: "7px", marginTop: "5px" }}
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  height="18px"
                  viewBox="0 0 384 512"
                >
                  <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              ) : (
                <svg
                  style={{ marginLeft: "7px", marginTop: "5px" }}
                  height="18px"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                </svg>
              )}
            </span>
          </div>
          {open && (
            <Fragment>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "20px",
                  paddingTop: "3px",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                  }}
                >
                  Részösszeg
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                    marginLeft: "40px",
                  }}
                >
                  {modifier
                    ? formatNumberWithQuantityUnit(
                        getModifierNetValueSum(modifier),
                        modifier.Currency,
                        modifier.Currency?.NetValueDigits
                      )
                    : formatNumberWithQuantityUnit(
                        getVoucherNetValueSum(voucher),
                        currencies && (voucher as any)?.Currency
                          ? currencies[(voucher as any)?.Currency]
                          : currencies && (voucher as any)?.CurrencyId
                          ? currencies[(voucher as any)?.CurrencyId]
                          : undefined,
                        currencies && (voucher as any)?.Currency
                          ? currencies[(voucher as any)?.Currency]
                              ?.GrossValueDigits
                          : currencies && (voucher as any)?.CurrencyId
                          ? currencies[(voucher as any)?.CurrencyId]
                              ?.GrossValueDigits
                          : undefined
                      )}
                </div>
              </div>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "20px",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                  }}
                >
                  {voucherType === "customerbid" ||
                  voucherType === "customerorder" ||
                  voucherType === "customerdeliverybill" ||
                  voucherType === "customerproformainvoice"
                    ? "Kedvezmény/Felár"
                    : "Kedvezmény"}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                    marginLeft: "40px",
                  }}
                >
                  {modifier && `${modifier?.DiscountPercent} %`}
                  {voucher && (
                    <div
                      style={{
                        display: "inline",
                      }}
                    >
                      <InputComponent
                        disabled={isDisabled}
                        value={voucherDiscount ?? voucher?.DiscountPercent ?? 0}
                        name={"DiscountPercent"}
                        min={
                          voucherType === "customerbid" ||
                          voucherType === "customerorder" ||
                          voucherType === "customerdeliverybill" ||
                          voucherType === "customerproformainvoice"
                            ? -100
                            : 0
                        }
                        max={200}
                        numeric
                        onChange={updateVoucherDiscount}
                        onFocusLeave={updateVoucher}
                      />
                      <span style={{ marginLeft: "5px" }}>{`%`}</span>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "20px",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                  }}
                >
                  ÁFA
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                    marginLeft: "40px",
                  }}
                >
                  {modifier
                    ? formatNumberWithQuantityUnit(
                        getModifierVatValueSum(modifier),
                        modifier.Currency,
                        modifier.Currency?.GrossValueDigits
                      )
                    : formatNumberWithQuantityUnit(
                        getVoucherVatValueSum(voucher, vats),
                        currencies && (voucher as any)?.Currency
                          ? currencies[(voucher as any)?.Currency]
                          : currencies && (voucher as any)?.CurrencyId
                          ? currencies[(voucher as any)?.CurrencyId]
                          : undefined,
                        currencies && (voucher as any)?.Currency
                          ? currencies[(voucher as any)?.Currency]
                              ?.GrossValueDigits
                          : currencies && (voucher as any)?.CurrencyId
                          ? currencies[(voucher as any)?.CurrencyId]
                              ?.GrossValueDigits
                          : undefined
                      )}
                </div>
              </div>
            </Fragment>
          )}

          <div
            style={{
              textAlign: "right",
              paddingRight: "20px",
              paddingTop: "5px",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                display: "inline-block",
                fontWeight: 700,
                marginBottom: "5px",
              }}
            >
              Összesen
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: 700,
                display: "inline-block",
                marginLeft: "40px",
              }}
            >
              {modifier
                ? getModifierTotal(modifier, false)
                : voucher
                ? getVoucherTotal(
                    voucher,
                    paymentMethods && (voucher as any)?.PaymentMethod
                      ? paymentMethods[(voucher as any)?.PaymentMethod]
                      : undefined,
                    currencies && (voucher as any)?.Currency
                      ? currencies[(voucher as any)?.Currency]
                      : currencies && (voucher as any)?.CurrencyId
                      ? currencies[(voucher as any)?.CurrencyId]
                      : undefined,
                    vats
                  )
                : 0}
            </div>
          </div>
          {modifier && (
            <div
              style={{
                textAlign: "right",
                paddingRight: "20px",
                paddingTop: "5px",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  display: "inline-block",
                  fontWeight: 700,
                  marginBottom: "5px",
                }}
              >
                Befizetve
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  display: "inline-block",
                  marginLeft: "40px",
                }}
              >
                {getModifierTotal(modifier, true)}
              </div>
            </div>
          )}
        </div>
      )}

      <div
        style={{
          width: "100%",
          display: "-webkit-flex",
          position: "fixed",
          bottom: 0,
          left: 0,
        }}
      >
        <div
          onClick={modifier ? handleOpenPreviewModifier : handleOpenPreview}
          style={{
            padding: "5px",
            alignItems: "center",
            flex: "1 0 auto",
            justifyContent: "center",
            backgroundColor: "#3f76bb",
            cursor: "pointer",
            textAlign: "center",
            color: isSalesPlusPlus ? "rgba(255, 255, 255, 0.5)" : "white",
            fontSize: "13px",
            pointerEvents: isSalesPlusPlus ? "none" : "auto",
          }}
        >
          <div
            style={{
              height: "26px",
            }}
          >
            <div
              style={{
                borderRadius: "50%",
                border: "2px solid #ffffff",
                width: "24px",
                height: "24px",
                margin: "auto",
                opacity: isSalesPlusPlus ? 0.5 : 0,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="14px"
                viewBox="0 0 576 512"
                fill="white"
                style={{ marginTop: "4.5px", marginLeft: "0.9px" }}
              >
                <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
              </svg>
            </div>
          </div>
          Előnézet
        </div>
        {localStorage.getItem("action") !== "edit" &&
          localStorage.getItem("voucherType") !== "customerdeliverybill" && (
            <div
              onClick={() =>
                modifier
                  ? handleSaveModifier(false)
                  : handleSave
                  ? handleSave()
                  : {}
              }
              style={{
                padding: "5px",
                alignItems: "center",
                flex: "1 0 auto",
                justifyContent: "center",
                backgroundColor: "#3f76bb",
                cursor: "pointer",
                textAlign: "center",
                color: "white",
                fontSize: "13px",
              }}
            >
              <div
                style={{
                  height: "26px",
                }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    border: "2px solid #ffffff",
                    width: "24px",
                    height: "24px",
                    margin: "auto",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="14px"
                    viewBox="0 0 576 512"
                    fill="white"
                    style={{ marginTop: "5px", marginLeft: "4px" }}
                  >
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                </div>
              </div>
              {voucher ? "Mentés" : modifier ? "Véglegesítés" : ""}
            </div>
          )}
        <div
          onClick={() =>
            modifier ? handleSaveModifier(true) : handleSendVoucher()
          }
          style={{
            padding: "5px",
            alignItems: "center",
            flex: "1 0 auto",
            justifyContent: "center",
            backgroundColor: "#3f76bb",
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            fontSize: "13px",
          }}
        >
          <div
            style={{
              height: "26px",
            }}
          >
            <div
              style={{
                borderRadius: "50%",
                border: "2px solid #ffffff",
                width: "24px",
                height: "24px",
                margin: "auto",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="14px"
                viewBox="0 0 512 512"
                fill="white"
                style={{ marginTop: "5px", marginLeft: "1px" }}
              >
                <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
              </svg>
            </div>
          </div>
          Küldés
        </div>
        <div
          onClick={handleCancel}
          style={{
            padding: "5px",
            alignItems: "center",
            flex: "1 0 auto",
            justifyContent: "center",
            backgroundColor: "#CECECE",
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            fontSize: "13px",
          }}
        >
          <div
            style={{
              height: "26px",
            }}
          >
            <div
              style={{
                borderRadius: "50%",
                border: "2px solid #ffffff",
                width: "24px",
                height: "24px",
                margin: "auto",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="14px"
                viewBox="0 0 576 512"
                fill="white"
                style={{ marginTop: "5px", marginLeft: "5px" }}
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </div>
          </div>
          Mégse
        </div>
      </div>
    </Fragment>
  );
};

export default MobileBottomMenu;
