/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VoucherType {
    INVALID = 'Invalid',
    CUSTOMER_INVOICE = 'CustomerInvoice',
    CUSTOMER_DELIVERY_BILL = 'CustomerDeliveryBill',
    CUSTOMER_OFFER = 'CustomerOffer',
    CUSTOMER_ORDER = 'CustomerOrder',
    CUSTOMER_CONTRACT = 'CustomerContract',
    GENERIC_VOUCHER = 'GenericVoucher',
    CUSTOMER_STOCK_IN = 'CustomerStockIn',
    CUSTOMER_STOCK_OUT = 'CustomerStockOut',
    CUSTOMER_STOCK_MOVE = 'CustomerStockMove',
    CUSTOMER_RELATION = 'CustomerRelation',
    MIGRATED_BIZXPERT_INVOICE = 'MigratedBizxpertInvoice',
    CUSTOMER_MANUAL_INVOICE = 'CustomerManualInvoice',
    CUSTOMER_PROFORMA_INVOICE = 'CustomerProformaInvoice',
    CUSTOMER_ADVANCE_ACCOUNT = 'CustomerAdvanceAccount',
    CUSTOMER_INCOMING_INVOICE = 'CustomerIncomingInvoice',
    RECONCILIATION = 'Reconciliation',
    MANUFACTURE = 'Manufacture',
    DISASSAMBLE = 'Disassamble',
    PETTY_CASH = 'PettyCash',
    PETTY_CASH_CLOSING = 'PettyCashClosing',
    CUSTOMER_BID = 'CustomerBid',
    SUPPLIER_ORDER = 'SupplierOrder',
    DEMAND_FOR_PAYMENT = 'DemandForPayment',
    RECONCILIATION_PROCESS = 'ReconciliationProcess',
    GUARANTEE = 'Guarantee',
    CASH_REGISTER = 'CashRegister',
    DOCUMENT_DATA = 'DocumentData',
    BANK_ACCOUNT_STATEMENT = 'BankAccountStatement',
    SUPPLIER_DELIVERY_BILL = 'SupplierDeliveryBill',
    SALES_OPPORTUNITY = 'SalesOpportunity',
    WORKSHEET = 'Worksheet',
    ACCOUNTING = 'Accounting',
    APPOINTMENT = 'Appointment',
    STATEMENT_REPORT = 'StatementReport',
    SERVICE_HISTORY = 'ServiceHistory',
    CUSTOMER_RECEIPT = 'CustomerReceipt',
}
