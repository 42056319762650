import React from "react";
import {
  CountryDictionary,
  CurrencyDictionary,
  CustomerCategoryDictionary,
  CustomerDictionary,
  PaymentMethodDictionary,
  ProductCategoryDictionary,
  ProductDictionary,
  ProjectDictionary,
  QuantityUnitDictionary,
  TransportModeDictionary,
  VatDictionary,
} from "../models/Dictionaries";
import {
  CustomerBidDto,
  CustomerDeliveryBillDto,
  CustomerOrderDto,
  CustomerProformaInvoiceDto,
  CustomerReceiptDto,
  LanguageDto,
  ModifierInvoiceDto,
  QuoteDto,
  UserSettingsDto,
} from "../KulcsUzletApi";
import { FiltersObject } from "../models/FilterObj";

export type RefreshProps = {
  refresh: boolean;
  setRefresh: any;
};

export const RefreshContext = React.createContext<RefreshProps>({
  refresh: false,
  setRefresh: null,
});

export type MasterDataProps = {
  products: ProductDictionary | null;
  setProducts: any;
  transportModes: TransportModeDictionary | null;
  setTransportModes: any;
  projects: ProjectDictionary | null;
  setProjects: any;
  vats: VatDictionary | null;
  setVats: any;
  quantityUnits: QuantityUnitDictionary | null;
  setQuantityUnits: any;
  productCategories: ProductCategoryDictionary | null;
  setProductCategories: any;
  customerCategories: CustomerCategoryDictionary | null;
  setCustomerCategories: any;
  customers: CustomerDictionary | null;
  setCustomers: any;
  currencies: CurrencyDictionary | null;
  setCurrencies: any;
  paymentMethods: PaymentMethodDictionary | null;
  setPaymentMethods: any;
  countries: CountryDictionary | null;
  setCountries: any;
  languages: LanguageDto[] | null;
  setLanguages: any;
  userSettings: UserSettingsDto;
  setUserSettings: any;
};

export const MasterDataContext = React.createContext<MasterDataProps>({
  products: {} as ProductDictionary,
  setProducts: null,
  transportModes: {} as TransportModeDictionary,
  setTransportModes: null,
  projects: {} as ProjectDictionary,
  setProjects: null,
  vats: {} as VatDictionary,
  setVats: null,
  quantityUnits: {} as QuantityUnitDictionary,
  setQuantityUnits: null,
  productCategories: {} as ProductCategoryDictionary,
  setProductCategories: null,
  customerCategories: {} as CustomerCategoryDictionary,
  setCustomerCategories: null,
  customers: {} as CustomerDictionary,
  setCustomers: null,
  currencies: {} as CurrencyDictionary,
  setCurrencies: null,
  paymentMethods: {} as PaymentMethodDictionary,
  setPaymentMethods: null,
  countries: {} as CountryDictionary,
  setCountries: null,
  languages: [] as LanguageDto[],
  setLanguages: null,
  userSettings: {} as UserSettingsDto,
  setUserSettings: null,
});

export type ModifierProps = {
  modifier: ModifierInvoiceDto;
  setModifier: any;
};

export const ModifierContext = React.createContext<ModifierProps>({
  modifier: {} as ModifierInvoiceDto,
  setModifier: null,
});

export type VoucherProps = {
  voucher:
    | CustomerDeliveryBillDto
    | CustomerBidDto
    | CustomerOrderDto
    | CustomerProformaInvoiceDto;
  setVoucher: any;
};

export const VoucherContext = React.createContext<VoucherProps>({
  voucher: {} as CustomerDeliveryBillDto | {} as CustomerBidDto | {} as
    | CustomerOrderDto
    | {} as CustomerProformaInvoiceDto,
  setVoucher: null,
});

export type ReceiptProps = {
  receipt: CustomerReceiptDto;
  setReceipt: any;
};

export const ReceiptContext = React.createContext<ReceiptProps>({
  receipt: {} as CustomerReceiptDto,
  setReceipt: null,
});

export type QuoteProps = {
  quote: QuoteDto;
  setQuote: any;
};

export const QuoteContext = React.createContext<QuoteProps>({
  quote: {} as QuoteDto,
  setQuote: null,
});

export type DeviceProps = {
  isMobile: boolean;
  setIsMobile: any;
};

export const DeviceContext = React.createContext<DeviceProps>({
  isMobile: false,
  setIsMobile: null,
});

export type FilterProps = {
  filtersObj: FiltersObject;
  setFiltersObj: any;
};

export const FilterContext = React.createContext<FilterProps>({
  filtersObj: {} as FiltersObject,
  setFiltersObj: null,
});
