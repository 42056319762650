import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LOCAL_STORAGE_KEY } from "../utils/Enums";

export default function FirstPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    // https://helyesbitoszamla.dev.adatpont.info/auth?dbGuid=509e8f43072b4bdca8201e1c2463921e&voucherType=quote&action=edit&id=11444
    getContext().then(() => {
      const voucherType =
        searchParams.get("voucherType") ??
        localStorage.getItem("voucherType") ??
        localStorage.getItem("vouchertype");

      if (voucherType === "modifier") navigate("/");
      else navigate("/" + voucherType);
    });
  }, []);

  const getContext = async () => {
    const dbGuid = searchParams.get("dbGuid") ?? searchParams.get("dbguid");

    if (dbGuid) localStorage.setItem("dbGuid", dbGuid);

    const id = searchParams.get("id");
    if (id) localStorage.setItem("id", id);

    const action = searchParams.get("action");
    if (action) localStorage.setItem("action", action);

    const isSalesPlusPlus = searchParams.get("isSalesPlusPlus");
    if (isSalesPlusPlus)
      localStorage.setItem("isSalesPlusPlus", isSalesPlusPlus);
    else localStorage.setItem("isSalesPlusPlus", "0");

    const voucherType =
      searchParams.get("vouchertype") ?? searchParams.get("voucherType");
    if (voucherType) localStorage.setItem("voucherType", voucherType);

    const relationVoucherType =
      searchParams.get("relationVoucherType") ??
      searchParams.get("RelationVoucherType");
    if (relationVoucherType)
      localStorage.setItem(
        "relationVoucherType",
        relationVoucherType?.toLowerCase()
      );
    else localStorage.removeItem("relationVoucherType");

    const relationId =
      searchParams.get("relationId") ?? searchParams.get("RelationId");
    if (relationId) localStorage.setItem("relationId", relationId);
    else localStorage.removeItem("relationId");
  };

  return <div></div>;
}
