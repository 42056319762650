import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import InputComponent from "../input/InputComponent";
import { useStylesDialogEditCustomerStyle } from "../../styles/DialogEditCustomerStyle";
import { useState } from "react";
import { CUSTOMER_MODAL_TAB, INPUT_VALIDATION } from "../../utils/Enums";
import {
  CategoryDto,
  CountryDto,
  CurrencyDto,
  CustomerBidDto,
  CustomerDefaultInvoicingType,
  CustomerDeliveryBillDto,
  CustomerDto,
  CustomerOrderDto,
  CustomerProformaInvoiceDto,
  MasterDataService,
  PaymentMethodDto,
  QuoteDto,
} from "../../KulcsUzletApi";
import {
  DeviceContext,
  MasterDataContext,
  QuoteContext,
  RefreshContext,
  VoucherContext,
} from "../../utils/GlobalContexts";
import SelectComponent from "../select/SelectComponent";
import {
  Switch,
  SwitchOnChangeData,
  Textarea,
} from "@fluentui/react-components";
import InfoIcon from "../icons/InfoIcon";
import QuestionIcon from "../icons/QuestionIcon";
import { defaultInvoicingTypes } from "../../utils/GlobalConsts";
import { DialogCustomerProps } from "../../interfaces/DialogCustomerProps";

const DialogEditCustomer: React.FC<DialogCustomerProps> = ({
  open,
  setOpen,
  title,
  customerObj,
  setShouldUpdateCurrency,
}) => {
  const styles = useStylesDialogEditCustomerStyle();
  const [tab, setTab] = useState(CUSTOMER_MODAL_TAB.DATA);
  const [currentCustomerObj, setCurrentCustomerObj] = useState<
    CustomerDto | undefined
  >(undefined);
  const { customerCategories, currencies, paymentMethods, countries } =
    React.useContext(MasterDataContext);
  const { setRefresh } = React.useContext(RefreshContext);
  const [showTooltips, setShowToolTips] = useState([] as string[]);
  const { isMobile } = React.useContext(DeviceContext);
  const { quote, setQuote } = React.useContext(QuoteContext);
  const { voucher, setVoucher } = React.useContext(VoucherContext);
  const isSalesPlusPlus = localStorage.getItem("isSalesPlusPlus") === "1";

  React.useEffect(() => {
    setTab(CUSTOMER_MODAL_TAB.DATA);
    getCurrentCustomerObj();
  }, [open]);

  React.useEffect(() => {
    if (showTooltips?.length > 0) {
      setTimeout(() => {
        setShowToolTips([]);
      }, 3000);
    }
  }, [showTooltips]);

  const getCurrentCustomerObj = async () => {
    const customerobj = customerObj ?? ({} as CustomerDto);
    if (!customerobj.Code) {
      const customerCode =
        await MasterDataService.getMasterdataGetnextcustomercode().catch((e) =>
          console.log(e)
        );
      if (customerCode) customerobj.Code = customerCode.toString();
    }
    if (!customerobj.IsPrivatePerson) customerobj.IsPrivatePerson = 0;
    if (!customerobj.IsTaxablePerson) customerobj.IsTaxablePerson = 0;
    if (!customerobj.CentralCountry) customerobj.CentralCountry = 237;
    if (!customerobj.PaymentMethod) customerobj.PaymentMethod = 313;
    if (!customerobj.DefaultCurrency) customerobj.DefaultCurrency = 245;
    if (!customerobj.DefaultInvoicingType)
      customerobj.DefaultInvoicingType = CustomerDefaultInvoicingType.PAPER;

    if (open) setCurrentCustomerObj(customerobj);
    else setCurrentCustomerObj(undefined);
  };

  const getTabIconColor = (currentTab: CUSTOMER_MODAL_TAB) =>
    tab === currentTab ? { backgroundColor: "#3f76bb" } : {};

  const editCustomer = async (e: any) => {
    let value = e.target.value;
    if (e.target.name === "DiscountPercent") {
      value = value > 200 ? 200 : value < -100 ? -100 : value;
    }
    setCurrentCustomerObj((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  };

  const editCustomerSelectCategory = async (ev: any, data: any) => {
    const selectedId = (data.optionValue as CategoryDto)?.Id ?? undefined;
    const selectedName = (data.optionValue as CategoryDto)?.Name ?? undefined;
    setCurrentCustomerObj((prev) => ({
      ...prev,
      Category1: selectedId,
      Category1Name: selectedName,
    }));
  };

  const editCustomerSelectCurrency = async (ev: any, data: any) => {
    setCurrentCustomerObj((prev) => ({
      ...prev,
      DefaultCurrency: (data.optionValue as CurrencyDto)?.Id ?? undefined,
    }));
  };

  const editCustomerSelectPaymentMethod = async (ev: any, data: any) => {
    setCurrentCustomerObj((prev) => ({
      ...prev,
      PaymentMethod: (data.optionValue as PaymentMethodDto)?.Id ?? undefined,
      PaymentMethodDelayDay:
        (data.optionValue as PaymentMethodDto)?.DelayDays ?? undefined,
    }));
  };

  const editCustomerSelectCountry = async (ev: any, data: any) => {
    setCurrentCustomerObj((prev) => ({
      ...prev,
      CentralCountry: (data.optionValue as CountryDto)?.Id ?? undefined,
    }));
  };

  const changePrivatePersonSwitch = (
    ev: React.ChangeEvent<HTMLInputElement>,
    data: SwitchOnChangeData
  ) => {
    setCurrentCustomerObj((prev) => ({
      ...prev,
      IsPrivatePerson: data.checked ? 1 : 0,
    }));
  };

  const handleSaveCustomer = async () => {
    if (
      !currentCustomerObj?.Name ||
      !currentCustomerObj?.CentralZip ||
      !currentCustomerObj?.CentralCity ||
      !currentCustomerObj?.CentralStreet
    ) {
      let missingFields = [] as string[];
      if (!currentCustomerObj?.Name) missingFields.push("CustomerName");
      if (!currentCustomerObj?.CentralZip)
        missingFields.push("CustomerCentralZip");
      if (!currentCustomerObj?.CentralCity)
        missingFields.push("CustomerCentralCity");
      if (!currentCustomerObj?.CentralStreet)
        missingFields.push("CustomerCentralStreet");

      setShowToolTips(missingFields);
      setTab(CUSTOMER_MODAL_TAB.DATA);
    } else
      await MasterDataService.postMasterdataCustomer(currentCustomerObj)
        .then((res) => {
          if (quote) {
            setQuote((prev: QuoteDto) => ({
              ...prev,
              CustomerObj: res,
              CustomerNameDisplay: res.Name,
              Customer: res.Id,
              Currency: isSalesPlusPlus
                ? quote.Currency
                : currentCustomerObj.DefaultCurrency ?? quote.Currency,
            }));
          } else if (voucher) {
            setVoucher(
              (
                prev:
                  | CustomerDeliveryBillDto
                  | CustomerOrderDto
                  | CustomerProformaInvoiceDto
                  | CustomerBidDto
              ) => ({
                ...prev,
                CustomerObj: res,
                CustomerNameDisplay: res.Name,
                CustomerId: res.Id,
                CurrencyId: isSalesPlusPlus
                  ? voucher.CurrencyId
                  : currentCustomerObj.DefaultCurrency ?? voucher.CurrencyId,
              })
            );
          }
          if (!isSalesPlusPlus)
            setShouldUpdateCurrency && setShouldUpdateCurrency(true);

          setRefresh(true);
          setOpen(false);
        })
        .catch((e) => console.log(e));
  };

  const addItemToRequiredFields = (item: string) => {
    let fields = [] as string[];
    fields.push(item);
    setShowToolTips(fields);
  };

  function onChangeInvoicingTypeSelect(ev: any, data: any): void {
    setCurrentCustomerObj((prev) => ({
      ...prev,
      DefaultInvoicingType: data.optionValue.Id,
    }));
  }

  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title={title}
      saveAction={handleSaveCustomer}
      saveBtnText="Mentés"
      closeBtnText="Mégse"
    >
      {title === "Partner szerkesztése" ? (
        <table className={styles.table}>
          <tbody>
            <tr>
              <td rowSpan={2} className={styles.fcolumn}>
                {customerObj?.Name}
              </td>
              <td className={styles.scolumn}>
                <span className={styles.fspan}>Létrehozás napja:</span>
                <span className={styles.sspan}>
                  {customerObj?.DateOfFiling &&
                    new Date(customerObj?.DateOfFiling).toLocaleDateString()}
                </span>
              </td>
            </tr>
            <tr className={styles.scolumn}>
              <td className={styles.scolumn}>
                <span className={styles.fspan}>Első értékesítés:</span>
                <span className={styles.sspan}>
                  {customerObj?.FirstPurchaseDate &&
                    new Date(
                      customerObj?.FirstPurchaseDate
                    ).toLocaleDateString()}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <></>
      )}
      <div className={styles.tabs}>
        <div
          className={isMobile ? styles.mobilewrapper : styles.wrapper}
          style={getTabIconColor(CUSTOMER_MODAL_TAB.DATA)}
          onClick={() => setTab(CUSTOMER_MODAL_TAB.DATA)}
        >
          <div className={styles.tabicon}>
            <img
              src="https://app.kulcs-uzlet.hu/images/client_data_icon.png"
              style={{ width: "35px", height: "auto" }}
              alt="1"
            />
          </div>
        </div>
        <div
          className={isMobile ? styles.mobilewrapper : styles.wrapper}
          style={getTabIconColor(CUSTOMER_MODAL_TAB.CRM)}
          onClick={() => setTab(CUSTOMER_MODAL_TAB.CRM)}
        >
          <div className={styles.tabicon}>
            <img
              src="https://app.kulcs-uzlet.hu/images/client_crm_icon.png"
              style={{ width: "35px", height: "auto" }}
              alt="2"
            />
          </div>
        </div>
        <div
          className={isMobile ? styles.mobilewrapper : styles.wrapper}
          style={getTabIconColor(CUSTOMER_MODAL_TAB.PAYMENT)}
          onClick={() => setTab(CUSTOMER_MODAL_TAB.PAYMENT)}
        >
          <div className={styles.tabicon}>
            <img
              src="https://app.kulcs-uzlet.hu/images/client_payment_icon.png"
              style={{ width: "35px", height: "auto" }}
              alt="3"
            />
          </div>
        </div>
        <div
          className={isMobile ? styles.mobilewrapper : styles.wrapper}
          style={getTabIconColor(CUSTOMER_MODAL_TAB.LOCAL)}
          onClick={() => setTab(CUSTOMER_MODAL_TAB.LOCAL)}
        >
          <div className={styles.tabicon}>
            <img
              src="https://app.kulcs-uzlet.hu/images/client_local_icon.png"
              style={{ width: "35px", height: "auto" }}
              alt="4"
            />
          </div>
        </div>
        <div className={styles.isPrivate}>
          <div
            className={
              isMobile ? styles.mobileisPrivateText : styles.isPrivateText
            }
          >
            Magánszemély
          </div>
          <div className={styles.isPrivateSwitch}>
            <Switch
              checked={currentCustomerObj?.IsPrivatePerson === 1 ? true : false}
              onChange={changePrivatePersonSwitch}
            />
          </div>
        </div>
      </div>

      <div
        className={`${styles.tab} ${
          tab !== CUSTOMER_MODAL_TAB.DATA ? styles.hide : ""
        }`}
      >
        <div className={isMobile ? "" : styles.first}>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Név *
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                id="CustomerName"
                tooltip="A mező kitöltése kötelező"
                tooltipColor="#b33c39"
                showTooltip={
                  tab === CUSTOMER_MODAL_TAB.DATA &&
                  showTooltips?.includes("CustomerName")
                }
                fullwidth
                value={currentCustomerObj?.Name ?? ""}
                name={"Name"}
                onChange={editCustomer}
                withCompanySearch
                setCustomerObj={setCurrentCustomerObj}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Irányítószám *
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                id="CustomerCentralZip"
                tooltip="A mező kitöltése kötelező"
                tooltipColor="#b33c39"
                showTooltip={
                  tab === CUSTOMER_MODAL_TAB.DATA &&
                  showTooltips?.includes("CustomerCentralZip")
                }
                fullwidth
                value={currentCustomerObj?.CentralZip ?? ""}
                name={"CentralZip"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Település *
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                id="CustomerCentralCity"
                tooltip="A mező kitöltése kötelező"
                tooltipColor="#b33c39"
                showTooltip={
                  tab === CUSTOMER_MODAL_TAB.DATA &&
                  showTooltips?.includes("CustomerCentralCity")
                }
                fullwidth
                value={currentCustomerObj?.CentralCity ?? ""}
                name={"CentralCity"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Cím *
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                id="CustomerCentralStreet"
                tooltip="A mező kitöltése kötelező"
                tooltipColor="#b33c39"
                showTooltip={
                  tab === CUSTOMER_MODAL_TAB.DATA &&
                  showTooltips?.includes("CustomerCentralStreet")
                }
                fullwidth
                value={currentCustomerObj?.CentralStreet ?? ""}
                name={"CentralStreet"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Kapcsolattartó
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.ContactName ?? ""}
                name={"ContactName"}
                onChange={editCustomer}
              />
            </div>
          </div>
        </div>
        <div className={isMobile ? "" : styles.second}>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Ország
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <SelectComponent
                fullwidth
                options={countries && (Object.values(countries) as any)}
                value={
                  (countries &&
                    (currentCustomerObj?.CentralCountry
                      ? countries[currentCustomerObj?.CentralCountry]?.Name
                      : countries[237]?.Name)) ??
                  ""
                }
                action={editCustomerSelectCountry}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Partnerkód
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                id="CustomerCode"
                tooltip="Maximum 20 karakteres egyedi azonosító."
                tooltipColor="#36bd3f"
                showTooltip={
                  tab === CUSTOMER_MODAL_TAB.DATA &&
                  showTooltips?.includes("CustomerCode")
                }
                value={currentCustomerObj?.Code ?? ""}
                name={"Code"}
                onChange={editCustomer}
                iconCount={1}
                icon={
                  <InfoIcon
                    onClick={() => addItemToRequiredFields("CustomerCode")}
                  />
                }
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Magyar adószám **
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.TaxNumber ?? ""}
                name={"TaxNumber"}
                showTooltip={
                  tab === CUSTOMER_MODAL_TAB.DATA &&
                  showTooltips?.includes("TaxNumber")
                }
                tooltip="Információk lekéréséhez ajdon meg adószámot!"
                tooltipColor="#b33c39"
                onChange={editCustomer}
                iconCount={2}
                icon={
                  <React.Fragment>
                    <QuestionIcon
                      onClick={() => addItemToRequiredFields("TaxNumber")}
                    />
                    <InfoIcon
                      onClick={() =>
                        window
                          .open(
                            "https://kulcsuzlet.freshdesk.com/support/solutions/articles/47001174430-hiba%C3%BCzenetek#Helytelen-ad%C3%B3sz%C3%A1m/-csoportazonos%C3%ADt%C3%B3/-EU-ad%C3%B3sz%C3%A1m-form%C3%A1tum",
                            "_blank"
                          )
                          ?.focus()
                      }
                    />
                  </React.Fragment>
                }
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Telefonszám
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.Phone ?? ""}
                name={"Phone"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Email
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.Email ?? ""}
                showTooltip={
                  tab === CUSTOMER_MODAL_TAB.DATA &&
                  showTooltips?.includes("Email")
                }
                tooltip="Több email cím is megadható pontosvesszővel (;) elválasztva."
                tooltipColor="#36bd3f"
                iconCount={1}
                icon={
                  <InfoIcon onClick={() => addItemToRequiredFields("Email")} />
                }
                name={"Email"}
                onChange={editCustomer}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.tab} ${
          tab !== CUSTOMER_MODAL_TAB.CRM ? styles.hide : ""
        }`}
      >
        <div className={isMobile ? "" : styles.first}>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Fizetési mód
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <SelectComponent
                fullwidth
                options={
                  paymentMethods && (Object.values(paymentMethods) as any)
                }
                value={
                  (paymentMethods &&
                    currentCustomerObj?.PaymentMethod &&
                    paymentMethods[currentCustomerObj?.PaymentMethod]?.Name) ??
                  ""
                }
                action={editCustomerSelectPaymentMethod}
                noEmptyOption
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Egyedi fiz. határidő
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                numeric
                value={currentCustomerObj?.PaymentMethodDelayDay ?? 0}
                name={"PaymentMethodDelayDay"}
                onChange={editCustomer}
                placeholder="Napok száma"
                disabled={
                  !currentCustomerObj?.PaymentMethod ||
                  (paymentMethods &&
                  currentCustomerObj?.PaymentMethod &&
                  paymentMethods[currentCustomerObj?.PaymentMethod]
                    ?.ImmediatePay === 1
                    ? true
                    : false)
                }
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Kedvezmény
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                numeric
                value={currentCustomerObj?.DiscountPercent ?? 0}
                max={200}
                min={-100}
                name={"DiscountPercent"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Weboldal
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.Webpage ?? ""}
                name={"Webpage"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Számlázási típus
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <SelectComponent
                fullwidth
                value={
                  defaultInvoicingTypes.find(
                    (x) => x.Id === currentCustomerObj?.DefaultInvoicingType
                  )?.Name ?? ""
                }
                options={defaultInvoicingTypes as any}
                action={onChangeInvoicingTypeSelect}
                noEmptyOption
              />
            </div>
          </div>
        </div>
        <div className={isMobile ? "" : styles.second}>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Alapértelmezett deviza
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <SelectComponent
                fullwidth
                options={currencies && (Object.values(currencies) as any)}
                value={
                  (currencies &&
                    currentCustomerObj?.DefaultCurrency &&
                    currencies[currentCustomerObj?.DefaultCurrency]
                      ?.DisplayName) ??
                  ""
                }
                action={editCustomerSelectCurrency}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Partnerkategória
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <SelectComponent
                fullwidth
                options={
                  customerCategories &&
                  (Object.values(customerCategories) as any)
                }
                value={
                  (customerCategories &&
                    currentCustomerObj?.Category1 &&
                    customerCategories[currentCustomerObj?.Category1]?.Name) ??
                  ""
                }
                action={editCustomerSelectCategory}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.tab} ${
          tab !== CUSTOMER_MODAL_TAB.PAYMENT ? styles.hide : ""
        }`}
      >
        <div className={isMobile ? "" : styles.first}>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Bankszámla
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.BankAccount ?? ""}
                name={"BankAccount"}
                onChange={editCustomer}
                validation={INPUT_VALIDATION.BANK_ACCOUNT}
                maxLength={26}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              EU adószám
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.EUTaxNumber ?? ""}
                name={"EUTaxNumber"}
                onChange={editCustomer}
                iconCount={1}
                icon={
                  <React.Fragment>
                    <InfoIcon
                      onClick={() =>
                        window
                          .open(
                            "https://kulcsuzlet.freshdesk.com/support/solutions/articles/47001174430-hiba%C3%BCzenetek#Helytelen-ad%C3%B3sz%C3%A1m/-csoportazonos%C3%ADt%C3%B3/-EU-ad%C3%B3sz%C3%A1m-form%C3%A1tum",
                            "_blank"
                          )
                          ?.focus()
                      }
                    />
                  </React.Fragment>
                }
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              IBAN
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.IBAN ?? ""}
                name={"IBAN"}
                onChange={editCustomer}
                maxLength={39}
                validation={INPUT_VALIDATION.IBAN}
              />
            </div>
          </div>
          <div
            className={isMobile ? styles.mobileline : styles.line}
            style={isMobile ? { height: "100px" } : {}}
          >
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Megjegyzések
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <Textarea
                style={{
                  width: "100%",
                  height: isMobile ? "70px" : "70px",
                  overflow: "hidden",
                }}
                value={currentCustomerObj?.Comment ?? ""}
                onChange={editCustomer}
                name={"Comment"}
              />
            </div>
          </div>
        </div>
        <div className={isMobile ? "" : styles.second}>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Csoportazonosító
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.GroupId ?? ""}
                name={"GroupId"}
                onChange={editCustomer}
                maxLength={13}
                validation={INPUT_VALIDATION.TAX_NUMBER_GROUP_ID}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Közösségen kívüli adószám
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.OOCTaxNumber ?? ""}
                name={"OOCTaxNumber"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              SWIFT kód
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.SWIFT ?? ""}
                name={"SWIFT"}
                onChange={editCustomer}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${styles.tab} ${
          tab !== CUSTOMER_MODAL_TAB.LOCAL ? styles.hide : ""
        }`}
      >
        <div className={isMobile ? "" : styles.first}>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Szállítási név
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.DeliveryName ?? ""}
                name={"DeliveryName"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Szállítási irányítószám
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.DeliveryZip ?? ""}
                name={"DeliveryZip"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Szállítási település
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.DeliveryCity ?? ""}
                name={"DeliveryCity"}
                onChange={editCustomer}
              />
            </div>
          </div>
          <div className={isMobile ? styles.mobileline : styles.line}>
            <div className={isMobile ? styles.mobilefpart : styles.fpart}>
              Szállítási cím
            </div>
            <div className={isMobile ? styles.mobilespart : styles.spart}>
              <InputComponent
                fullwidth
                value={currentCustomerObj?.DeliveryStreet ?? ""}
                name={"DeliveryStreet"}
                onChange={editCustomer}
              />
            </div>
          </div>
        </div>
      </div>

      <span
        className={
          isMobile ? styles.mobilerequiredFieldHelp : styles.requiredFieldHelp
        }
      >
        ** Amennyiben külföldi partner EU adószámát vagy Közösségen kívüli
        adószámát szeretnéd rögzíteni, azt a 3. lapon tedd meg!
      </span>
      <br />
      <span
        className={
          isMobile ? styles.mobilerequiredFieldHelp : styles.requiredFieldHelp
        }
      >
        * Kötelező mező
      </span>
    </DialogSkeleton>
  );
};

export default DialogEditCustomer;
