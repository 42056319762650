/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoryDto } from '../models/CategoryDto';
import type { CIBApiResponseDto } from '../models/CIBApiResponseDto';
import type { CompanyProfileDto } from '../models/CompanyProfileDto';
import type { ContactSourceDto } from '../models/ContactSourceDto';
import type { ContactUsDto } from '../models/ContactUsDto';
import type { CountryDto } from '../models/CountryDto';
import type { CurrencyDto } from '../models/CurrencyDto';
import type { CurrencyPriceDto } from '../models/CurrencyPriceDto';
import type { CustomerAddressDto } from '../models/CustomerAddressDto';
import type { CustomerContactDto } from '../models/CustomerContactDto';
import type { CustomerDto } from '../models/CustomerDto';
import type { CustomerDtoImportResult } from '../models/CustomerDtoImportResult';
import type { CustomerPictureDto } from '../models/CustomerPictureDto';
import type { CustomerStockMoveTitleDto } from '../models/CustomerStockMoveTitleDto';
import type { DeleteCategoryRequestDto } from '../models/DeleteCategoryRequestDto';
import type { ExpensePictureDto } from '../models/ExpensePictureDto';
import type { FilterDto } from '../models/FilterDto';
import type { KataCustomerExport } from '../models/KataCustomerExport';
import type { KobakAuthDto } from '../models/KobakAuthDto';
import type { LanguageDto } from '../models/LanguageDto';
import type { NavQuantityUnitDto } from '../models/NavQuantityUnitDto';
import type { PaymentMethodDto } from '../models/PaymentMethodDto';
import type { ProductDto } from '../models/ProductDto';
import type { ProductDtoImportResult } from '../models/ProductDtoImportResult';
import type { ProductPictureDto } from '../models/ProductPictureDto';
import type { ProjectDto } from '../models/ProjectDto';
import type { PublicDomainDto } from '../models/PublicDomainDto';
import type { QuantityUnitDto } from '../models/QuantityUnitDto';
import type { ResultDto } from '../models/ResultDto';
import type { ScopeOfActivityDto } from '../models/ScopeOfActivityDto';
import type { SimilarCustomersDto } from '../models/SimilarCustomersDto';
import type { SimpleModelDto } from '../models/SimpleModelDto';
import type { SliderContentDto } from '../models/SliderContentDto';
import type { SmallTaxPayerDto } from '../models/SmallTaxPayerDto';
import type { TaxGroupDto } from '../models/TaxGroupDto';
import type { TransportModeDto } from '../models/TransportModeDto';
import type { UgyvitelSyncDto } from '../models/UgyvitelSyncDto';
import type { ValidatableCompanyProfileDto } from '../models/ValidatableCompanyProfileDto';
import type { ValidatableCustomerDto } from '../models/ValidatableCustomerDto';
import type { ValidatableCustomerStockMoveTitleDto } from '../models/ValidatableCustomerStockMoveTitleDto';
import type { ValidatableProductDto } from '../models/ValidatableProductDto';
import type { VatDto } from '../models/VatDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MasterDataService {

    /**
     * @param pId 
     * @returns ProjectDto Success
     * @throws ApiError
     */
    public static getMasterdataProject(
pId: number,
): CancelablePromise<ProjectDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/project/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ProjectDto Success
     * @throws ApiError
     */
    public static postMasterdataProject(
requestBody?: ProjectDto,
): CancelablePromise<ProjectDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/project',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProjectDto Success
     * @throws ApiError
     */
    public static getMasterdataProject1(): CancelablePromise<Array<ProjectDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/project',
        });
    }

    /**
     * @param requestBody 
     * @returns ProjectDto Success
     * @throws ApiError
     */
    public static postMasterdataUpdateproject(
requestBody?: ProjectDto,
): CancelablePromise<ProjectDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/updateproject',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataDeleteproject(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deleteproject/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns TransportModeDto Success
     * @throws ApiError
     */
    public static getMasterdataTransportMode(): CancelablePromise<Array<TransportModeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/transportMode',
        });
    }

    /**
     * @param requestBody 
     * @returns TransportModeDto Success
     * @throws ApiError
     */
    public static postMasterdataTransportMode(
requestBody?: TransportModeDto,
): CancelablePromise<TransportModeDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/transportMode',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns TransportModeDto Success
     * @throws ApiError
     */
    public static getMasterdataTransportMode1(
pId: number,
): CancelablePromise<TransportModeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/transportMode/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TransportModeDto Success
     * @throws ApiError
     */
    public static postMasterdataTransportModes(
requestBody?: Array<TransportModeDto>,
): CancelablePromise<Array<TransportModeDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/transportModes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataTransportModeDelete(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/transportMode/delete/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns CustomerStockMoveTitleDto Success
     * @throws ApiError
     */
    public static getMasterdataCustomerstockmovetitle(): CancelablePromise<Array<CustomerStockMoveTitleDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customerstockmovetitle',
        });
    }

    /**
     * @param requestBody 
     * @returns CustomerStockMoveTitleDto Success
     * @throws ApiError
     */
    public static postMasterdataCustomerstockmovetitle(
requestBody?: ValidatableCustomerStockMoveTitleDto,
): CancelablePromise<CustomerStockMoveTitleDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/customerstockmovetitle',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns CustomerStockMoveTitleDto Success
     * @throws ApiError
     */
    public static getMasterdataCustomerstockmovetitle1(
pId: number,
): CancelablePromise<CustomerStockMoveTitleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customerstockmovetitle/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns CustomerStockMoveTitleDto Success
     * @throws ApiError
     */
    public static postMasterdataUpdatecustomerstockmovetitle(
requestBody?: ValidatableCustomerStockMoveTitleDto,
): CancelablePromise<CustomerStockMoveTitleDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/updatecustomerstockmovetitle',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataDeletecustomerstockmovetitle(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deletecustomerstockmovetitle/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns CountryDto Success
     * @throws ApiError
     */
    public static getMasterdataCountry(): CancelablePromise<Array<CountryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/country',
        });
    }

    /**
     * @returns CompanyProfileDto Success
     * @throws ApiError
     */
    public static getMasterdataCompanyprofile(): CancelablePromise<CompanyProfileDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/companyprofile',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataCompanyprofile(
requestBody?: ValidatableCompanyProfileDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/companyprofile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns SmallTaxPayerDto Success
     * @throws ApiError
     */
    public static postMasterdataGetallincome(
requestBody?: SmallTaxPayerDto,
): CancelablePromise<SmallTaxPayerDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/getallincome',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SmallTaxPayerDto Success
     * @throws ApiError
     */
    public static getMasterdataGetFormerKataIncome(): CancelablePromise<SmallTaxPayerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getFormerKataIncome',
        });
    }

    /**
     * @returns KataCustomerExport Success
     * @throws ApiError
     */
    public static getMasterdataGetFormerKataCustomers(): CancelablePromise<Array<KataCustomerExport>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getFormerKataCustomers',
        });
    }

    /**
     * @returns PublicDomainDto Success
     * @throws ApiError
     */
    public static getMasterdataPublicdomains(): CancelablePromise<Array<PublicDomainDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/publicdomains',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataContactus(
requestBody?: ContactUsDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/contactus',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pDatabaseInfoId 
     * @param pAppType 
     * @param pGuid 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataUpdateuserstatistics(
pDatabaseInfoId?: number,
pAppType?: number,
pGuid?: string,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/updateuserstatistics',
            query: {
                'pDatabaseInfoId': pDatabaseInfoId,
                'pAppType': pAppType,
                'pGuid': pGuid,
            },
        });
    }

    /**
     * @returns KobakAuthDto Success
     * @throws ApiError
     */
    public static getMasterdataKobakauth(): CancelablePromise<KobakAuthDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/kobakauth',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataKobakauth(
requestBody?: KobakAuthDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/kobakauth',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param filterType 
     * @returns FilterDto Success
     * @throws ApiError
     */
    public static getMasterdataFilter(
filterType: string,
): CancelablePromise<FilterDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/filter/{filterType}',
            path: {
                'filterType': filterType,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataFilter(
requestBody?: FilterDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/filter',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SliderContentDto Success
     * @throws ApiError
     */
    public static getMasterdataSlidercontent(): CancelablePromise<Array<SliderContentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/slidercontent',
        });
    }

    /**
     * @returns LanguageDto Success
     * @throws ApiError
     */
    public static getMasterdataInvoicelanguage(): CancelablePromise<Array<LanguageDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/invoicelanguage',
        });
    }

    /**
     * @param requestBody 
     * @returns CustomerDtoImportResult Success
     * @throws ApiError
     */
    public static postMasterdataCustomerlist(
requestBody?: Array<CustomerDto>,
): CancelablePromise<CustomerDtoImportResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/customerlist',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProductDtoImportResult Success
     * @throws ApiError
     */
    public static postMasterdataProductlist(
requestBody?: Array<ProductDto>,
): CancelablePromise<ProductDtoImportResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/productlist',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static postMasterdataCustomersync(
requestBody?: UgyvitelSyncDto,
): CancelablePromise<Array<CustomerDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/customersync',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static postMasterdataProductsync(
requestBody?: UgyvitelSyncDto,
): CancelablePromise<Array<ProductDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/productsync',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CIBApiResponseDto Success
     * @throws ApiError
     */
    public static postMasterdataGetsubscriptiondata(): CancelablePromise<CIBApiResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/getsubscriptiondata',
        });
    }

    /**
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataProductmonitor(): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/productmonitor',
        });
    }

    /**
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataCustomermonitor(): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customermonitor',
        });
    }

    /**
     * @returns CurrencyDto Success
     * @throws ApiError
     */
    public static getMasterdataCurrency(): CancelablePromise<Array<CurrencyDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/currency',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataCurrency(
requestBody?: CurrencyDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/currency',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataDeletecurrency(
requestBody?: CurrencyDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/deletecurrency',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pCurrencyId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getMasterdataGetcurrencyisused(
pCurrencyId: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getcurrencyisused/{pCurrencyId}',
            path: {
                'pCurrencyId': pCurrencyId,
            },
        });
    }

    /**
     * @returns VatDto Success
     * @throws ApiError
     */
    public static getMasterdataVat(): CancelablePromise<Array<VatDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/vat',
        });
    }

    /**
     * @param pId 
     * @returns TaxGroupDto Success
     * @throws ApiError
     */
    public static getMasterdataTaxgroup(
pId: number,
): CancelablePromise<TaxGroupDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/taxgroup/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns PaymentMethodDto Success
     * @throws ApiError
     */
    public static getMasterdataPaymentmethod(): CancelablePromise<Array<PaymentMethodDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/paymentmethod',
        });
    }

    /**
     * @param requestBody 
     * @returns PaymentMethodDto Success
     * @throws ApiError
     */
    public static postMasterdataPaymentmethod(
requestBody?: PaymentMethodDto,
): CancelablePromise<PaymentMethodDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/paymentmethod',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataUpdatedefaultpmethod(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/updatedefaultpmethod/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataDeletepaymentmethod(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deletepaymentmethod/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns QuantityUnitDto Success
     * @throws ApiError
     */
    public static getMasterdataQuantityunit(): CancelablePromise<Array<QuantityUnitDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/quantityunit',
        });
    }

    /**
     * @param requestBody 
     * @returns QuantityUnitDto Success
     * @throws ApiError
     */
    public static postMasterdataQuantityunit(
requestBody?: QuantityUnitDto,
): CancelablePromise<QuantityUnitDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/quantityunit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param quantityUnitName 
     * @returns QuantityUnitDto Success
     * @throws ApiError
     */
    public static getMasterdataQuantityunitbyname(
quantityUnitName: string,
): CancelablePromise<QuantityUnitDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/quantityunitbyname/{quantityUnitName}',
            path: {
                'quantityUnitName': quantityUnitName,
            },
        });
    }

    /**
     * @returns NavQuantityUnitDto Success
     * @throws ApiError
     */
    public static getMasterdataNavquantityunit(): CancelablePromise<Array<NavQuantityUnitDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/navquantityunit',
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataDeletequantityunit(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deletequantityunit/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static getMasterdataCustomer(): CancelablePromise<Array<CustomerDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customer',
        });
    }

    /**
     * @param requestBody 
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static postMasterdataCustomer(
requestBody?: ValidatableCustomerDto,
): CancelablePromise<CustomerDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/customer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SimpleModelDto Success
     * @throws ApiError
     */
    public static getMasterdataSimpledcustomer(): CancelablePromise<Array<SimpleModelDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/simpledcustomer',
        });
    }

    /**
     * @param pId 
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static getMasterdataGetcustomerbyid(
pId: number,
): CancelablePromise<CustomerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getcustomerbyid/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pCode 
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static getMasterdataGetcustomerbycode(
pCode: string,
): CancelablePromise<CustomerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getcustomerbycode/{pCode}',
            path: {
                'pCode': pCode,
            },
        });
    }

    /**
     * @param pTaxNumber 
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static getMasterdataGetcustomersbytaxnumber(
pTaxNumber: string,
): CancelablePromise<Array<CustomerDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getcustomersbytaxnumber/{pTaxNumber}',
            path: {
                'pTaxNumber': pTaxNumber,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SimilarCustomersDto Success
     * @throws ApiError
     */
    public static postMasterdataGetsimilarcustomers(
requestBody?: CustomerDto,
): CancelablePromise<Array<SimilarCustomersDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/getsimilarcustomers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static postMasterdataSubscriptionclient(
requestBody?: ValidatableCustomerDto,
): CancelablePromise<CustomerDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/subscriptionclient',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param taxNumber 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getMasterdataChecktaxnumber(
taxNumber: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/checktaxnumber/{taxnumber}',
            path: {
                'taxNumber': taxNumber,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static postMasterdataSetcustomerbycodeoremail(
requestBody?: CustomerDto,
): CancelablePromise<CustomerDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/setcustomerbycodeoremail',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static postMasterdataCustomers(
requestBody?: Array<CustomerDto>,
): CancelablePromise<Array<CustomerDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/customers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataDeletecustomer(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deletecustomer/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataPermanentdeletecustomer(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/permanentdeletecustomer/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getMasterdataCustomerinuse(
pId: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customerinuse/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pCustomerId 
     * @param pCustomerCode 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getMasterdataIscustomercodeunique(
pCustomerId?: number,
pCustomerCode?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/iscustomercodeunique',
            query: {
                'pCustomerId': pCustomerId,
                'pCustomerCode': pCustomerCode,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getMasterdataGetnextcustomercode(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getnextcustomercode',
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getMasterdataAllidCustomer(): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/allid/customer',
        });
    }

    /**
     * @param requestBody 
     * @returns CustomerContactDto Success
     * @throws ApiError
     */
    public static postMasterdataCustomercontact(
requestBody?: CustomerContactDto,
): CancelablePromise<CustomerContactDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/customercontact',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getMasterdataDeletecustomercontact(
pId: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deletecustomercontact/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getMasterdataDeletecustomercontacts(
pId: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deletecustomercontacts/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pId 
     * @returns CustomerContactDto Success
     * @throws ApiError
     */
    public static getMasterdataCustomercontact(
pId: number,
): CancelablePromise<Array<CustomerContactDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customercontact/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns CategoryDto Success
     * @throws ApiError
     */
    public static getMasterdataClientcategory(): CancelablePromise<Array<CategoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/clientcategory',
        });
    }

    /**
     * @param requestBody 
     * @returns CategoryDto Success
     * @throws ApiError
     */
    public static postMasterdataClientcategory(
requestBody?: CategoryDto,
): CancelablePromise<CategoryDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/clientcategory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataDeleteclientcategory(
requestBody?: DeleteCategoryRequestDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/deleteclientcategory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getMasterdataClientcategoryinuse(
pId: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/clientcategoryinuse/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns CustomerPictureDto Success
     * @throws ApiError
     */
    public static getMasterdataCustomerpicturelist(): CancelablePromise<Array<CustomerPictureDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customerpicturelist',
        });
    }

    /**
     * @param pId 
     * @returns CustomerPictureDto Success
     * @throws ApiError
     */
    public static getMasterdataGetcustomerpicture(
pId: number,
): CancelablePromise<CustomerPictureDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getcustomerpicture/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pIds 
     * @returns string Success
     * @throws ApiError
     */
    public static getMasterdataGetcustomerspicture(
pIds?: string,
): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getcustomerspicture',
            query: {
                'pIds': pIds,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataSavecustomerpicture(
requestBody?: CustomerPictureDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/savecustomerpicture',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomerAddressDto Success
     * @throws ApiError
     */
    public static getMasterdataCustomeraddress(): CancelablePromise<Array<CustomerAddressDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customeraddress',
        });
    }

    /**
     * @param pCustomerAddressId 
     * @returns CustomerAddressDto Success
     * @throws ApiError
     */
    public static getMasterdataCustomeraddress1(
pCustomerAddressId: number,
): CancelablePromise<CustomerAddressDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customeraddress/{pCustomerAddressId}',
            path: {
                'pCustomerAddressId': pCustomerAddressId,
            },
        });
    }

    /**
     * @param pInvoiceId 
     * @returns CustomerAddressDto Success
     * @throws ApiError
     */
    public static getMasterdataCustomeraddressbyinvoice(
pInvoiceId: number,
): CancelablePromise<CustomerAddressDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/customeraddressbyinvoice/{pInvoiceId}',
            path: {
                'pInvoiceId': pInvoiceId,
            },
        });
    }

    /**
     * @returns ContactSourceDto Success
     * @throws ApiError
     */
    public static getMasterdataContactsource(): CancelablePromise<Array<ContactSourceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/contactsource',
        });
    }

    /**
     * @param requestBody 
     * @returns ContactSourceDto Success
     * @throws ApiError
     */
    public static postMasterdataContactsource(
requestBody?: ContactSourceDto,
): CancelablePromise<ContactSourceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/contactsource',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataDeletecontactsource(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deletecontactsource/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns ScopeOfActivityDto Success
     * @throws ApiError
     */
    public static getMasterdataScopeofactivity(): CancelablePromise<Array<ScopeOfActivityDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/scopeofactivity',
        });
    }

    /**
     * @param requestBody 
     * @returns ScopeOfActivityDto Success
     * @throws ApiError
     */
    public static postMasterdataScopeofactivity(
requestBody?: ScopeOfActivityDto,
): CancelablePromise<ScopeOfActivityDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/scopeofactivity',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataDeletescopeofactivity(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deletescopeofactivity/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static getMasterdataProduct(): CancelablePromise<Array<ProductDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/product',
        });
    }

    /**
     * @param requestBody 
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static postMasterdataProduct(
requestBody?: ValidatableProductDto,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/product',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static getMasterdataProductbyapi(): CancelablePromise<Array<ProductDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/productbyapi',
        });
    }

    /**
     * @param requestBody 
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static postMasterdataProductbyapi(
requestBody?: ValidatableProductDto,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/productbyapi',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SimpleModelDto Success
     * @throws ApiError
     */
    public static getMasterdataSimpledproduct(): CancelablePromise<Array<SimpleModelDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/simpledproduct',
        });
    }

    /**
     * @param pId 
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static getMasterdataGetproductbyid(
pId: number,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getproductbyid/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pId 
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static getMasterdataGetproductbyidforedit(
pId: number,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getproductbyidforedit/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pCode 
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static getMasterdataGetproductbycode(
pCode: string,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getproductbycode/{pCode}',
            path: {
                'pCode': pCode,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getMasterdataGetnextproductcode(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/getnextproductcode',
        });
    }

    /**
     * @param requestBody 
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static postMasterdataSetproductbycode(
requestBody?: ProductDto,
): CancelablePromise<ProductDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/setproductbycode',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProductDto Success
     * @throws ApiError
     */
    public static postMasterdataProducts(
requestBody?: Array<ProductDto>,
): CancelablePromise<Array<ProductDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataDeleteproduct(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/deleteproduct/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param pId 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static getMasterdataPermanentdeleteproduct(
pId: number,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/permanentdeleteproduct/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataDeleteproducts(
requestBody?: Array<number>,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/deleteproducts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataUndodeleteproducts(
requestBody?: Array<number>,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/undodeleteproducts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataDeletecustomers(
requestBody?: Array<number>,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/deletecustomers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataUndodeletecustomers(
requestBody?: Array<number>,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/undodeletecustomers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getMasterdataProductinuse(
pId: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/productinuse/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

    /**
     * @param productId 
     * @param currencyId 
     * @returns CurrencyPriceDto Success
     * @throws ApiError
     */
    public static getMasterdataCurrencyprice(
productId: number,
currencyId: number,
): CancelablePromise<CurrencyPriceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/currencyprice/{productId}/{currencyId}',
            path: {
                'productId': productId,
                'currencyId': currencyId,
            },
        });
    }

    /**
     * @param productId 
     * @returns CurrencyPriceDto Success
     * @throws ApiError
     */
    public static getMasterdataHufprice(
productId: number,
): CancelablePromise<CurrencyPriceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/hufprice/{productId}',
            path: {
                'productId': productId,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static getMasterdataAllidProduct(): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/allid/product',
        });
    }

    /**
     * @param productId 
     * @returns CurrencyPriceDto Success
     * @throws ApiError
     */
    public static getMasterdataCurrencyprices(
productId: number,
): CancelablePromise<Array<CurrencyPriceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/currencyprices/{productId}',
            path: {
                'productId': productId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataCurrencyprices(
requestBody?: Array<CurrencyPriceDto>,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/currencyprices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ProductPictureDto Success
     * @throws ApiError
     */
    public static getMasterdataProductpicturelist(): CancelablePromise<Array<ProductPictureDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/productpicturelist',
        });
    }

    /**
     * @param pIds 
     * @returns string Success
     * @throws ApiError
     */
    public static getMasterdataProductspictures(
pIds?: string,
): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/productspictures',
            query: {
                'pIds': pIds,
            },
        });
    }

    /**
     * @returns ExpensePictureDto Success
     * @throws ApiError
     */
    public static getMasterdataExpensepicturelist(): CancelablePromise<Array<ExpensePictureDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/expensepicturelist',
        });
    }

    /**
     * @returns CategoryDto Success
     * @throws ApiError
     */
    public static getMasterdataProductcategory(): CancelablePromise<Array<CategoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/productcategory',
        });
    }

    /**
     * @param requestBody 
     * @returns CategoryDto Success
     * @throws ApiError
     */
    public static postMasterdataProductcategory(
requestBody?: CategoryDto,
): CancelablePromise<CategoryDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/productcategory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResultDto Success
     * @throws ApiError
     */
    public static postMasterdataDeleteproductcategory(
requestBody?: DeleteCategoryRequestDto,
): CancelablePromise<ResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/masterdata/deleteproductcategory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static getMasterdataProductcategoryinuse(
pId: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/masterdata/productcategoryinuse/{pId}',
            path: {
                'pId': pId,
            },
        });
    }

}
