import { Fragment, useContext, useEffect, useState } from "react";
import {
  DeviceContext,
  MasterDataContext,
  ReceiptContext,
} from "../utils/GlobalContexts";
import { useStylesMainPage } from "../styles/MainPageStyles";
import { useParams } from "react-router-dom";
import {
  CustomerReceiptDto,
  DocumentPrintService,
  VoucherService,
  VoucherType,
} from "../KulcsUzletApi";
import { TAB_TYPE, LIST_TYPE } from "../utils/Enums";
import { getCompanyName, getMasterData } from "../utils/HelperFunctions";
import axios from "axios";
import _ from "lodash";
import MobileTabs from "../components/mobile-tabs/MobileTabs";
import ListContainer from "../components/lists/ListContainer";
import Header from "../components/header/Header";
import PageHeader from "../components/page-header/PageHeader";
import DialogConfirm from "../components/dialog/DialogConfirm";
import DialogSetCurrency from "../components/dialog/DialogSetCurrency";
import DialogSendEmail from "../components/dialog/DialogSendEmail";
import { getNewCustomerReceiptDto } from "../utils/GlobalConsts";
import CustomerReceiptFormMobile from "../components/forms/CustomerReceiptFormMobile";
import CustomerReceiptForm from "../components/forms/CustomerReceiptForm";
import MobileBottomMenuReceipt from "../components/bottom-menu/MobileBottomMenuReceipt";
import DialogEditTempDetailReceipt from "../components/dialog/DialogEditTempDetailReceipt";
import { getUnitPrice } from "../utils/VoucherFunctions";

export default function CustomerReceiptPage() {
  const { isMobile } = useContext(DeviceContext);
  const { receipt, setReceipt } = useContext(ReceiptContext);
  const [companyName, setCompanyName] = useState("");
  const {
    setVats,
    setQuantityUnits,
    setProductCategories,
    setProjects,
    setCustomerCategories,
    setCurrencies,
    setPaymentMethods,
    setCountries,
    currencies,
    setTransportModes,
    setLanguages,
    setUserSettings,
    vats,
  } = useContext(MasterDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [openResultDialog, setOpenResultDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openTempDetailDialog, setOpenTempDetailDialog] = useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [dialogText, setDialogText] = useState<string | null>(null);
  const [currencyRate, setCurrencyRate] = useState<number | undefined | null>(
    1
  );
  const [unsavedCurrencyRate, setUnsavedCurrencyRate] = useState<
    number | undefined
  >(1);
  let { id } = useParams();
  const [activeTab, setActiveTab] = useState(
    id ? TAB_TYPE.INVOICE : TAB_TYPE.CUSTOMERS
  );
  const [currentListType, setCurrentListType] = useState(
    id ? LIST_TYPE.PRODUCT : LIST_TYPE.CUSTOMER
  );
  const styles = useStylesMainPage();
  const [openCurrencyRateModal, setOpenCurrencyRaterModal] = useState(false);
  const [shouldUpdateCurrency, setShouldUpdateCurrency] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const isSalesPlusPlus = localStorage.getItem("isSalesPlusPlus") === "1";

  useEffect(() => {
    if (companyName === "") {
      getCompanyName(setCompanyName);
    }
  }, [companyName]);

  useEffect(() => {
    setIsLoading(true);
    getData().then(() => setIsLoading(false));
  }, []);

  const getData = async () => {
    const action = localStorage.getItem("action");
    let voucherId = undefined;
    if (action === "edit" || action === "copy") {
      setCurrentListType(LIST_TYPE.PRODUCT);
      setActiveTab(TAB_TYPE.INVOICE);
      voucherId = voucherId ?? localStorage.getItem("id");
    } else localStorage.removeItem("id");

    await getMasterData(
      setQuantityUnits,
      setVats,
      setProductCategories,
      setProjects,
      setTransportModes,
      setCustomerCategories,
      setCurrencies,
      setPaymentMethods,
      setCountries,
      setLanguages,
      setUserSettings
    );

    if (voucherId) {
      let dto = await VoucherService.getVoucherReceipt(Number(voucherId));
      if (action === "copy") {
        dto.Id = undefined;
        const voucherNumber = await VoucherService.getVoucherNextvouchernumber(
          VoucherType.CUSTOMER_RECEIPT,
          dto.CurrencyId ?? 245
        ).catch((e) => console.log(e));
        dto.VoucherNumber = voucherNumber ?? "";
        dto.Detail = {
          ...dto.Detail,
          Id: Math.floor(Math.random() * 10000000),
          ReceiptId: undefined,
        };
      }
      setReceipt(dto);
      setCurrencyRate(dto.CurrencyRate);
    } else {
      setCurrencyRate(1);
      const newDto = await getNewCustomerReceiptDto();
      setReceipt(newDto);
    }
  };

  useEffect(() => {
    if (receipt?.CurrencyId && shouldUpdateCurrency) {
      updateCurrency().then(async () => {
        if (receipt?.CurrencyId) {
          const vouchernumber =
            await VoucherService.getVoucherNextvouchernumber(
              VoucherType.CUSTOMER_RECEIPT,
              receipt.CurrencyId
            );
          if (vouchernumber !== receipt.VoucherNumber) {
            setReceipt((prev: CustomerReceiptDto) => ({
              ...prev,
              VoucherNumber: vouchernumber,
            }));
          }
        }
      });
      setShouldUpdateCurrency(false);
    }
  }, [shouldUpdateCurrency]);

  const updateGross = async () => {
    if (!currencyRate) return;

    let updatedGross = (receipt.Detail?.GrossValue ?? 0) / currencyRate;
    if (receipt?.Detail?.ProductId) {
      const currencyUnitPrice = await getUnitPrice(
        receipt.Detail,
        receipt?.CurrencyId,
        currencyRate
      );
      let vat =
        vats && receipt?.Detail.VatId ? vats[receipt?.Detail.VatId] : undefined;
      updatedGross = currencyUnitPrice * (1 + (vat?.Rate ?? 0) / 100);
    }
    setReceipt((prev: CustomerReceiptDto) => ({
      ...prev,
      Detail: {
        ...prev.Detail,
        GrossValue: updatedGross,
      },
    }));
  };
  useEffect(() => {
    if (currencyRate && receipt?.CurrencyId) {
      if (receipt?.Id && firstRender) {
        setFirstRender(false);
        return;
      } else if (receipt?.Detail) {
        updateGross();
      } else {
        setReceipt((prev: CustomerReceiptDto) => ({
          ...prev,
          CurrencyRate: currencyRate,
        }));
      }
    }
  }, [currencyRate]);

  const updateCurrency = async () => {
    const currencyname =
      currencies &&
      receipt?.CurrencyId &&
      currencies[receipt?.CurrencyId]?.Name;

    if (currencyname && currencyname !== "HUF") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/currencyRates/${currencyname}`
        );
        if (response.data) openSetCurrencyRateModal(response.data);
        else openSetCurrencyRateModal(1);
      } catch (e) {
        openSetCurrencyRateModal(1);
      }
    } else {
      setCurrencyRate(1);
    }
  };

  const openResultModal = (text?: string) => {
    if (text) setDialogText(text);
    setOpenResultDialog(true);
  };

  const openNewTempProductModal = () => {
    setOpenTempDetailDialog(true);
  };

  const openSetCurrencyRateModal = (currencyValue?: number) => {
    if (currencyValue) setUnsavedCurrencyRate(currencyValue);
    setOpenCurrencyRaterModal(true);
  };

  const handleSendEmailClick = () => {
    if (isSalesPlusPlus) {
      handleSave(true);
    } else setOpenSendEmailDialog(true);
  };

  const handleSave = async (isSendEmail?: boolean) => {
    setIsSaveLoading(true);
    let originalDto = _.cloneDeep(receipt) as CustomerReceiptDto;

    if (originalDto.Detail) {
      originalDto.Detail = {
        ...originalDto.Detail,
        Id: null,
      };
    }
    if (isSendEmail) originalDto.IsEReceipt = true;

    if (originalDto.VoucherDate) {
      var voucherdate = new Date(originalDto.VoucherDate);
      originalDto.VoucherDate = new Date(
        voucherdate.getTime() - voucherdate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }

    let result: number | undefined | null = undefined;
    await VoucherService.postVoucherReceipt(originalDto)
      .then((res) => {
        if (res.IsSuccess === false) {
          openResultModal(`A mentés nem sikerült: ${res.ErrorMsg}`);
          setIsSaveLoading(false);
          result = undefined;
        } else {
          setIsSaveLoading(false);
          result = res.ResultValue?.Id;
          if (!isSendEmail) {
            openResultModal("A mentés sikeres volt.");
            const voucherType = localStorage.getItem("voucherType");
            window.location.replace(
              `${process.env.REACT_APP_KULCSUZLET_BASE_URL}/${voucherType}s`
            );
          }
        }
      })
      .catch((e) => {
        openResultModal(`A mentés nem sikerült: ${e?.message}`);
        console.log(e);
        setIsSaveLoading(false);
        result = undefined;
      });
    return result;
  };

  const handleOpenPreview = async () => {
    setIsPreviewLoading(true);
    let originalDto = _.cloneDeep(receipt);
    if (originalDto.VoucherDate) {
      const voucherdate = new Date(originalDto.VoucherDate);
      originalDto.VoucherDate = new Date(
        voucherdate.getTime() - voucherdate.getTimezoneOffset() * 60000
      ).toISOString() as any;
    }
    await DocumentPrintService.postDocumentprintPreviewReceipt(originalDto)
      .then((res) => {
        if (res.IsSuccess === false) {
          openResultModal(
            `Az előnézet betöltése nem sikerült: ${res.ErrorMsg}`
          );
          setIsPreviewLoading(false);
        } else if (res.PreviewPages && res.PreviewPages.length > 0) {
          let contents: string[] = [];
          res.PreviewPages.forEach((p, index) =>
            contents.push(
              `<img src="data:image/png;base64,${p}" alt="${index}"/>`
            )
          );
          var tab = window.open(
            `${originalDto.VoucherNumber}_customerReceipt`,
            "_blank"
          );
          var html = `<html>
           <body>
             ${contents.join()}
           </body>
           </html>`;
          tab?.document.write(html);
          tab?.document.close();
          setIsPreviewLoading(false);
        }
      })
      .catch((e) => {
        openResultModal(`Az előnézet betöltése nem sikerült: ${e?.message}`);
        console.log(e);
        setIsPreviewLoading(false);
      });
  };

  const openEditDetailDialog = () => {
    setOpenDetailDialog(true);
  };

  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          <div className={styles.containerMobile}>
            <div className={styles.invoiceContainerMobile}>
              <MobileTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                needPartnerTab
              />
              {activeTab === TAB_TYPE.CUSTOMERS && (
                <ListContainer
                  setShouldUpdateCurrency={setShouldUpdateCurrency}
                  type={LIST_TYPE.CUSTOMER}
                  setActiveTab={setActiveTab}
                />
              )}
              {activeTab === TAB_TYPE.PRODUCTS && (
                <ListContainer
                  openNewTempProductModal={openNewTempProductModal}
                  type={LIST_TYPE.PRODUCT}
                />
              )}
              {activeTab === TAB_TYPE.INVOICE && (
                <CustomerReceiptFormMobile
                  setActiveTab={setActiveTab}
                  selectDetail={openEditDetailDialog}
                />
              )}
              <MobileBottomMenuReceipt
                handleOpenPreview={handleOpenPreview}
                handleSave={handleSave}
                handleSendEmailClick={handleSendEmailClick}
                openResultModal={openResultModal}
                activeTab={activeTab}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Header companyName={companyName} />
          <div
            className={styles.container}
            style={{ justifyContent: "center" }}
          >
            <PageHeader
              title=""
              content={<div style={{ fontSize: 25 }}>Nyugta</div>}
            />
            <div className={styles.innerContainer} style={{ marginBottom: 0 }}>
              <div className={styles.containerLeft}>
                <ListContainer
                  setShouldUpdateCurrency={setShouldUpdateCurrency}
                  type={currentListType}
                  setType={setCurrentListType}
                  openNewTempProductModal={openNewTempProductModal}
                />
              </div>
              <div className={styles.containerRight}>
                <CustomerReceiptForm
                  isPreviewLoading={isPreviewLoading}
                  handleOpenPreview={handleOpenPreview}
                  isLoading={isLoading}
                  isSaveLoading={isSaveLoading}
                  handleSave={handleSave}
                  handleSendEmailClick={handleSendEmailClick}
                  copy={localStorage.getItem("action") === "copy"}
                  currencyRate={currencyRate}
                  openResultModal={openResultModal}
                  updateCurrencyRate={updateCurrency}
                  setShouldUpdateCurrency={setShouldUpdateCurrency}
                  selectDetail={openEditDetailDialog}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <DialogConfirm
        message={dialogText ?? ""}
        open={openResultDialog}
        setOpen={setOpenResultDialog}
      />

      <DialogEditTempDetailReceipt
        open={openDetailDialog}
        setOpen={setOpenDetailDialog}
        isEdit
      />
      <DialogEditTempDetailReceipt
        open={openTempDetailDialog}
        setOpen={setOpenTempDetailDialog}
        isEdit={false}
      />
      <DialogSetCurrency
        currencyRate={unsavedCurrencyRate}
        setCurrencyRate={setCurrencyRate}
        open={openCurrencyRateModal}
        setOpen={setOpenCurrencyRaterModal}
      />
      <DialogSendEmail
        openResultModal={openResultModal}
        handleSaveVoucher={handleSave}
        open={openSendEmailDialog}
        setOpen={setOpenSendEmailDialog}
        voucher={receipt}
        voucherType={38}
      />
    </Fragment>
  );
}
