import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";
import InputComponent from "../input/InputComponent";
import {
  DeviceContext,
  MasterDataContext,
  ReceiptContext,
} from "../../utils/GlobalContexts";
import {
  CurrencyDto,
  CustomerReceiptDetailDto,
  CustomerReceiptDto,
  VatDto,
} from "../../KulcsUzletApi";
import { useContext, useEffect, useState } from "react";
import { Text } from "@fluentui/react-components";
import { useStylesDialogEditCustomerStyle } from "../../styles/DialogEditCustomerStyle";
import SelectComponent from "../select/SelectComponent";
import { DialogEditDetailReceiptProps } from "../../interfaces/DialogEditDetailReceiptProps";

const DialogEditTempDetailReceipt: React.FC<DialogEditDetailReceiptProps> = ({
  open,
  setOpen,
  isEdit,
}) => {
  const styles = useStylesDialogEditCustomerStyle();
  const { receipt, setReceipt } = useContext(ReceiptContext);
  const [currentDetail, setCurrentDetail] =
    useState<CustomerReceiptDetailDto | null>(null);
  const { vats, currencies, userSettings } = useContext(MasterDataContext);
  const [currency, setCurrency] = useState<CurrencyDto | undefined | null>(
    undefined
  );
  const [vat, setVat] = useState<VatDto | undefined | null>(undefined);
  const [quantity, setQuantity] = useState(0);
  const { isMobile } = useContext(DeviceContext);

  useEffect(() => {
    if (open) {
      setInitialData();
    } else {
      setVat(undefined);
      setCurrency(undefined);
      setCurrentDetail(null);
      setQuantity(0);
    }
  }, [open]);

  const setInitialData = () => {
    if (isEdit && receipt?.Detail) {
      if (vats && receipt?.Detail?.VatId) setVat(vats[receipt?.Detail?.VatId]);

      if (currencies && receipt?.CurrencyId)
        setCurrency(currencies[receipt.CurrencyId]);
      setQuantity(receipt?.Detail?.Quantity ?? 1);
      setCurrentDetail(receipt?.Detail);
    } else {
      if (vats && userSettings.DefaultReceiptVat)
        setVat(vats[userSettings.DefaultReceiptVat]);

      if (currencies && receipt?.CurrencyId)
        setCurrency(currencies[receipt.CurrencyId]);
      setQuantity(1);
      const newDetail = {
        Id: null,
        ReceiptId: undefined,
        ProductId: null,
        VatId: userSettings.DefaultReceiptVat,
        Quantity: 1,
        QuantityUnitId: 305,
        UnitPrice: 1,
        NetValue: 1,
        VatValue: 0,
        GrossValue: 1,
        ProductNameDisplay: "",
      } as CustomerReceiptDetailDto;

      setCurrentDetail(newDetail);
    }
  };

  const saveDetail = async () => {
    await setReceipt((prevState: CustomerReceiptDto) => ({
      ...prevState,
      Detail: currentDetail,
    }));
    setOpen(false);
  };

  const updateCurrentDetail = (e: any, checkForNegative?: boolean) => {
    setCurrentDetail((prev) => ({
      ...prev,
      [e.target.name]:
        checkForNegative && e.target.value < 0 ? 0 : e.target.value,
    }));
  };

  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title={isEdit ? "Tétel" : "Új tétel hozzáadása"}
      saveAction={saveDetail}
      saveBtnText="Mentés"
      closeBtnText="Mégse"
    >
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Név
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={currentDetail?.ProductNameDisplay ?? ""}
            name={"ProductNameDisplay"}
            onChange={updateCurrentDetail}
          />
        </div>
      </div>
      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Mennyiség
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={quantity ?? 0}
            name={"Quantity"}
            min={1}
            numeric
            onChange={(e: any) => setQuantity(e.target.value ?? 0)}
            onFocusLeave={(e: any) => updateCurrentDetail(e, true)}
          />
        </div>
      </div>

      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            Bruttó összeg
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <InputComponent
            fullwidth
            value={currentDetail?.GrossValue ?? 0}
            contentBefore={
              currency?.IsPost === 0 ? (
                <Text size={400} id={"currency-input"}>
                  {currency?.Sign}
                </Text>
              ) : (
                ""
              )
            }
            contentAfter={
              currency?.IsPost === 1 || !currency ? (
                <Text size={400} id={"currency-input"}>
                  {currency?.Sign ?? "Ft"}
                </Text>
              ) : (
                ""
              )
            }
            numeric
            name={"GrossValue"}
            onChange={updateCurrentDetail}
          />
        </div>
      </div>

      <div className={isMobile ? styles.mobileline : styles.line}>
        <div className={isMobile ? styles.mobilefpart : styles.fpart}>
          <div className={isMobile ? styles.mobilefpart : styles.fpartText}>
            ÁFA
          </div>
        </div>
        <div className={isMobile ? styles.mobilespart : styles.spart}>
          <SelectComponent
            noEmptyOption
            fullwidth
            options={
              vats &&
              (Object.values(vats).sort((a, b) =>
                (a.Name ?? "").localeCompare(b.Name ?? "")
              ) as any)
            }
            value={
              (vats &&
                currentDetail?.VatId &&
                vats[currentDetail?.VatId]?.VatCode) ??
              ""
            }
            action={(ev: any, data: any) => {
              setCurrentDetail((prev) => ({
                ...prev,
                VatId: (data.optionValue as VatDto)?.Id ?? undefined,
              }));
            }}
          />
        </div>
      </div>
    </DialogSkeleton>
  );
};

export default DialogEditTempDetailReceipt;
